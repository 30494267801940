import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { handleSignOut } from "./SessionExpiredApi";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import EligibilityTenureForm from "./EligibilityTenureForm";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import "./personalLoan.css";
import { businessLoanDecisionEngine1API, businessLoanPreDe1CallAPI,fetchDataBusinessAPI } from "../../actioncreators/actioncreators";
import LoadingSpinner from "../../fields/spinner.jsx";
import { toast } from 'react-toastify';

const EligibilityCheckForm = ( { onContinue, onBack, userData, ckycSuccess } ) => {
  const [ showEligibilityTenureForm, setShowEligibilityTenureForm ] =
    useState( false );
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [ currentFormData, setCurrentFormData ] = React.useState( {} );
  const [ purposeDropdown, setPurposeDropdown ] = React.useState( [] );
  const [ tenureDropdown, setTenureDropdown ] = React.useState( [] );
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await businessLoanPreDe1CallAPI();
        const {
          annualTurnover,
          existingObligations,
          initialLoanAmount,
          initialLoanTenure,
          purpose,
          salary
        } = response.data;
  
        setFormData({
          salary: salary,
          annualTurnover: annualTurnover,
          existingObligationEMI: existingObligations,
          loanAmount: initialLoanAmount,
          tenure: initialLoanTenure,
          purpose: purpose,
        });
      } catch (error) {
        handleApiError(error);
          console.error("Error fetching user data:", error);
      }
    };
  
    fetchData();
  }, []);  

  const fetchPurposeValues = async () => {
    setPurposeDropdown( [] )
    try {
      let payload = {
        "type": "BUSINESS_LOAN",
        "key":"PURPOSE",
        "source":"json_value"
    }
      const response = await fetchDataBusinessAPI(payload)
      setPurposeDropdown( response.data[ 0 ].List )
    } catch ( error ) {
      handleApiError(error);
      console.error( "Error fetching address vintage:", error );
    }
  };

  useEffect( () => {
      fetchPurposeValues();
  }, [] );

  const fetchTenureValues = async () => {
    setTenureDropdown( [] )
    try {
      let payload = {
        "source": "json_value",
        "key": "TENURE",
        "type": "BUSINESS_LOAN"
    }
      const response = await fetchDataBusinessAPI(payload)
      setTenureDropdown( response?.data?.[ 0 ]?.Tenure )
    } catch ( error ) {
      handleApiError(error);
      console.error( "Error fetching address vintage:", error );
    }
  };
  
  useEffect( () => {
      fetchTenureValues();
  }, [] );

  const [ formData, setFormData ] = useState( {
    salary:"",
    annualTurnover: "",
    existingObligationEMI: "",
    loanAmount: "",
    tenure: "",
    purpose: "",
    monthlyIncome: "",
  } );
  useEffect( () => {
    validateForm();
  }, [ formData ] );
  


  const handleChange = ( e ) => {
    const { name, value } = e.target;
    console.log( name, value );

    // Update formData state based on the input field

  if ( name == "annualTurnover" ) {
     // Sanitize input by removing non-digit characters
     const sanitizedValue = value.replace(/\D/g, '');
     // Define regex pattern for validation
     const regex = /^[0-9]*$/;
 
     // Validate the sanitized value
     if (regex.test(sanitizedValue)) {
       setFormData({
         ...formData,
         [name]: sanitizedValue,
       });
     } else {
       // Optionally handle the case where validation fails
       console.error("Invalid input. Please enter a number with up to 8 digits.");
     }
    
    } 
  else if (name === "existingObligationEMI" || name === "loanAmount" || name === "salary") {
    // Sanitize input by removing non-digit characters
    const sanitizedValue = value.replace(/\D/g, '');
    // Define regex pattern for validation
    const regex = /^[0-9]{0,8}$/;

    // Validate the sanitized value
    if (regex.test(sanitizedValue)) {
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });
    } else {
      // Optionally handle the case where validation fails
      console.error("Invalid input. Please enter a number with up to 8 digits.");
    }
  } else {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  // Validate the form on each change
  validateForm();
};

  const [ isFormValid, setIsFormValid ] = useState( false );

  const handleBack = () => {
    onBack(); // Call the onBack function passed as a prop
  };

  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};

  const navigate = useNavigate();

const handleContinue = async () => {
  // Set loading state to true
  setIsButtonClicked(true);
  setIsLoading(true);

  const headers = {
    'x-apid': ap_id,
    'x-loanappid': loan_app_id,
  };

  const data = {
    annualTurnover: formData.annualTurnover,
    income: formData.salary,
    emi: formData.existingObligationEMI,
    amount: formData.loanAmount,
    purpose: formData.purpose,
    tenure: formData.tenure.toString(),
    exactPurpose:formData.exactPurpose,
  };

  try {
    const response = await businessLoanDecisionEngine1API(data);

    const {
      interestRate,
      maxLoanAmount,
      maxTenure,
      minimumLoan,
      tentativeEmi,
      minimumTenure,
      loanAmount,
      loanTenure,
      tenure
    } = response.data;

    if (response.status === 200 && response.data.businessStatusCode === 2) {
      toast.success('Financial Details Added Successfully')
      setShowEligibilityTenureForm(true);
      navigate(EligibilityTenureForm, {
        state: {
          interestRate,
          maxLoanAmount,
          maxTenure,
          minimumLoan,
          tentativeEmi,
          minimumTenure,
          ap_id,
          loan_app_id,
          loanAmount,
          loanTenure,
          tenure
        },
      });
    } else if (response.status === 200 && response.data.businessStatusCode === 1) {
      setShowEligibilityTenureForm(false);
      toast.info(response.data.message);
    } else {
      console.error("Invalid loan data in response.");
    }
  } catch (error) {
    handleApiError(error);
    console.error("Error fetching loan data:", error);
  } finally {
    // Reset loading state after the operation is complete
    setIsLoading(false);
    setIsButtonClicked(false);
  }
};

  const validateForm = () => {
    const {
      annualTurnover,
      salary,
      existingObligationEMI,
      loanAmount,
      tenure,
      purpose,
    } = formData;

    // Validate each field
    const isAnnualTurnoverValid = /^\d+$/.test( annualTurnover );
    const isMonthlyIncomeValid = /^\d+$/.test( salary );
    const isExistingObligationEMIValid = /^\d+$/.test( existingObligationEMI );
    const isLoanAmountValid = /^\d+$/.test( loanAmount );
    const isTenureValid = tenure !== "" && tenure !== "0";
    const isPurposeValid = purpose && purpose.trim() !== "";
    // Update the overall form validity
    setIsFormValid(
      isAnnualTurnoverValid &&
      isMonthlyIncomeValid &&
      isExistingObligationEMIValid &&
      isLoanAmountValid &&
      isTenureValid &&
      isPurposeValid
    );
  };

  return (
    <>
      {!showEligibilityTenureForm ? (
        <>
          <h6 className="text">Financial Details</h6>

          <form className="mt-4">

            <div className="row">
            <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="annualTurnover"
                  label="₹ Annual Turnover"
                  fullWidth
                  variant="outlined"
                  value={formData.annualTurnover}
                  onChange={handleChange}
                />
                {/* <p className="msg">As per Bank Statement</p> */}
              </div>
              <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="salary"
                  label="₹ Monthly Income"
                  fullWidth
                  variant="outlined"
                  value={formData.salary}
                  onChange={handleChange}
                />
                <p className="msg">As per Bank Statement</p>
              </div>
              <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="existingObligationEMI"
                  label="₹ Existing Obligation/EMI"
                  fullWidth

                  variant="outlined"
                  value={formData.existingObligationEMI}
                  onChange={handleChange}

                />
                {/* {isInvalidAmou && (
              <p style={{ color: "red", fontSize: "10px" }}>Invalid PAN</p>
            )} */}
                <p className="msg">Total amount per month</p>
              </div>
            </div>

            <h6 className="text mt-2">Loan Requirement</h6>
            <div className="row mt-4">
              <div className="col-md-6 ">
                <ArthaTextField
                  name="loanAmount"
                  label="₹ Loan Amount"
                  defaultValue=""
                  fullWidth
                  variant="outlined"
                  value={formData.loanAmount}
                  onChange={handleChange}
                />
                <p className="msg">Preferred Loan Amount</p>
              </div>
              <div className="col-md-6">
                <ArthaFormControl fullWidth>
                  <ArthaInputLabel id="tenure-label">Tenure</ArthaInputLabel>
                  <Select
                    labelId="tenure-label"
                    id="tenure"
                    name="tenure"
                    required
                    value={formData.tenure}
                    onChange={handleChange}
                  >
                  {tenureDropdown && tenureDropdown.map( ( item ) => {
                      return ( <MenuItem key={item} value={item}>{item}</MenuItem> )
                    } )}
                  </Select>
                  <p className="msg">Preferred Tenure</p>
                </ArthaFormControl>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
              <ArthaFormControl fullWidth>
                  <InputLabel id="qualification-label">
                    Purpose
                  </InputLabel>
                  <Select
                    labelId="qualification-label"
                     label="Purpose"
                    name="purpose"
                    value={formData.purpose}
                    onChange={( e ) => {
                      setFormData( {
                        ...formData,
                        'purpose': e.target.value,
                        exactPurpose: e.target.value === 'Other' ? formData.exactPurpose : '',
                      } );
                    }
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: 'custom-scrollbar', // Apply custom scrollbar class
                        style: {
                          maxHeight: 120, // Adjust the max height to control the size of the dropdown
                        },
                      },
                    }}
                  >
                    {purposeDropdown && purposeDropdown.map( ( item ) => {
                      return ( <MenuItem key={item} value={item}>{item}</MenuItem> )
                    } )}
                  </Select>
                </ArthaFormControl>
              </div>
              {formData.purpose === 'Other' && (
                <div className="col-md-6 mt-2">
                  <ArthaFormControl fullWidth>
                    <ArthaTextField
                      label="Exact Purpose"
                      name="exactPurpose"
                      value={formData.exactPurpose}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          exactPurpose: e.target.value,
                        });
                      }}
                    />
                  </ArthaFormControl>
                </div>
              )}
            </div>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton variant="contained"
                  onClick={handleBack}

                >
                  Back
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  // Add style based on form validity
                  style={{
                    backgroundColor: isFormValid
                      ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                      : "rgb(255 98 0 / 39%)",
                      opacity: isButtonClicked ? 0.39 : 1,
        pointerEvents: isButtonClicked ? "none" : "auto", // Lower opacity when form is not valid
                  }}
                >
                  {isLoading ? (
            <LoadingSpinner style={{ pointerEvents: 'none' }} />
          ) : (
            'Continue'
          )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <EligibilityTenureForm
          onContinue={onContinue}
          data={currentFormData}
          onCancel={() => setShowEligibilityTenureForm( false )}
          ckycSuccess={ckycSuccess}
        />
      )}
    </>
  );
};

export default EligibilityCheckForm;