import React, { useEffect, useState } from "react";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import KYCAdharVerification from "./KYCAdharVerification";
import KYCDetailsForm from "./KYCDetailsForm";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";
import Slider from '@mui/material/Slider';
import { storeDecisionEngine1DetailsAPI } from "../../actioncreators/actioncreators";
import { toast } from 'react-toastify';
export default function EligibilityTenureForm({ onContinue, onCancel, ckycSuccess, setTenureFlag }) {
  const navigate = useNavigate();
  const [showKYCDetailsForm, setShowKYCDetailsForm] = useState(false);
  const [showKYCAdharVerification, setShowKYCAdharVerification] =
    useState(false);
  const [activeStep, setActiveStep] = useState(1);
  // Default values or values from location.state
  const location = useLocation();
  const {
    minimumLoan,
    minimumTenure,
    tentativeEmi,
    loanAmount,
    loanTenure,
    ap_id,
    loan_app_id,
    tenure,
  } = location.state || {};

  const [loanAmount1, setLoanAmount] = useState(loanAmount);
  const [loanTenure1, setTenure] = useState(loanTenure);
  const [emi, setEmi] = useState(tentativeEmi || 0);
  const [emiPerLakh, setEmiPerLakh] = useState();
  const [maxLoanAmount1, setMaxLoanAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setTenure(loanTenure);
  }, [loanTenure]);

  useEffect(() => {
    setLoanAmount(loanAmount);
  }, [loanAmount]);

  useEffect(() => {
    // Calculate EMI when either loan amount, tenure, or emiPerLakh changes
    calculateEmi();
  }, [loanAmount1, loanTenure1, emiPerLakh]);

  console.log("tenure", tenure);

  useEffect(() => {
    // Set default values from location.state
    setLoanAmount(loanAmount1);
  }, [loanAmount1, emiPerLakh]);

  useEffect(() => {
    // Set default values from location.state
    setLoanAmount(loanAmount1);

    // Set tenure state only if it's not already set
    if (!tenure) {
      setTenure(loanTenure);
    }
    // Map values from the tenure array based on the initial loanTenure
    const initialTenureObj = tenure && tenure.find((obj) => obj.Tenure === loanTenure1);

    if (initialTenureObj) {
      setEmiPerLakh(initialTenureObj.EMIPerLakh);
      const roundedMaxLoanAmount1 = Math.floor(initialTenureObj.MaXLoanAmount / 1000) * 1000;
      setMaxLoanAmount(roundedMaxLoanAmount1);
    }
  }, [loanAmount1, emiPerLakh, tenure, loanTenure1]);


  useEffect(() => {

    // Update loanAmount1 based on the condition
    if (loanAmount1 > maxLoanAmount1) {
      setLoanAmount(maxLoanAmount1);
    }
  }, [loanAmount1, maxLoanAmount1]);

  useEffect(() => {
    // Calculate EMI when either loan amount or tenure changes
    calculateEmi();
  }, [loanAmount1, loanTenure1]);

  useEffect(() => {
    // Update maxLoanAmount when tenure changes
    setMaxLoanAmount(getMaxLoanAmount(loanTenure1));
  }, [loanTenure1]);

  const calculateEmi = () => {
    // EMI Calculation logic (you can replace this with your own calculation)
    const principal = loanAmount1 || 0;
    const emiperklakh = emiPerLakh || 0;

    const emiValue = (principal * emiperklakh) / 100000;
    setEmi(Math.round(emiValue));
  };


  const handleContinue = async () => {

    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);

    const data = {
      loanAmount: loanAmount1.toString(),
      loanTenure: loanTenure1.toString(),
    };

    try {
      const response = await storeDecisionEngine1DetailsAPI(data);
      if (response.status === 200) {
        if (
          response.data.httpResponseCode === 200 &&
          (response.data.businessStatusSubCode === 1.2 ||
            response.data.businessStatusSubCode === 2.2)
        ) {
          setActiveStep(2);
          setShowKYCDetailsForm(true);
          ckycSuccess(true);
          onContinue();
        } else {
          console.error("Invalid loan data in response.");
          // Render KYCAdharVerification component


          setActiveStep(2);
          setShowKYCAdharVerification(true);
          ckycSuccess(false);
          onContinue();
        }
      } else {
        console.error("Invalid loan data in response.");

      }

    } catch (error) {
      if (error.response.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching loan data:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }

  };


  const handleLoanAmountChange = (event) => {
    setLoanAmount(parseInt(event.target.value, 10));
  };

  const getMaxLoanAmount = (selectedTenure) => {
    if (Array.isArray(tenure)) {
      const selectedTenureObj = tenure.find(
        (obj) => obj.Tenure === selectedTenure
      );
      if (selectedTenureObj) {
        const emiPerLakh = selectedTenureObj.EMIPerLakh;
        setEmiPerLakh(emiPerLakh);
        const roundedMaxLoanAmount1 = Math.floor(selectedTenureObj.MaXLoanAmount / 1000) * 1000;
        return roundedMaxLoanAmount1;
      } else {
        // Handle the case where the selected tenure is not found
        return maxLoanAmount1; // or provide a default value
      }
    } else {
      // Handle the case where tenure is not an array
      return maxLoanAmount1; // or provide a default value
    }
  };

  const handleTenureChange = (event) => {
    const newTenure = parseInt(event.target.value, 10);
    setTenure(newTenure);
    const newMaxLoanAmount = getMaxLoanAmount(newTenure);
    setMaxLoanAmount(newMaxLoanAmount);
  };

  return (
    <>
      {showKYCDetailsForm && activeStep === 2 ? (
        <KYCDetailsForm
          onContinue={onContinue} // Pass onContinue function to KYCDetailsForm
          onCancel={onCancel} // Pass onCancel function to KYCDetailsForm
        />
      ) : showKYCAdharVerification && activeStep === 2 ? (
        <KYCAdharVerification
          setTenureFlag={setTenureFlag}
          onContinue={onContinue} // Pass onContinue function to KYCAdharVerification
          onCancel={onCancel} // Pass onCancel function to KYCAdharVerification
        />
      ) : (
        <>
          <div className="tenure-heading">
            <h6 className="text">
              Congrats! Here's Your eligible amount & tenure
            </h6>
            <p className="small-heading">
              Loan Amount & tenure set here will be used for a loan offer in the
              subsequent step
            </p>
          </div>

          <form className="row mt-4">
            <div className="tenure">
              <div className="row">
                <div className="col-12 col-md-6">
                  <p className="small-heading"> Approximate EMI/Month </p>
                  <h4>₹ {emi}</h4>
                  <p className="small-heading">
                    *EMI is subject to change based on further checks
                  </p>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <p className="small-heading mb-0">Eligible Tenure</p>
                  <h6 className="mt-2">12 - 60 months</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={loanTenure1}
                    disabled={!!loanTenure}
                    onChange={handleTenureChange}
                  />
                  <Slider
                    style={{ color: "#10215F", }}
                    className="form-range range-slider"
                    defaultValue={loanTenure1}
                    min={12}
                    max={60}
                    step={12}
                    // value={loanTenure1}
                    onChange={handleTenureChange}
                  />

                  <div className="d-flex">
                    <p className="small-heading">12</p>
                    <p className="small-heading ms-auto">24</p>
                    <p className="small-heading ms-auto">36</p>
                    <p className="small-heading ms-auto">48</p>
                    <p className="small-heading ms-auto">60</p>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <p className="small-heading mb-0">Eligible Loan Amount</p>
                  <h6 className="mt-2">
                    ₹ {minimumLoan}- ₹ {maxLoanAmount1}
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    value={loanAmount1}
                    disabled={!!loanAmount}
                    onChange={handleLoanAmountChange}
                  />

                  <Slider
                    style={{ color: "#10215F" }}
                    className="form-range range-slider"
                    min={minimumLoan}
                    max={maxLoanAmount1}
                    step={25000}
                    defaultValue={loanAmount1}
                    onChange={handleLoanAmountChange}
                  />
                  <div className="d-flex">
                    <p className="small-heading">₹{minimumLoan}</p>
                    <p className="small-heading ms-auto">₹{maxLoanAmount1}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton type="button" onClick={onCancel}>
                  Back
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton type="button" onClick={handleContinue} style={{
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto"
                }}>
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}