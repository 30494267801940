import React, { useState, useEffect, useRef } from "react";
import "./LoginForm.css";
import card from "./public/card1.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TermsPopup from "./TermsPopup";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { handleSignOut } from "../Loan/PersonalLoan/SessionExpiredApi";
import LoadingSpinner from "../fields/spinner";
import ResumeSessionPopup from "./ResumeSessionPopup";
import {loginAPI} from "../actioncreators/actioncreators"
import { toast } from 'react-toastify';
import { loginResponse } from "../actions/loanAction";
const LoginForm = () => {
  const dispatch = useDispatch();
  const [ mobile, setMobile ] = useState( "" );
  const [ isValidMobile, setIsValidMobile ] = useState( true );
  const [ panCard, setPanCard ] = useState( "" );
  const [ isValidPanCard, setIsValidPanCard ] = useState( true );
  const [ isAgreed, setIsAgreed ] = useState( false );
  const [ isButtonDisabled, setIsButtonDisabled ] = useState( true );
  const [ isOpen, setIsOpen ] = React.useState( false );
  const [ isOpenResume, setIsOpenResume ] = React.useState( false );
  const navigate = useNavigate();
  const [ apiError, setApiError ] = useState( "" );
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [ isCheckboxDisabled, setIsCheckboxDisabled ] = useState( true );
  const [ isTermsAccepted, setIsTermsAccepted ] = useState( false );
  const [ isTermsAcceptedResume, setIsTermsAcceptedResume ] = useState( false );
const [message,setmessage]= useState(false);
const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };



  useEffect(() => {

    setIsButtonDisabled(!(isValidMobile && isValidPanCard && isTermsAccepted));
    console.log("isButtonDisabled:", isButtonDisabled);
  }, [isValidMobile, isValidPanCard, isTermsAccepted, isCheckboxDisabled]);


  const handleMobileChange = (e) => {
    const enteredMobile = e.target.value;
    const isNumeric = /^[0-9]+$/.test(enteredMobile);

    if (isNumeric || enteredMobile === "") {
      setMobile(enteredMobile);
      setIsValidMobile(enteredMobile.length === 10);
      // setIsCheckboxDisabled(!(enteredMobile.length === 10 && isValidPanCard && !isTermsAccepted));
      // setIsCheckboxDisabled(!enteredMobile );

    }
  };

  const handlePanCardChange = (e) => {
    const enteredPanCard = e.target.value.toUpperCase(); // Convert to uppercase
    setPanCard(enteredPanCard);
    const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
    setIsValidPanCard(panCardRegex.test(enteredPanCard));
    //setIsCheckboxDisabled(!(isValidMobile && panCardRegex.test(enteredPanCard) && !isTermsAccepted));
    // setIsCheckboxDisabled(!enteredPanCard );
  };
  const handleAgreeChange = () => {
    setIsAgreed(true);
    setIsCheckboxDisabled(!(isValidMobile && isValidPanCard && isAgreed));
  };


  const handleMobileClick = (e) => {
    document.getElementById("country-code").innerHTML = "+91";
  };

  const openPopup = () => {
    if (mobile && panCard) {
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleAcceptTerms = () => {
    setIsTermsAccepted(true);
    setIsCheckboxDisabled(true);
    closeModal();
  };

 
  const closeModalResume = () => {
    setIsOpenResume( false );
  };

  const handleAcceptTermsResume = () => {
    setIsTermsAcceptedResume( true );
    closeModalResume();
  };
  const [ isLoading, setIsLoading ] = useState( false );
  
  // const handleSubmit = async (e) => {
  //   setIsButtonClicked(true);
  //   setIsLoading(true);
  
  //   e.preventDefault();

  //   // Set loading state to true
  //   setIsLoading(true);
  //   setIsButtonDisabled(true);
  //   localStorage.removeItem("timerStartTime");
  //   const requestBody = {
  //     mobile: mobile,
  //     pan: panCard,
  //   };
  //   try {
  //     loginAPI(requestBody).then((response) => {
  //       setmessage( response.data.message)
  //       if (response.status === 200 && response.data.businessStatusCode === 2) {
  //         navigate("/getotp", { state: { mobile, panCard } });
  //       } else if ( response.data.httpResponseCode === 200 && response.data.businessStatusCode === 1 && response.data.businessStatusSubCode === 1.1 ){
  //         setIsOpenResume(true);
  //       }else {
  //         // API error: Set the error message
  //         alert(response.data.message);
  //         setApiError("Login failed. Invalid credentials.");
  //       }
  //     });
  //   } catch (error) {
  //     if (error.response.status === 401) {
  //       handleSignOut();
  //       alert("Your Session has expired.You will be redirected to Login Page.");
  //       navigate("/");
  //     }
  //     console.error("Error fetching loan data:", error);
  //   } finally {
  //     setIsLoading(false);
  //     setIsButtonClicked(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("timerStartTime");
    
    const requestBody = {
      mobile: mobile,
      pan: panCard,
    };
  
    setIsButtonClicked(true);
    setIsLoading(true);
    try {
      const response = await loginAPI(requestBody);
      dispatch(loginResponse(response));
      setmessage(response.data.message);
  
      if (response.status === 200 && response.data.businessStatusCode === 2) {
        navigate("/getotp", { state: { mobile, panCard } });
      } else if (
        response.data.httpResponseCode === 200 &&
        response.data.businessStatusCode === 1 &&
        response.data.businessStatusSubCode === 1.1
      ) {
        setIsOpenResume(true);
      } else {
        // API error: Set the error message
        toast.error(response.data.message);
        setApiError("Login failed. Invalid credentials.");
      }
    } catch (error) {
      handleApiError(error);
        console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };
  
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Set loading state to true
//     setIsLoading(true);
//     setIsButtonDisabled(true);
//     localStorage.removeItem("timerStartTime");
//     const requestBody = {
//       mobile: mobile,
//       pan: panCard,
//     };

//     try {

//       const response = await axios.post("/authn/auth", requestBody, {
//         withCredentials: true,
//       });

// setmessage( response.data.message)
//       if (response.status === 200 && response.data.businessStatusCode === 2) {
//         navigate("/getotp", { state: { mobile, panCard } });
//       } else if ( response.data.httpResponseCode === 200 && response.data.businessStatusCode === 1 && response.data.businessStatusSubCode === 1.1 ){
//         setIsOpenResume(true);
//       }else {
//         // API error: Set the error message
//         alert(response.data.message);
//         setApiError("Login failed. Invalid credentials.");
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         await handleSignOut();
//         alert("Your session has expired. You will be redirected to the login page.");
//         navigate('/');
//       }
//       // Network error
//       setApiError("An error occurred while communicating with the server.");

//       // Print the error in the console
//       console.error(error);
//     } finally {
//       // Reset loading state after the operation is complete
//       setIsLoading(false);
//       setIsButtonDisabled(false);
//     }
//   };

  return (
    <div className="login-form-new">
       <ResumeSessionPopup message={message} isOpen={isOpenResume} closeModal={closeModalResume}  mobile={mobile} panCard={panCard} />
      <div className="title">
        <b className="welcome">Welcome!</b>
        <div className="enter-details-to">Enter details to Login / Sign up</div>
      </div>
      <div className="form mt-4">
        <div className="mobile-no">
          <div
            className={`box ${isValidMobile ? "" : "error"}`}
            id="boxContainer"
          >
            <div className="content">
              <div className="mobile">
                <div className="rectangle-parent">
                  <div className="group-child"></div>
                  <div className="group-item"></div>
                </div>
              </div>
              <span id="country-code"></span>{" "}
              <input
                maxLength={10}
                type="text"
                className={`form-control ${isValidMobile ? "" : "error"
                  } mobile-input`}
                placeholder="Mobile No"
                value={mobile}
                onChange={handleMobileChange}
                onClick={handleMobileClick}
              />
            </div>
          </div>
          <div className="subtitle">
            <div
              className={`otp-verification-will ${isValidMobile ? "" : "invalid-input"
                }`}
            >
              {isValidMobile
                ? "OTP verification will be needed."
                : "Enter 10 digit valid number!"}
            </div>
          </div>
        </div>
        <div className="mobile-no">
          <div className={`box1 ${isValidPanCard ? "" : "error"}`}>
            <div className="content">
              <img className="card-icon1" alt="" src={card} />
              <input
                type="text"
                maxLength={10}
                className={`form-control ${isValidPanCard ? "" : "error"}`}
                placeholder="PAN Card No."
                style={{ border: "0px" }}
                value={panCard}
                onChange={handlePanCardChange}
              />
            </div>
          </div>
          <div className="subtitle">
            <div
              className={`pan-card-validation ${isValidPanCard ? "" : "invalid-input"
                }`}
            >
              {isValidPanCard
                ? "Valid Pan Card."
                : "Invalid Pan Card. Please enter in the specified format."}
            </div>
          </div>
        </div>

        <div className="checkbox">
          <input
            type="checkbox"
            id="agree"
            onChange={handleAgreeChange}
            checked={isTermsAccepted}
            disabled={isCheckboxDisabled || isTermsAccepted}
            style={{ cursor: !isTermsAccepted || isCheckboxDisabled ? "not-allowed" : "pointer" }}
          />
          <label htmlFor="agree" style={{ color: isCheckboxDisabled ? "#888" : "inherit" }}>
            For proceeding, please read and accept our {""} <br />
            <b>
              <span className="terms-conditions " onClick={openPopup}>
                Terms &amp; Conditions.
              </span>
              <TermsPopup isOpen={isOpen} closeModal={closeModal} onAcceptTerms={() => handleAcceptTerms(setIsTermsAccepted)} />
            </b>
          </label>
        </div>

        <Link
          to={isButtonDisabled ? "#" : "/getOtp"} // Change "getOtp" to the correct path
          className={`get-opt-sbmt ${isButtonDisabled ? "disabled" : ""}`}
          style={{
            opacity: isButtonDisabled ? 0.39 : (isButtonClicked ? 0.39 : 1),
            pointerEvents: isButtonDisabled ? 'none' : (isButtonClicked ? 'none' : 'auto')
          }}          
          onClick={(e) => (isButtonDisabled ? e.preventDefault() : handleSubmit(e))}
        >
          {isLoading ? (
            <LoadingSpinner style={{ pointerEvents: 'none' }} />
          ) : (
            'Get OTP'
          )}
        </Link>

      </div>
    </div>
  );
};

export default LoginForm;
