import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleSignOut } from "./SessionExpiredApi";
import { toast } from 'react-toastify';
import { Businesscheck_eNachStatusAPI, BusinessloanDocketCreationAPI } from "../../actioncreators/actioncreators";
const MandateLoadingPage = ({ setActiveStep }) => {
    const navigate = useNavigate();
    const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
    async function fetchRedirectionStatusForMandate() {
        try {
            const response = await Businesscheck_eNachStatusAPI();
            return response.data;
        } catch (error) {
            handleApiError(error);
            console.error(error);
            throw error; // Rethrow the error to handle it outside of this function
        }
    }
    useEffect( () => {
        fetchRedirectionStatusForMandate();
      }, [] )

    async function createLoanDocket() { 
        try {
            const response = await BusinessloanDocketCreationAPI();
            if (response.data.businessStatusCode === 2) {
                // alert("Your mandate setup is done. Please proceed for the e-sign.")
                setActiveStep(13);
            } 
            return response.data;
        } catch (error) {
            handleApiError(error);
            console.error(error);
            throw error; // Rethrow the error to handle it outside of this function
        }
    }
   
    useEffect(() => {
        const checkVkycRedirection = async () => {
            let ismandateRedirected = localStorage.getItem('isBusinessloanmandateRedirected');
    
            if (ismandateRedirected === 'true') {
                localStorage.setItem('isBusinessloanmandateRedirected', 'false');
    
                try {
                    let res = await fetchRedirectionStatusForMandate();
    
                    if (res.businessStatusCode === 2 || res.businessStatusSubCode === 1.2) {
                        // alert("Your mandate setup is done. Please proceed for the e-sign.")
                        createLoanDocket();
                    } else if (res.businessStatusSubCode === 1.1) {
                        setActiveStep(11);
                    } 
                } catch (error) {
                    handleApiError(error);
                    console.error(error);
                }
            }
        };
    
        checkVkycRedirection();
    }, [setActiveStep, navigate]);
    

    return (
        <div style={{ textAlign: "center", padding: "20px" }}>
            <p>Please wait, Your Application is in Process</p>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                style={{ margin: "20px" }}
            >
                <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="#3498db"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                >
                    <animate
                        attributeName="stroke-dashoffset"
                        dur="2s"
                        repeatCount="indefinite"
                        from="0"
                        to="502"
                    />
                    <animate
                        attributeName="stroke-dasharray"
                        dur="2s"
                        repeatCount="indefinite"
                        values="150.79644737231007 100.53096491487338;1 150"
                    />
                </circle>
            </svg>
        </div>
    );
};

export default MandateLoadingPage;
