import React, { useState, useEffect, useRef } from "react";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import ArthaContinueButton from "../fields/ArthaContinueButton";
import ArthaClearButton from "../fields/ArthaClearButton";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { handleSignOut } from "../Loan/PersonalLoan/SessionExpiredApi";
import { newSessionLoginAPI } from "../actioncreators/actioncreators";
import { toast } from "react-toastify";
function ResumeSessionPopup(props) {
  const { message,panCard,mobile } = props;
  const navigate = useNavigate();
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  const handleAcceptTerms = async () => {
    const requestBody = {
      pan: panCard,
      mobile: mobile
    };
  
    try {
      const response = await newSessionLoginAPI(requestBody);
      if (response.status === 200 && response.data.businessStatusCode === 2) {
        navigate("/getotp", { state: { mobile, panCard } });
      } 
    } catch (error) {
      handleApiError(error);
        console.error("Error fetching terms and conditions:", error);
    }
  
    props.closeModal();
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <div>
      {props.isOpen && (
        <div className="modal text-center" style={{paddingTop:'140px',paddingLeft:'260px'}} tabIndex="-1" role="dialog">
          <div className="modal-lg" role="text">
            <div className="modal-content">
              <div className="">
                <h6 className="text-center py-2">Confirmation</h6>
                <hr className="fw-lighter"/>
              </div>
              <div className="my-3 ">
               <h6 className="p-2 ps-4">{message}</h6> 
              </div>
              <div className="">
                <div className="row mt-2 mx-auto">
                  <div className="col-12 d-flex justify-content-center mt-2">
                    <ArthaContinueButton
                      variant="contained"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                      onClick={handleAcceptTerms}
                    >
                      Yes
                    </ArthaContinueButton>
                    <ArthaClearButton
                      variant="contained"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                      onClick={handleClose}
                    >
                      No
                    </ArthaClearButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResumeSessionPopup;
