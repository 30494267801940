import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleSignOut } from "./SessionExpiredApi";
import { toast } from 'react-toastify';
const MsignerLoadingPage = ({ setActiveStep }) => {
    const navigate = useNavigate();

    async function fetchRedirectionStatusForMsigner() {
        try {
            const response = await axios.get ("/personal-loan/emsigner-esign-status-check", {
                headers: {
                    "Accept": "application/json",
                    // Add other headers if needed
                },
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401){
                await handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
                       }else if (error.response && error.response.status === 429) {
                               toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
                             }else {
                                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
                              }
            console.error(error);
            throw error; // Rethrow the error to handle it outside of this function
        }
    }
    useEffect( () => {
        fetchRedirectionStatusForMsigner();
      }, [] )

    

    useEffect(() => {
        const checkMsignerRedirection = async () => {
            let isEmsignerRedirected = localStorage.getItem('isEmsignerRedirected');
    
            if (isEmsignerRedirected === 'true') {
                localStorage.setItem('isEmsignerRedirected', 'false');
    
                try {
                    let res = await fetchRedirectionStatusForMsigner();
    
                    if (res.businessStatusCode === 2 ) {
                        setActiveStep(15);
                       
                    } else if (res.businessStatusSubCode === 1 ) {
                        // setActiveStep(13);
                        navigate('/home/completedapplication')
                    } 
                } catch (error) {
                    if ( error.response.status === 401 ) {
                        handleSignOut();
                        toast.error( "Your Session has expired.You will be redirected to Login Page." )
                        navigate( '/' )
                    }else if (error.response && error.response.status === 429) {
                        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
                      }else {
                        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
                      }
                    console.error(error);
                }
            }
        };
    
        checkMsignerRedirection();
    }, [setActiveStep, navigate]);
    

    return (
        <div style={{ textAlign: "center", padding: "20px" }}>
            <p>Please wait, Your Application is in Process</p>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                style={{ margin: "20px" }}
            >
                <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="#3498db"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                >
                    <animate
                        attributeName="stroke-dashoffset"
                        dur="2s"
                        repeatCount="indefinite"
                        from="0"
                        to="502"
                    />
                    <animate
                        attributeName="stroke-dasharray"
                        dur="2s"
                        repeatCount="indefinite"
                        values="150.79644737231007 100.53096491487338;1 150"
                    />
                </circle>
            </svg>
        </div>
    );
};

export default MsignerLoadingPage;
