import React, { useState, useRef, useEffect } from "react";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { MdOutlineFileDownload } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import {
  FaIdBadge,
  FaCheck,
  FaExclamationTriangle,
  FaExclamationCircle,
} from "react-icons/fa";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Vkyc from "./vKYC.jsx";
import axios from "axios";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import { toast } from 'react-toastify';

const SanctionLetter = ({ onBack, onContinue, setActiveStep }) => {
  const fileInputRefAIS1 = useRef(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const fileInputRefAIS2 = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [stateResponse, setStateResponse] = useState();

  //GET API TO FETCH SANCTION LETTER DETAILS START -------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/personal-loan/sanction-letter-mitc-info", {
          headers: {
            "x-apid": ap_id,
            "x-loanappid": loan_app_id,
          },
        });
        // let response = {
        //   "sanctionLetterDocumentId": "31c2c84d-c7e9-45e8-acb8-e427ede15048",
        //   "sanctionLetterDocumentName": "FL00004PL00001_SANCTION_LETTER_20240229171752118.pdf",
        //   "mitcDocumentId": "ff98b481-0335-4bba-bf5b-ffff1dcafe1b",
        //   "mitcDocumentName": "FL00004PL00001_MITC_20240229171758476.pdf",
        //   "httpResponseCode": 200,
        //   "businessStatusCode": 2
        // }

        console.log("API Response:", response);

        if (response) {
          setStateResponse(response.data);
        }
      } catch (error) {
        if ( error.response.status === 401 ) {
          handleSignOut();
          toast.error( "Your Session has expired.You will be redirected to Login Page." )
          navigate( '/' )
      }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [ap_id, loan_app_id]);

  //GET API TO FETCH SANCTION LETTER DETAILS END -------------------------------------------------------------

  const handleBack = () => {
    onBack();
  };
  // const handleContinue = () => {
  //   onContinue();
  // };

  const handleAgreeChange = () => {
    setIsAgreed(!isAgreed);
  };

  // Download API call
  const downloadDocument = async (documentId) => {
    try {
      const payload = {
        documentId: documentId,
      };

      const documentresponse = await Axios.post(
        "/personal-loan/get-document-data-by-document-id",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-apid": ap_id,
            "x-loanappid": loan_app_id,
          },
        }
      );
      console.log("documentresponse", documentresponse);
      console.log("documentresponse.data", documentresponse.data);
      console.log(
        "documentresponse.data",
        documentresponse.data.documentBase64
      );

      if (
        documentresponse.status === 200 &&
        documentresponse.data.businessStatusCode === 2
      ) {
        console.log("documentresponse", documentresponse);
        console.log("documentresponse.data", documentresponse.data);
        console.log(
          "documentresponse.data",
          documentresponse.data.documentBase64
        );

        const base64Data = documentresponse.data.documentBase64;
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = documentresponse.data.documentName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Handle the case where the download is not successful
        console.error("Error downloading document:", documentresponse);
        // You may want to show an error message to the user
      }
    } catch (error) {
      if (error.response.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error downloading document:", error);
    }
  };
  
  const handleDownloadButton = async (documentId) => {
    await downloadDocument(documentId);
  };

  const [showVKYC, setShowVKYC] = useState(false);

  const handleContinue = () => {
    
   setIsButtonClicked(true);
   setIsLoading(true);
    setActiveStep(8);
  };
  return (
    <>
      {!showVKYC ? (
        <>
          <div className="row">
            <div className="col-6 mt-3 ">
              <h6>Sanction Letter and Other Documents</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-11 mt-2">
              <p className="small-heading kyc-form-text-size">
                Your 'Sanction Letter' and 'Most important Terms and
                Conditions'documents are sent to your registered email and
                mobile number.These documents can also be downloaded from here.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-md-9 mt-0 mt-md-4 ">
              <p className="msg">Sanction Letter </p>

              <>
                {/* <FaExclamationCircle className="badge-icon" /> */}
                <CgFileDocument
                  className="badge-icon1"
                  style={{ fontSize: "18px" }}
                />
                <span
                  className="mt-2 upload-lable"
                  style={{
                    position: "relative",
                    top: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {stateResponse?.sanctionLetterDocumentName}
                </span>
              </>
            </div>
            <div className="col-4 col-md-2 mt-0 mt-md-4">
              <input
                type="file"
                ref={fileInputRefAIS1}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={() =>
                  handleDownloadButton(stateResponse?.sanctionLetterDocumentId)
                }
              >
                <MdOutlineFileDownload style={{ fontSize: "20px" }} /> Download
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-9 mt-3 mt-md-4 ">
              <p className="msg">Terms & conditions</p>

              <>
                {/* <FaExclamationCircle className="badge-icon" /> */}
                <CgFileDocument
                  className="badge-icon1"
                  style={{ fontSize: "18px" }}
                />
                <span
                  className="mt-2 upload-lable"
                  style={{
                    position: "relative",
                    top: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {stateResponse?.mitcDocumentName}
                </span>
              </>
            </div>
            <div className="col-4 col-md-2 mt-4">
              <input
                type="file"
                ref={fileInputRefAIS2}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={() =>
                  handleDownloadButton(stateResponse?.mitcDocumentId)
                }
              >
                <MdOutlineFileDownload style={{ fontSize: "20px" }} /> Download
              </Button>
            </div>
            <div className="col-12 col-md-12 mt-3 checkbox">
              <input
                type="checkbox"
                id="agree"
                checked={isAgreed}
                onChange={handleAgreeChange}
              />
              <label htmlFor="agree">
                I accept the terms of the above sanction letter
              </label>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-6 d-flex justify-content-end">
              <ArthaClearButton
                type="button"
                className="custom_btn"
                onClick={handleBack}
              >
                Back
              </ArthaClearButton>
            </div>
            <div className="col-6 d-flex justify-content-start">
              <ArthaContinueButton
                type="button"
                className={`get-opt-sbmt ${!isAgreed && "disabled"}`}
                style={{
                  opacity: isButtonClicked || !isAgreed ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto",
                }}                
                onClick={handleContinue}
                disabled={!isAgreed}
              >
                {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : (
        <Vkyc
          setActiveStep={setActiveStep}
        />
      )
      }

    </>
  );
};

export default SanctionLetter;
