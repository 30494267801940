import React, { useState, useEffect} from 'react';
import ArthaContinueButton from '../../fields/ArthaContinueButton';
import ArthaClearButton from '../../fields/ArthaClearButton';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from '../../fields/spinner';
import { toast } from 'react-toastify';
const Vkyc = ({ setActiveStep }) => { // Added destructuring to get setActiveStep directly

    const [openDialog, setOpenDialog] = useState(false);
    const [response, setResponse] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);


    const handleSkipClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    async function fetchData_back() {
        // Set loading state to true
        setIsButtonClicked(true);
        setIsLoading(true);
        try {
            const response = await axios.get("/personal-loan/vkyc-fetch-url", {
                headers: {
                    "Accept": "application/json",
                    // Add any additional headers if required
                },
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                await handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            }else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
              }else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
              }
            console.log(error);
        } finally {
            // Reset loading state after the operation is complete
            setIsLoading(false);
            setIsButtonClicked(false);
        }
        return null;
    }

    const handleContinue = async () => { // Made handleContinue async to correctly handle the asynchronous fetchData_back

        let res = await fetchData_back();
        if (res.businessStatusCode === 2) {
            localStorage.setItem('isVkycRedirected', 'true');
            let externalUrl = res.vkycUrl;
            window.location.href = externalUrl;
        }
        setResponse(res);
    };

    // const handleContinue_back = () => {
    //     localStorage.setItem('isVkycRedirected', 'true');
    //     window.location.href = 'https://webvkycfesb.digitap.work/okycvkyc?transactionId=NDMwMzEzNzY6MGMxMWZlMTMtMDYxMC00NTVjLWI0ZDgtOGRhNDFjYWQxNjI1';
    // };

    async function fetchSkip() {
        try {
            const response = await axios.get("/personal-loan/vkyc-skip", {
                headers: {
                    "Accept": "application/json",
                },
            });
            console.log("version 1");
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                await handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            }else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
              }else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
              }
            console.log(error);
        }
        return null;
    }
   
    const onSkipHandleChange = async () => {
        let res = await fetchSkip();
        setActiveStep(10);
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            // Prevent closing when clicking on the backdrop
            return;
        }
        // Close the dialog
        handleCloseDialog();
    };



    return (
        <div>

            <div className="row">
                <div className="col-9"><span style={{ fontSize: "13px", fontWeight: "700" }}>VKYC</span></div>
                <div className="col-3 ps-5">
                    <a
                        onClick={handleSkipClick}
                        style={{
                            color: "rgb(255, 115, 0)",
                            fontWeight: "bold",
                            textDecoration: "none",
                            cursor: "pointer",

                        }}
                    >
                        Skip
                    </a>
                </div>
                <div className="col-12">
                    <span style={{ fontSize: "13px", }}>Perform V-KYC to expedite loan Processing </span>
                </div>
                <div className="col-12 mt-3">
                    <span style={{ fontSize: "13px", }}>Click continue to procced V-KYC.</span>
                </div>

                <div className="row mt-4 ">
                    <div className="col-7 d-flex justify-content-end mt-4">
                        {/* <ArthaClearButton variant="contained" onClick={handleSkipClick} >
                            Skip
                        </ArthaClearButton> */}
                    </div>
                    <div className="col-5 ms-auto mt-4">
                        <ArthaContinueButton
                            variant="contained"
                            onClick={handleContinue}
                            style={{
                                opacity: isButtonClicked ? 0.39 : 1,
                                pointerEvents: isButtonClicked ? "none" : "auto"
                            }}
                        >
                            {isLoading ? (
                                <LoadingSpinner style={{ pointerEvents: 'none' }} />
                            ) : (
                                'Continue'
                            )}
                        </ArthaContinueButton>
                    </div>

                </div>
            </div>
            <Dialog open={openDialog} onClose={handleClose} PaperProps={{
                style: {
                    width: '40vw',
                    maxWidth: '100%',
                    overflow: 'hidden',
                },
            }}>
                <div className="row" style={{ overflowX: "hidden", margin: "10px" }}>
                    <div className="12"><span style={{ fontSize: "13px", fontWeight: "700", }}>Skip V-KYC</span></div>
                    <div className="col-12">
                        <span style={{ fontSize: "13px", }}>Please Confirm if you want to skip V-KYC </span>
                    </div>
                    <div className="col-12 mt-3">
                        <span style={{ fontSize: "13px", }}>Physical KYC will take more time and will delay the loan processing.</span>
                    </div>

                    <div className="row mt-4 mx-auto">
                        <div className="col-6 d-flex justify-content-end mt-4">
                            <ArthaClearButton variant="contained" onClick={handleCloseDialog}>
                                Back
                            </ArthaClearButton>
                        </div>
                        <div className="col-6 d-flex justify-content-start mt-4">
                            <ArthaContinueButton
                                variant="contained"
                                onClick={onSkipHandleChange}
                            >
                                Continue
                            </ArthaContinueButton>
                        </div>
                    </div>
                </div>
            </Dialog>



        </div>


    )
}
export default Vkyc;