import React from 'react';
import './CreditScore.css'; // Assuming you have a CSS file for styling

const CreditScore = ({ score }) => {
 return (
    <div className="credit-score-container mb-2">
      <h6 className="credit-score-title">Your Credit Score is</h6>
      <p className="credit-score-value">{score}</p>
    </div>
 );
};

export default CreditScore;
