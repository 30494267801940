import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoanOffer from "./LoanOffer";
import axios from "axios";
import { handleSignOut } from "./SessionExpiredApi";
import { toast } from 'react-toastify';

const LoadingComponent = ( { setActiveStep, isLoading } ) => {
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const navigate = useNavigate();
  const [ timeRemaining, setTimeRemaining ] = useState( 59 );

  useEffect( () => {
    const interval = setInterval( () => {
      setTimeRemaining( prevTime => prevTime - 1 );
    }, 1000 );


    return () => clearInterval( interval );
  }, [ timeRemaining ] );

  const handleLoanOfferIntegration = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-apid": ap_id,
        "x-loanappid": loan_app_id,
      };

      const apiUrl = "/personal-loan/integration-api";

      const response = await axios.post( apiUrl, {}, { headers } );

      console.log( "response", response );
      console.log( "ResponseData", response.data );

      if ( response.data.httpResponseCode === 200 && response.data.businessStatusCode === 2 ) {
        const responseBody = response.data;
        setActiveStep( 6 );
        navigate( LoanOffer, {
          state: {
            ap_id: ap_id,
            loan_app_id: loan_app_id,
            response: responseBody,
          },
        } );
      } else if ( response.data.businessStatusCode === 1 && response.data.businessStatusSubCode === 1.1 ) {
        toast.error( response.data.message );
        isLoading( false );
        setActiveStep( 5 );


      } else {
        navigate( "/home/completedapplication" );
        console.error(
          "Error in API call:",
          response.data.message || response.statusText
        );
      }
    } catch ( error ) {
      if ( error.response.status === 401 ) {
        await handleSignOut();
        toast.error( "Your Session has expired.You will be redirected to Login Page." )
        navigate( '/' )
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      navigate( "/home/completedapplication" );
      console.error( "Error:", error );
    }
  };

  useEffect( () => {
    handleLoanOfferIntegration();
    console.log( "isloading", isLoading )
  }, [ isLoading ] );

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <p>Please wait, while your application is being analyzed</p>
      <p style={{ fontSize: '14px' }}>wait : {timeRemaining} sec</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ margin: "20px" }}
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="#3498db"
          strokeWidth="4"
          fill="none"
          strokeLinecap="round"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="2s"
            repeatCount="indefinite"
            from="0"
            to="502"
          />
          <animate
            attributeName="stroke-dasharray"
            dur="2s"
            repeatCount="indefinite"
            values="150.79644737231007 100.53096491487338;1 150"
          />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingComponent;
