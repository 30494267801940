import React, { useState, useRef, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import { toast } from "react-toastify";
const UploadBankStatementPopup = ({
  open,
  handleClose,
  bank,
  fileNames,
  removeFileFromFileNames,
  setFileNames,
}) => {
  const [fileInfo, setFileInfo] = useState([]);
  const [currentFile, setCurrentFile] = useState();

  const hiddenFileInput = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      // Check if adding this file exceeds the limit
      if (fileInfo.length + selectedFiles.length > 6) {
        // Alert the user or handle the error accordingly
        toast.error("You can only upload a maximum of 6 documents.");
        return; // Stop execution if the limit is reached
      }

      // Check if the file type is ePDF
      const isEPDF = Array.from(selectedFiles).every(file => {
        return file.type === 'application/pdf';
      });

      if (!isEPDF) {
        toast.error("Only ePDF documents are allowed.");
        return; // Stop execution if the file type is not ePDF
      }

      // Add each file to fileInfo state
      const newFiles = Array.from(selectedFiles).map((file) => ({
        file,
        password: ""
      }));

      setFileInfo((prevFileInfo) => [...prevFileInfo, ...newFiles]);
      setCurrentFile(selectedFiles[0]); // Set the current file to the last one added
    }
  };

  const handleDeleteFile1 = (file) => {
    removeFileFromFileNames(file, bank.bankId);
    setCurrentFile(null);
  };
  const handleDeleteFile = (index) => {
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = [...prevFileInfo];
      updatedFileInfo.splice(index, 1);
      return updatedFileInfo;
    });

    setCurrentFile(null);
  };

  const handlePasswordChange = (index, value) => {
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = [...prevFileInfo];
      updatedFileInfo[index].password = value;
      return updatedFileInfo;
    });
  };

  const handlePasswordEdit = (outerIndex, innerIndex, value) => {
    if (fileNames) {
      let outerFile = fileNames[outerIndex];
      let existingFile = outerFile.files[innerIndex];
      existingFile.password = value;
      const updatedFileNames = [...fileNames];
      if (
        updatedFileNames[outerIndex] &&
        updatedFileNames[outerIndex].files[innerIndex]
      ) {
        updatedFileNames[outerIndex].files[innerIndex].password = value;
      }
      setFileNames(updatedFileNames);
    }
  };

  const handleAddFile = () => {
    setCurrentFile(null);
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
  // Updated fileToBase64 function to return a Promise
  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const base64String = event.target.result.split(",")[1]; // Extracting the base64 data part
        resolve(base64String);
      };

      reader.onerror = function (error) {
        console.error("Error reading the file:", error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  const handleSave = async () => {
    // Create an array to store file names and corresponding passwords
    const filesData = await Promise.all(
      fileInfo.map(async (file) => ({
        name: file.file.name,
        password: file.password,
        bankStatement: await fileToBase64(file.file),
      }))
    );

    // Log the array to the console
    console.log("Files Data:", filesData);

    // Perform actions with the selected files and passwords
    // For now, just close the dialog and reset states
    setFileInfo([]);
    setCurrentFile(null);
    handleClose(filesData, bank.bankId); // Close the dialog by setting the open prop to false
  };



  const renderButtons = () => {
    if (currentFile) {
      return (
        <>
          <div className="row mt-4">
            <div className="uploadadhar  row ">
              <span>
                <label
                  htmlFor="fileInput"
                  className="upload-label d-flex justify-content-center"
                >
                  <span className="mt-1">+Add File</span>
                </label>
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                />
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6 d-flex justify-content-center">
              <ArthaClearButton
                type="button"
                onClick={() => {
                  setFileInfo([]); // Clear files when cancel is clicked
                  setCurrentFile(null);
                }}
              >
                Cancel
              </ArthaClearButton>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn"
                onClick={handleSave}
              >
                Continue
              </ArthaContinueButton>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose([])} maxWidth="lg">
      <DialogContent>
        <div className="row d-flex justify-content-center mb-4">
          <div className="col-12 text-center">
            {bank.bankName} Bank Statement From {bank.bankTimePeriodFirstMonth}{" "}
            To {bank.bankTimePeriodLastMonth}
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-12 text-center">
            {fileNames &&
              fileNames.map((item, outerIndex) => {
                if (item.id === bank.bankId) {
                  return item.files.map((file, innerIndex) => (
                    <div key={innerIndex} className="uploadadhar  row p-0 m-0">
                      <div className="uploaded-image-container col-12 d-flex">
                        <p
                          className="doc-label col-2 align-items-top"
                          style={{ fontSize: "12px", paddingTop: "7px" }}
                        >{`Document ${innerIndex + 1
                          }`}</p>
                        <div className="col-5 mb-1">
                          <span
                            style={{
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {file.name} 88
                          </span>
                        </div>
                        <div className="col-1">
                          <span
                            onClick={() => handleDeleteFile1(file)}
                            title="Delete"
                            style={{ width: "50px", fontSize: "12px" }}
                          >
                            <img
                              src={Cancel}
                              style={{ width: "20px", justifyContent: "center" }}
                              alt="Cancel"
                            />
                          </span>

                        </div>


                        <div className="col-4">
                          <input
                            type="password"
                            placeholder={`Enter Document password ${innerIndex + 1
                              }`}
                            value={file.password}
                            onChange={(e) =>
                              handlePasswordEdit(
                                outerIndex,
                                innerIndex,
                                e.target.value
                              )
                            }
                            style={{
                              width: "100%",
                              height: "33 px",
                              padding: "6px 12px",
                              fontSize: "12px",
                              lineHeight: "1.5",
                              color: "#495057",
                              backgroundColor: "#fff",
                              border: "1px solid #ced4da",
                              borderRadius: "0.25rem",
                              boxShadow: "none",
                              transition:
                                "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                            }}
                          />
                        </div>
                      </div>
                      <hr style={{ height: '1px', borderWidth: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />

                    </div>

                  ));
                }
                return null; // If the IDs don't match, you can return null or an empty array
              })}
          </div>
        </div>

        {fileInfo.map((file, index) => (
          <div key={index} className="uploadadhar  row p-0 m-0">
            <div className="uploaded-image-container col-12 d-flex">
              <p
                className="doc-label col-2 align-items-top"
                style={{ fontSize: "12px", paddingTop: "7px" }}
              >{`Document ${index + 1}`}</p>
              <div className="col-5 mb-1">
                <span
                  style={{
                    fontSize: "12px",
                    wordWrap: "break-word",
                  }}
                >
                  {file.file.name} 11
                </span>
              </div>

              <div className="col-1">
                <span
                  onClick={() => handleDeleteFile(index)}
                  title="Delete"
                  style={{ width: "50px", fontSize: "12px" }}
                >
                  <img
                    src={Cancel}
                    style={{ width: "20px", justifyContent: "center" }}
                    alt="Cancel"
                  />
                </span>
              </div>
              <div className="col-4">
                <input
                  type="password"
                  placeholder={`Enter Document password ${index + 1}`}
                  value={file.password}
                  onChange={(e) => handlePasswordChange(index, e.target.value)}
                  style={{
                    width: "100%",
                    height: "33 px",
                    padding: "6px 12px",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    color: "#495057",
                    backgroundColor: "#fff",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    boxShadow: "none",
                    transition:
                      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                  }}
                />
              </div>
            </div>
            <hr style={{ height: '1px', borderWidth: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />


            {/* <div className="row mt-4">
              <div className="col-8 mx-auto">
              
              </div>
            </div> */}
          </div>
        ))}

        {!currentFile && (
          <>
            <div className="uploadadhar  row ">
              <span>
                <label
                  htmlFor="fileInput"
                  className="upload-label d-flex justify-content-center"
                >
                  <span className="upload-icon">
                    <MdOutlineFileUpload />
                  </span>
                  <span className="mt-1">Upload</span>
                </label>
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                />
              </span>
            </div>
            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-center">
                <ArthaClearButton
                  type="button"
                  onClick={() => {
                    setFileInfo([]); // Clear files when cancel is clicked
                    setCurrentFile(null);
                    handleClose([]); // Close the popup and pass an empty array
                  }}
                >
                  Cancel
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-center">
                <ArthaContinueButton
                  type="button"
                  className="orange custom_btn"
                  onClick={handleSave}
                >
                  Continue
                </ArthaContinueButton>
              </div>
            </div>
          </>
        )}
        {renderButtons()}
      </DialogContent>
    </Dialog>
  );
};

export default UploadBankStatementPopup;
