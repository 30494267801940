import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField1.js";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import {
  propertyLoanFetchDataAPI,
  propertyLoanStoreDetailsAPI,
  propertyLoanUserDetailsAPI,
  propertyLoanCreateLoanApplicationAPI,
} from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
import { Select, MenuItem } from "@mui/material";

const BasicPropertyDetails = ({ onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    propertyName: "",
    propertyType: "",
    propertyOwnership: "",
    propertyPin: "",
    numberOfOwners: "",
    propertyAge: "",
    propertyValuation: "",
    currentAddressPincode: "",
    name: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [propertyTypeDropdown, setPropertyTypeDropdown] = React.useState([]);
  const [propertyAgeDropdown, setPropertyAgeDropdown] = React.useState([]);
  const [propertyOwnerDropdown, setPropertyOwnerDropdown] = React.useState([]);
  const [propertyOwnerNumberDropdown, setPropertyOwnerNumberDropdown] =
    React.useState([]);
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false);
  const [pinCodeError, setPinCodeError] = useState("");

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return;

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }
    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await propertyLoanCreateLoanApplicationAPI();
        const lapLoanApplicationStatus = response?.status;
        const fetchData = async () => {
          try {
            const response1 = await propertyLoanUserDetailsAPI();

            if (response1 !== null) {
              setPropertyForms(response1);
            }
          } catch (error) {
            handleApiError(error);
            console.error("Error fetching user data:", error);
          }
        };
        fetchData();
        navigate("/home/loanagainstproperty", {
          state: {
            lapLoanApplicationStatus,
          },
        });
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    };
    fetchData1();
  }, []);

  useEffect(() => {
    validateForm();
  }, []);

  // Fetching dropdown Values
  useEffect(() => {
    let apiPayload = {
      source: "json_value",
      type: "LAP_LOAN",
      key: "PROPERTY_TYPE",
    };
    fetchDropDownValues(apiPayload, "PROPERTY_TYPE");
    let apiPayload1 = {
      source: "json_value",
      type: "LAP_LOAN",
      key: "PROPERTY_OWNEDBY",
    };
    fetchDropDownValues(apiPayload1, "PROPERTY_OWNEDBY");

    let apiPayload2 = {
      source: "json_value",
      type: "LAP_LOAN",
      key: "NUMBER_OF_OWNERS",
    };
    fetchDropDownValues(apiPayload2, "NUMBER_OF_OWNERS");
    let apiPayload3 = {
      source: "json_value",
      type: "LAP_LOAN",
      key: "PROPERTY_AGE",
    };
    fetchDropDownValues(apiPayload3, "PROPERTY_AGE");
  }, []);

  const fetchDropDownValues = async (apiPayload, dropdownType) => {
    setPropertyTypeDropdown(["Residential", "Commercial"]);
    setPropertyOwnerDropdown(["Joint"]);
    setPropertyAgeDropdown(["1", "2", "3", "24"]);
    setPropertyOwnerNumberDropdown(["1", "2", "3"]);
    try {
      const response = await propertyLoanFetchDataAPI(apiPayload);

      if (dropdownType === "PROPERTY_TYPE") {
        setPropertyTypeDropdown(response?.data?.[0]?.PropertyType);
      } else if (dropdownType === "PROPERTY_OWNEDBY") {
        setPropertyOwnerDropdown(response?.data?.[0]?.PropertyOwnedBy);
      } else if (dropdownType === "NUMBER_OF_OWNERS") {
        setPropertyOwnerNumberDropdown(response?.data?.[0]?.NumberOfOwners);
      } else if (dropdownType === "PROPERTY_AGE") {
        setPropertyAgeDropdown(response?.data?.[0]?.PropertyAge);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching address vintage:", error);
    }
  };

  const validateForm = () => {
    const {
      propertyName,
      propertyType,
      numberOfOwners,
      propertyAge,
      propertyOwnership,
      propertyPin,
      propertyValuation,
    } = formData;

    const isValid =
      propertyName !== "" &&
      propertyType !== "" &&
      numberOfOwners !== "" &&
      propertyAge !== "" &&
      propertyOwnership !== "" &&
      propertyPin !== "" &&
      propertyValuation !== "";

    setIsFormValid(isValid);
  };

  const handleClear = () => {
    navigate("/home");
  };

  useEffect(() => {
    const pinCodeInput = document.getElementById("pin-code-inpt");
    if (pinCodeInput) {
      pinCodeInput.focus();
    }
  }, []);

 

  const closeModal = () => {
    setIsOpen(false);
    let inpt = document.getElementById("pin-code-inpt");
    inpt.focus();
  };

  const [propertyForms, setPropertyForms] = useState([
    {
      propertyName: "",
      propertyType: "",
      propertyOwnership: "",
      propertyPin: "",
      numberOfOwners: "",
      propertyAge: "",
      propertyValuation: "",
      propertyID: "",
    },
  ]);

  const handleChange = (e,index) => {
    const { name, value } = e.target;
    const updatedForms = [...propertyForms];
    updatedForms[index][name] = value;
    setPropertyForms(updatedForms);
  
    // Apply specific validation based on the field name
    let updatedValue = value;
     if (name === "currentAddressPincode") {
      updatedValue = value.replace(/\D/g, "").slice(0, 6);
      const isPinCodeValid = /^\d{6}$/.test(updatedValue);
      if (!isPinCodeValid) {
        setPinCodeError("Pincode must be 6 digits");
      } else {
        setPinCodeError("");
      }
    } 
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
      //...additionalUpdates,
    }));
    // validateForm(); // Make sure validateForm() is defined elsewhere
  };

  // const handleChange1 = (e) => {
  //   const { name, value } = e.target;
  //   const updatedForms = [...propertyForms];
  //   updatedForms[name] = value;
  //   setPropertyForms(updatedForms);
  // };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddProperty = () => {
    setPropertyForms([
      ...propertyForms,
      {
        propertyName: "",
        propertyType: "",
        propertyOwnership: "",
        propertyPin: "",
        numberOfOwners: "",
        propertyAge: "",
        propertyValuation: "",
        propertyID: "",
      },
    ]);
  };

  const handleRemoveProperty = (index) => {
    const updatedForms = propertyForms.filter((_, i) => i !== index);
    setPropertyForms(updatedForms);
  };

  const handleContinue = async (values, setSubmitting) => {
    setIsButtonClicked(true);
    setIsLoading(true);

    const data = {
      pinCode: formData.currentAddressPincode,
      fullName: formData.name,
      property: propertyForms.map(form => ({
          numberOfOwners: form.numberOfOwners,
          propertyAge: form.propertyAge,
          propertyName: form.propertyName,
          propertyOwnership: form.propertyOwnership,
          propertyPin: form.propertyPin,
          propertyType: form.propertyType,
          propertyValuation: form.propertyValuation,
          propertyID: form.propertyID, 
      }))
  };
  
    try {
      const response = await propertyLoanStoreDetailsAPI(data);

      if (response.businessStatusCode === 2) {
        setShowEligibilityCheckForm(true);
        onSubmit(formData);
        toast.success("Applicant Details Added Successfully");
      } else if (
        response.businessStatusCode === 1 &&
        response.businessStatusSubCode === 1.1
      ) {
        toast.info(response.message);
      } else if (
        response.businessStatusCode === 1 &&
        response.businessStatusSubCode === 1.3
      ) {
        toast.warning(response.message);
        navigate("/home");
      } else {
        setShowEligibilityCheckForm(false);
        setIsOpen(true);
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };
  return (
    <>
      {!showEligibilityCheckForm ? (
        <>
          <h6 className="text mt-4">Initial Applicant Details</h6>

          <form
            className="mt-4 border border-dark p-3"
            style={{ borderRadius: "10px" }}
          >
            <div className="row mt-2 ">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="name"
                  value={formData.name || ""}
                  onChange={(e) => handleChange1(e)}
                  label="Full Name"
                  fullWidth
                  variant="outlined"
                  // disabled
                  className="form-control fs-5 custom-disabled-input"
                />
                <span className="msg">As per PAN</span>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="currentAddressPincode"
                  type="text"
                  maxLength="6"
                  value={formData.currentAddressPincode || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 6) {
                      handleChange1(e);
                    }
                  }}
                  label="Pincode of your current address"
                  id="pin-code-inpt"
                  fullWidth
                  inputMode="numeric"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </form>

          <h6 className="text mt-4">Basic Property Details</h6>
          {propertyForms?.map((formData, index) => (
            <form
              key={index}
              className="mt-4 border border-dark p-3"
              style={{ borderRadius: "10px" }}
            >
              <p
                className="col-md-auto"
                style={{
                  fontSize: "16px",
                  // marginTop: "-25px",
                  marginLeft: "7px",
                }}
              >
                Property - {index + 1}
              </p>
              <div className="row mt-2 ">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <ArthaTextField
                    id="outlined-basic"
                    name="propertyName"
                    value={formData.propertyName || ""}
                    onChange={(e) => handleChange(e, index)}
                    label="Property Short Name"
                    fullWidth
                    variant="outlined"
                    className="form-control fs-5 custom-disabled-input"
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyType-label">
                      Property Type
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyType-label"
                      id="propertyType"
                      name="propertyType"
                      value={formData.propertyType || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyTypeDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>
              </div>

              <div className="row mt-3 mt-md-4">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyOwnership-label">
                      Property Owned By
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyOwnership-label"
                      id="propertyOwnership"
                      name="propertyOwnership"
                      value={formData.propertyOwnership || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyOwnerDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="numberOfOwners-label">
                      Number of Owners in the Property
                    </ArthaInputLabel>
                    <Select
                      labelId="numberOfOwners-label"
                      id="numberOfOwners"
                      name="numberOfOwners"
                      value={formData.numberOfOwners || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyOwnerNumberDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>
              </div>

              <div className="row mt-3 mt-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <ArthaTextField
                    type="text"
                    name="propertyValuation"
                    value={formData.propertyValuation || ""}
                    onChange={(e) => handleChange(e, index)}
                    label="Approximate Property Valuation (₹)"
                    fullWidth
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                  <ArthaFormControl fullWidth>
                    <ArthaInputLabel id="propertyAge-label">
                      Property Age
                    </ArthaInputLabel>
                    <Select
                      labelId="propertyAge-label"
                      id="propertyAge"
                      name="propertyAge"
                      value={formData.propertyAge || ""}
                      onChange={(e) => handleChange(e, index)}
                    >
                      {propertyAgeDropdown?.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ArthaFormControl>
                </div>
              </div>
              <div className="row mt-3 mb-2 mt-md-2">
                <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-3">
                  <ArthaTextField
                    name="propertyPin"
                    type="text"
                    maxLength="6"
                    value={formData.propertyPin || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 6) {
                        handleChange(e, index);
                      }
                    }}
                    label="Property Pincode"
                    id="pin-code-inpt"
                    fullWidth
                    inputMode="numeric"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(pinCodeError)}
                  helperText={pinCodeError}
                  autoFocus
                  />
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 "></div>
                <div className="col-sm-12 col-md-3 col-lg-3 mt-3 mt-md-3 text-end">
                  {propertyForms.length > 1 && (
                    <ArthaClearButton
                      variant="contained"
                      onClick={() => handleRemoveProperty(index)}
                    >
                      Remove Property
                    </ArthaClearButton>
                  )}
                </div>
              </div>
            </form>
          ))}

          <div className="col-12 mt-4">
            <ArthaClearButton variant="contained" onClick={handleAddProperty}>
              Add Property
            </ArthaClearButton>
          </div>
          <div className="row mt-4 mx-auto">
            <div className="col-6 d-flex justify-content-end mt-4">
              <ArthaClearButton variant="contained" onClick={handleClear}>
                Cancel
              </ArthaClearButton>
            </div>

            <div className="col-6 d-flex justify-content-start mt-4">
              <ArthaContinueButton
                variant="contained"
                onClick={handleContinue}
                // disabled={!isFormValid}
                // style={{
                //   backgroundColor: isFormValid
                //     ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                //     : "rgb(255 98 0 / 39%)",
                //   // opacity: isButtonClicked ? 0.39 : 1,
                //   pointerEvents: isButtonClicked ? "none" : "auto", // Lower opacity when form is not valid
                // }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Continue"
                )}
              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : (
        <EligibilityCheckForm
          onContinue={onSubmit}
          onCancel={() => setShowEligibilityCheckForm(false)}
        />
      )}
    </>
  );
};

export default BasicPropertyDetails;
