import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { MdOutlineFileUpload, MdDelete } from "react-icons/md";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { useLocation } from 'react-router-dom';
import EmployementDetails from "./EmployementDetails";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useEffect } from "react";
import { handleSignOut } from "./SessionExpiredApi";
import FetchingCreditscorePopup from "./FetchingCreditscorePopup";
import CreditScore from "./CreditScore";
import "./CreditScore.css"
import LoadingSpinner from "../../fields/spinner";
import { getImageMapAPI,imageMatchAPI,cibilAPI } from "../../actioncreators/actioncreators";
import { toast } from 'react-toastify';
function PhotoVerification({ onBack, onContinue, changeStatus }) {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
  const webcamRef = useRef(null);
  const [captureMode, setCaptureMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  // Assuming base64String, ApIdString, and LoanAppIdString are defined elsewhere
  const [base64String, setBase64String] = useState(null);
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [formData, setFormData] = useState({ imageData: [] });
  const [showEmployementDetails, setShowEmployementDetails] =
      useState(false);
  // const navigate = useNavigate();
  const [Successmessage, Setmessage] = useState(false);
  const [Status, setStatus] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try{
      const response = await getImageMapAPI(); 
          const { imageData } = response.data;
          setFormData(imageData);
          setUploadedImage(`data:image/jpeg;base64,${imageData}`);
          const {status} = response.data;
          setStatus(status);
          const { message } = response.data;
          Setmessage(message);
        }catch(error) {
          if (error.response.status === 401) {
            handleSignOut();
            toast.error("Your Session has expired.You will be redirected to Login Page.")
            navigate('/')
          }else if (error.response && error.response.status === 429) {
            toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
          }else {
            toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
          }
          console.error('Error fetching user data:', error);
        };
      };

      fetchData();
  }, [ap_id, loan_app_id]);


  const handleDeleteImage = () => {
    setStatus(null);
    setUploadedImage(null);
    setBase64String(null);
    setCaptureMode(false);
    setIsPhotoUploaded(false);
  };

  const handleTakePhoto = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some(device => device.kind === 'videoinput');

      if (!hasCamera) {
        showCameraNotFoundAlert();
        return;
      }

      if (webcamRef.current) {
        const photo = webcamRef.current.getScreenshot();
        setUploadedImage(photo);
        setIsPhotoUploaded(true);
        setBase64String(photo.split(",")[1]);
        setCaptureMode(true); // Set capture mode to true after taking the photo
      }
    } catch (error) {
      if ( error.response.status === 401 ) {
        handleSignOut();
        toast.error( "Your Session has expired.You will be redirected to Login Page." )
        navigate( '/' )
    }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error capturing photo:", error);
      showCameraErrorAlert();
    }
  };

  const handleRetakePhoto = () => {
    setUploadedImage(null);
    setIsPhotoUploaded(false);
    setBase64String(null);
    setStatus(null);
    setCaptureMode(false); // Set capture mode to false to show the camera window again
  };

  const showCameraNotFoundAlert = () => {

    toast.error("No camera found. Please check if the camera is connected and working properly.");
  };

  const showCameraErrorAlert = () => {

    toast.error("Error accessing the camera. Please check if the camera is connected and working properly.");
  };

  // const handleContinue = () => {
  //   // Call the API request function
  //   handleApiRequest();

  // };


  const handleApiRequest = async () => {
    // setIsModalOpen(true);
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);
    const headers = {
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };
    if (base64String || formData) {
      // Create your API payload, including the imageBase64 variable.
      const apiPayload = {
        imageType: "SELFIE",
        imageData: base64String || formData, // Include the base64 image in your payload
      };

      try {
        const response = await imageMatchAPI(apiPayload); 
    
        if (response.status === 200 && response.data.businessStatusCode === 2 ) {
          if(response.data.businessStatusSubCode === 2.1){
            setIsModalOpen1(false);
            setShowEmployementDetails(true);
            onContinue();
          } else {
            setIsModalOpen1(true);
            handleContinue();
          }

          // Assuming matchImage() is defined elsewhere
          navigate(EmployementDetails, {
            state: {
              ap_id,
              loan_app_id,
            },
          });
        } else {
          toast.error("You can try again, because we are unable to proceed as the photo you submitted does not match the one on your KYC documents");
          console.error("API request failed");
        }
      
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired. You will be redirected to the Login Page.");
          navigate('/');
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
          // Handle network or other errors here
          console.error("API request error:", error);
        
      }
      finally {
        // Reset loading state after the operation is complete
        setIsLoading(false);
        setIsButtonClicked(false);
      }
    }
  };
 
  const handleContinue = async () => {
    try {
      const response = await cibilAPI(); 
          setCreditScore(response.data.cibilScore)
          // Check if the response is successful
          if (response.data.httpResponseCode === 200 && response.data.businessStatusCode === 1) {
            // Navigate to the Emi_Calculator page
            navigate("/home/creditscoreoffmark");

          } else if (response.data.hasOwnProperty('cibilScore')) {
            setIsModalOpen1(false);
            setIsModalOpen(true);
          }else {
            setShowEmployementDetails(true);
            onContinue();
          }
        
      } catch(error) {
          if (error.response.status === 500) {
            setIsModalOpen1(false);
            toast.error("There appears to be a technical issue connecting to CBIL. Could you please try again later?.")
          }
          if (error.response.status === 401) {
            handleSignOut();
            toast.error("Your Session has expired.You will be redirected to Login Page.")
            navigate('/')
          }else if (error.response && error.response.status === 429) {
            toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
          }else {
            toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
          }
          console.error('Error fetching loan data:', error);
        };
  };
 
  const handleBack = () => {
    onBack();
    changeStatus("KYC_DETAILS")
  };

  const closeModal = () => {
    setIsOpen(false);

  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [creditScore ,setCreditScore] = useState();

 

  const closeModal1 = () => {
    setIsModalOpen(false);
    setShowEmployementDetails( true );
    onContinue();
  };
  const closeModal2 = () => {
    setIsModalOpen1(false);
  };
  return (
      <>
        {!showEmployementDetails ? (
            <>
              <div className="row">
                {isModalOpen && (
                    <div className="modal1  " onClick={closeModal1}>

                      <div className="modal1-content" onClick={e => e.stopPropagation()}>

                        <h5 className="text-center">Congratulations</h5>
                        <CreditScore score={creditScore} />
                        <div className="text-center">
                          <ArthaContinueButton className="btn col-2 mt-1" onClick={closeModal1}>ok</ArthaContinueButton>
                        </div>
                      </div>
                    </div>
                )}
                {isModalOpen1 && (
                    <div className="modal1" onClick={closeModal2}>

                      <div className="modal1-content" onClick={e => e.stopPropagation()}>
                        <div className="text-end">
                        </div>
                        <FetchingCreditscorePopup/>
                      </div>
                    </div>
                )}



                <div className="col-12">
                  <h6>Photo Verification</h6>
                  <p className="small-heading kyc-form-text-size">
                    Please capture your selfie for us to proceed ahead. You may see a popup asking for Camera Control, please select 'allow' and proceed further. Please ensure you are sitting in an illuminated space with a clear background so that captured image is clear
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 border border-secondary ">
                  {Status || isPhotoUploaded ? (
                      <div className="row h-100">
                        <div className="col-6 mx-auto" style={{ position: "relative" }}>

                          <img
                              src={uploadedImage}
                              alt="Uploaded Adhar Card"
                              style={{ width: "100%" }}

                          />

                          <div className="col-1" style={{ position: "absolute", right: "45px", top: "5px" }}>

                            {Status ? null : (
                                <button
                                    className="delete-button"
                                    onClick={handleDeleteImage}
                                    title="Delete"
                                >
                                  <MdDelete />
                                </button>
                            )}
                          </div>
                        </div>
                      </div>

                  ) : (
                      <div className="row h-100">
                        <div className="col-6 mx-auto cameraWindow" >
                          <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                  )}
                </div>
                {Successmessage && (
                    <div
                        className="col-12 text-center mt-3"
                        style={{ color: "green", fontWeight: "bold" }}
                    >
                      <span>{Successmessage}</span>
                    </div>
                )}
              </div>

              <div className="row mt-4">
                <div className="col-6 d-flex justify-content-end">
                  {captureMode ? (
                      <ArthaClearButton type="button" onClick={handleRetakePhoto}>
                        Retake
                      </ArthaClearButton>
                  ) : (
                      <ArthaClearButton type="button" onClick={handleBack}>
                        Back
                      </ArthaClearButton>
                  )}
                </div>

                <div className="col-6 d-flex justify-content-start">
                  <>
                    {Status || formData || isPhotoUploaded ? (
                        <ArthaContinueButton type="button" onClick={handleApiRequest}
                        style={{ opacity: isButtonClicked ? 0.39 : 1,
                          pointerEvents: isButtonClicked ? "none" : "auto"}}>
                       {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                        </ArthaContinueButton>
                    ) : (
                        <ArthaContinueButton type="button" onClick={handleTakePhoto}>
                          {captureMode ? "Continue" : "Take Photo"}
                        </ArthaContinueButton>
                    )}
                  </>
                </div>
              </div>
              {/*<ProcessFailed isOpen={isOpen} closeModal={closeModal} />*/}
            </>
        ) : (
            <EmployementDetails
                onContinue={onContinue}
                //  data={currentFormData}
                onBack={onBack}
            />
        )}
      </>
  );
}

export default PhotoVerification;
