import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";

const ArthaTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "14px", // Increased font size for input text
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "20px", // Increased font size for placeholder text
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px", // Increased font size for input labels
  },
}));

export default ArthaTextField;
