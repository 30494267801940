import React, { useEffect, useRef, useState } from "react";
import "./personalLoan.css";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { Button, Dialog, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadDocument from "./UploadDocument";
import { FaExclamationCircle } from "react-icons/fa";
import UploadSingleDocument from "./uploadSIngleDocument";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import AddBank from "./addBank";
import PreviousEmployer from "./previousEmployer";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import PreviousSalariedEmpDetails from "./previousSalariedEmpDetails";
import dayjs from 'dayjs';
import ITrDetails from './iTrDetails'
import { routeBankOrAccountStatementAPI,ragistrationDocumentAPI, fetchProfessionalDetailsAPI, fetchDataAPI, storeProfessionalDetailsAPI, verifyGSTN, verifyUdyamNumber, emailVerificationAPI, emailOTPValidationAPI, initiateKycAutoAPI } from "../../actioncreators/actioncreators";
import { toast } from 'react-toastify';
import { validateObjectKeys } from "../../../utility/validations";
import UploadSupportiveDocs from "./uploadSupportiveDocs";
import { EnergySavingsLeaf } from "@mui/icons-material";
import LoadingSpinner from "../../fields/spinner";

const ProfessionalDetails = ({ onSubmit, onCancel, onContinue, setActiveStep, setItrFlagDetails, uidprops, onBack }) => {
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();
    const [response, setResponse] = React.useState();
    const [professionType, setProfessionType] = React.useState('');

    const fetchProfDetails = async () => {
        const fetchResponse = await fetchProfessionalDetailsAPI();
        setResponse(fetchResponse.data);

    }

    //address dialog ended
    const handleBack = () => {
        onBack();
    };
    useEffect(() => {
        fetchProfDetails();
    }, [])

    const [businessId, setBusinessId] = React.useState();

    useEffect(() => {
        if (response) {
            // Check if employmentSubType is "Doctor" and convert to uppercase if true
            if (response.employmentSubType === "Doctor") {
                const uppercase = response.employmentSubType.toUpperCase();
                setProfessionType(uppercase);
            } else {
                setProfessionType(response.employmentSubType);
            }

            let tempArray = response.firmDetails;
            for (let index = 0; index < tempArray.length; index++) {
                const element = tempArray[index];
                setBusinessId(element.businessId);
            }
        }
    }, [response]);

    const [isFormFilled, setIsFormFilled] = useState(false);
    const [qualificationDropdown, setQualificationDropdown] = React.useState([]);
    const [totalExpDropdown, setTotalExpDropdown] = React.useState([]);
    const [medicalCouncilDropdown, setMedicalCouncilDropdown] = React.useState([]);
    const [firmVintageOwnerDropdown, setFirmVintageOwnerDropdown] = React.useState([]);
    const [natureOfFirm, setNatureOfFirm] = React.useState(["B2B", "B2C", "Not Applicable"]);
    const [firmVintageDropdown, setFirmVintageDropdown] = React.useState([]);
    const [currentProfessionYearsDropdown, setCurrentProfessionYearsDropdown] = React.useState([])
    const [formData, setFormData] = React.useState({
        'medicalCouncil': '',
        'qualification': '',
        'totalExperience': '',
        'qualificationYear': null,
        'registrationNumber': '',
        'registrationYear': null,
        'officialEmail': '',
        'registrationCertificateDoc': ''
    });

    const [currentEmployeer, setCurrentEmployeer] = React.useState({
        'gstNumber': '',
        'udyamAadhaar': '',
        'shopActNumber': '',
        'firmName': '',
        'firmVintage': '',
        'numberOfEmployeesInFirm': '',
        'yearsInCurrentProfession': '',
        'workfrom': null,
        'incorporationMonth': null,
        'firmPremisesOwner': '',
        'natureOfBusiness': '',
        'officialEmail': '',
        'firmPremisesOwner': ''
    })

    const [previousEmployeer, setPreviousEmployeer] = React.useState({
        'gstNumber': '',
        'udyamAadhaar': '',
        'shopActNumber': '',
        'firmName': '',
        'firmVintage': '',
        'numberOfEmployeesInFirm': '',
        'yearsInCurrentProfession': '',
        'workfrom': null,
        'worktill': null,
        'incorporationMonth': null,
        'firmPremisesOwner': '',
        'officialEmail': '',
        'firmClosureMonth': ''
    });

    const [prevSalariedEmployer, setPrevSalariedEmployer] = React.useState({
        'companyName': '',
        'employerType': '',
        'employerWebsite': '',
        'designation': '',
        'workfrom': null,
        'worktill': null,
        'bankName': '',
        'accNo': ''
    })

    const [shopActNoTouched, setShopActNoTouched] = useState(false);
    useEffect(() => {
        console.log(response);
        if (response) {
            setProfessionType(response?.employmentSubType);
            let data = response?.qualificationDetails;
            if (data) {
                setFormData({
                    'medicalCouncil': data.medicalCouncil ? data.medicalCouncil : '',
                    'memberType': data.memberType ? data.memberType : '',
                    'qualification': data.qualification ? data.qualification : '',
                    'totalExperience': data.totalExperience ? data.totalExperience : '',
                    'qualificationYear': data.qualificationYear ? dayjs().year(data.qualificationYear) : null,
                    'registrationNumber': data.registrationNumber ? data.registrationNumber : '',
                    'registrationYear': data.registrationYear ? dayjs().year(data.registrationYear) : null,
                    'registrationCertificateDoc': data.registrationCertificateDoc ? data.registrationCertificateDoc : ''
                })
                setRegistrationDocument(data.registrationCertificateDoc ? data.registrationCertificateDoc : '')
            }

            let firmDetails = response.firmDetails;
            console.log(firmDetails);
            for (let index = 0; index < firmDetails.length; index++) {
                const element = firmDetails[index];
                if (element.businessEmployerType == 'Current Employer') {
                    let currAddress = element.address;

                    if (currAddress) {
                        if (currAddress.businessAddrId) {
                            setAddressAdded(true);
                            setCurrentAddress({
                                'line1': currAddress.line1,
                                'line2': currAddress.line2,
                                'pincode': currAddress.pincode,
                                'city': currAddress.city,
                                'state': currAddress.state
                            })
                        }
                    }

                    let bankDetails = element.bankAccDetails;
                    if (bankDetails) {
                        if (bankDetails.length > 0) {
                            setBankDetails(bankDetails)
                        }
                    }

                    let currentEmployeerRes = element;
                    if (currentEmployeerRes.officialEmail != "") {
                        setEmailAdded(true)
                    }
                    setCurrentEmployeer({
                        'gstNumber': currentEmployeerRes.gstNumber,
                        'udyamAadhaar': currentEmployeerRes.udyamAadhaar,
                        'shopActNumber': currentEmployeerRes.shopActNumber,
                        'firmName': currentEmployeerRes.firmName,
                        'firmVintage': currentEmployeerRes.firmVintage,
                        'numberOfEmployeesInFirm': currentEmployeerRes.numberOfEmployeesInFirm,
                        'yearsInCurrentProfession': null,
                        'workfrom': null,
                        'incorporationMonth': null,
                        'firmPremisesOwner': currentEmployeerRes,
                        'natureOfBusiness': currentEmployeerRes.natureOfBusiness,
                        'officialEmail': currentEmployeerRes.officialEmail,
                        "firmPremisesOwner": currentEmployeerRes.firmPremisesOwner
                    });
                }
                if (element.businessEmployerType == 'Previous Employer 1') {
                    setPreviousEmpReq(true);
                    let currAddress = element.address;

                    if (currAddress) {
                        setPrevEmpType(currAddress.employmentType);
                        if (currAddress.businessAddrId) {
                            setAddressAdded(true);
                            setPreviousAddress({
                                'line1': currAddress.line1,
                                'line2': currAddress.line2,
                                'pincode': currAddress.pincode,
                                'city': currAddress.city,
                                'state': currAddress.state
                            })
                        }
                    }

                    let bankDetails = element.bankAccDetails;
                    if (bankDetails) {
                        if (bankDetails.length > 0) {
                            setPrevBankDetails(bankDetails)
                        }
                    }
                    let currentEmployeerRes = element;
                    setPreviousEmployeer({
                        'gstNumber': currentEmployeerRes.gstNumber,
                        'udyamAadhaar': currentEmployeerRes.udyamAadhaar,
                        'shopActNumber': currentEmployeerRes.shopActNumber,
                        'firmName': currentEmployeerRes.firmName,
                        'firmVintage': currentEmployeerRes.firmVintage,
                        'numberOfEmployeesInFirm': currentEmployeerRes.numberOfEmployeesInFirm,
                        'yearsInCurrentProfession': null,
                        'workfrom': null,
                        'incorporationMonth': null,
                        'firmPremisesOwner': currentEmployeerRes,
                        'natureOfBusiness': currentEmployeerRes.natureOfBusiness,
                        'firmClosureMonth': currentEmployeerRes.firmClosureMonth
                    });
                }

            }


        }
    }, [response]);

    const handleChange = (e) => {
        setGstn(e.target.value)
    }
    const [selectedYear, setSelectedYear] = useState(null);
    const fileInputRefID = useRef(null);
    const [isRegistrationPopupOpen, setIsRegistrationPopupOpen] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [registrationDocument, setRegistrationDocument] = React.useState({});
    const [registrationDocumentTypes, setRegistrationDocumentTypes] = React.useState(['Certificate of Registration'])
    const [gstn, setGstn] = React.useState('')
    const handleYearChange = (date) => {
        validateCurrentEmployeer();
        if (date) {
            setSelectedYear(date);
            setFormData({
                ...formData,
                qualificationYear: date,
            })
        }
    };

    const handleRegistrationYearChange = (date) => {
        validateCurrentEmployeer();
        if (date) {
            setSelectedYear(date);
            setFormData({
                ...formData,
                registrationYear: date,
            })
        }
    };

    const handleFileChangeID = (event) => {
        validateCurrentEmployeer();
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData({
                    ...formData,
                    registrationCertificateDoc: {
                        ...formData.registrationCertificateDoc,
                        documentData: e.target.result.split(',')[1], // Assuming base64 data
                        documentName: file.name,
                        documentType: file.type
                    }
                });
            };
            reader.readAsDataURL(file);
        }

    };


    const handleUploadDocument = () => {
        validateCurrentEmployeer();
        setPopupOpen(true);
    };
    const handleRegistrationPopup = () => {
        setIsRegistrationPopupOpen(true);
    }

    const onSave = (obj) => {
        setRegistrationDocument(obj);
        setPopupOpen(false);
    }

console.log ("registrationDocument",registrationDocument);
    const [udyamAdharNumberVerified, setUdyamAdharNumberVerified] = React.useState(false);
    const [shopActNoVerified, setShopActNoVerified] = React.useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isGstVerifying, setGstIsVerifying] = useState(false);
    const [gstnVerified, setGstnVerified] = React.useState(false);
    const handleVerification = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log( name, value );
        if ( name == 'gstNumber' ) {
            if ( value === '' ) {
                setGstnVerified( false );
            } else {
                let response = verifyGSTN({
                    "gstin": value,
                    "businessId": businessId
                } )
                if ( response.businessStatusCode === 2 ) {
                    setGstnVerified( true );
                } else {
                    setGstnVerified(false);
                }

                if (name === 'gstNumber') {
                    if (!gstnVerified && !isGstVerifying) { // Check if already verifying
                        if (value === '') {
                            setGstnVerified(false);
                        } else {
                            setIsVerifying(true);
                            try {
                                let response = await verifyGSTN({ gstNumber: value, businessId });
                                if (response.businessStatusCode == '2') {
                                    setGstnVerified(true);
                                } else {
                                    setGstnVerified(false);
                                }
                            } catch (error) {
                                console.error('Failed to verify GSTN', error);
                                setGstnVerified(false);
                            }
                            finally {
                                setGstIsVerifying(false);
                            }
                        }
                    }
                } if (name === 'udyamAadhaar') {
                    if (!udyamAdharNumberVerified && !isVerifying) { // Check if already verifying
                        if (value === '') {
                            setUdyamAdharNumberVerified(false);
                        } else {
                            setIsVerifying(true); // Set loading state to true
                            try {
                                let response = await verifyUdyamNumber({ udyamNumber: value, businessId });
                                if (response.businessStatusCode === 2 ) {
                                    setUdyamAdharNumberVerified(true);
                                } else {
                                    setUdyamAdharNumberVerified(false);
                                }
                            } catch (error) {
                                console.error('Failed to verify Udyam Number', error);
                                setUdyamAdharNumberVerified(false);
                            } finally {
                                setIsVerifying(false); // Reset loading state
                            }
                        }
                    }
                } 
            }
        } 
    }

    //email dialog code started
    const [isOpenEmailDialog, setOpenEmailDialog] = useState(false);
    const [isEmailAdded, setEmailAdded] = React.useState(false);
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [isInvalidOtp, setIsInvalidOtp] = useState(true);
    const inputRefs = useRef([]);
    const [showItr, setShowItr] =
        useState(false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const handleInputKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            // Move focus to the previous input on Backspace
            inputRefs.current[index - 1].focus();
        }
    };
    const closeEmailDialog = () => {
        setOpenEmailDialog(false);
        if (!isEmailAdded) {
            setFormData({
                ...formData,
                officialEmail: '',
            })
        }
    }

    const handleVerifyClick = async (e) => {
        setIsButtonClicked(true);
        setIsLoading2(true);
        e.preventDefault();

        setOpenOTPDialog(false);
        setOpenEmailDialog(false);
        const requestBody = {
            "otp": otp.join(""), 
        };

        if (otp.join("") === "") { 
            setIsInvalidOtp(true);
            inputRefs.current[0].current.focus();
            return;
        }

        try {
            const response = await emailOTPValidationAPI(requestBody);

            if (response.data.httpResponseCode === 200) {
                setOpenOTPDialog(false);
                setEmailAdded(true);
            } else {
                console.log("Unexpected response status:", response.status);
                setEmailAdded(false);
            }
        } catch (error) {
            if (error.response.status === 401) {
                handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error during OTP verification:", error);
        }
        finally {
            // Reset loading state after the operation is complete
            setIsLoading2(false);
            setIsButtonClicked(false);
        }
        //TODO1 remove below two lines
        //setOpenOTPDialog(false);
        //setEmailAdded(true);
    };
   
    const [isOpenOTPDialog, setOpenOTPDialog] = useState(false);
    const openOTPDialog = async () => {
        setIsButtonClicked(true);
        setIsLoading1(true);
        
        setOtp(["", "", "", "", "", ""]);
        //TODO1 remove below line
        //setOpenOTPDialog(true);
        const payload = { id:formData.officialEmail }
        try {

            const response = await emailVerificationAPI(payload);

            if (response.data.httpResponseCode === 200) {
                setOpenOTPDialog(true);
            } else {
                console.log("Unexpected response status:", response.status);
            }
        } catch (error) {
            if (error.response.status === 401) {
                handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                //toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error during OTP verification:", error);
        }
        finally {
            // Reset loading state after the operation is complete
            setIsLoading1(false);
            setIsButtonClicked(false);
        }
    }
    
    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < otp.length - 1 && value !== '') {
            // Move focus to the next input if not on the last input and a digit is entered
            inputRefs.current[index + 1].focus();
        }
        const isAllFieldsFilled = newOtp.every((otpValue) => otpValue !== "");
        // Enable or disable the "Continue" button based on the OTP fields status
        setIsInvalidOtp(!isAllFieldsFilled);
    };

    const isEmailValid = (email) => {
        return emailRegex.test(email);
    };

    //email dialog code ended

    //address dialog code started


    const [openAddressDialog, setOpenAddressDialog] = useState(false);
    const [isAddressAdded, setAddressAdded] = React.useState(false);
    const [pincodeError, setPincodeError] = React.useState('');
    const [currentAddress, setCurrentAddress] = React.useState({
        'line1': '',
        'line2': '',
        'pincode': '',
        'city': '',
        'state': ''
    });
    const [previousAddress, setPreviousAddress] = React.useState({
        'line1': '',
        'line2': '',
        'pincode': '',
        'city': '',
        'state': ''
    });
    const [salariedPreviousAddress, setSalariedPreviousAddress] = React.useState({
        'line1': '',
        'line2': '',
        'pincode': '',
        'city': '',
        'state': ''
    });
    const openAddrDialog = () => {
        validateCurrentEmployeer();
        setOpenAddressDialog(true);
        setPincodeError('')
    }

    const isPincodeValid = (pincode) => /^\d{6}$/.test(pincode);
    const isCityValid = (city) => /^[a-zA-Z\s]+$/.test(city);
    const isStateValid = (state) => /^[a-zA-Z\s]+$/.test(state);

    const handleAddressChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name == 'pincode' && value == '000000') {
            setPincodeError('Please enter a valid 6-digit pincode')
        }
        setCurrentAddress(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
        checkFormFilled();
    }

    const checkFormFilled = () => {
        if (!currentAddress) return;

        const { line1, line2, pincode, city, state } = currentAddress;

        const isLine1Filled = line1 && line1.trim() !== '' && /^[a-zA-Z0-9\s]+$/.test(line1);
        const isLine2Filled = line2 && line2.trim() !== '' && /^[a-zA-Z0-9\s]+$/.test(line2);
        const isPincodeFilled = pincode && pincode.trim() !== '' && /^\d{6}$/.test(pincode); // Assuming pincode is 6 digits
        const isCityFilled = city && city.trim() !== '' && /^[a-zA-Z\s]+$/.test(city);
        const isStateFilled = state && state.trim() !== '' && /^[a-zA-Z\s]+$/.test(state);

        let isPincodeValidated = isPincodeFilled && isPincodeValid(pincode);
        if (pincode == '000000') {
            isPincodeValidated = false;
        }
        if (isPincodeValidated) {
            setPincodeError('');
        }
        const isCityValidated = isCityFilled && isCityValid(city);
        const isStateValidated = isStateFilled && isStateValid(state);

        if (

            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }

    };


    const closeAddrDialog = () => {
        if (isAddressAdded) {
            setOpenAddressDialog(false);
        } else {
            setOpenAddressDialog(false);
            setCurrentAddress({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            })
        }
    }



    //address dialog ended
    //add bank dialog
    const [openBankDialog, setOpenBankDialog] = React.useState(false);
    const [bankDetails, setBankDetails] = React.useState()
    const cancelBankDialog = () => {
        setOpenBankDialog(false);
    }

    const onBankDataSave = (data) => {
        validateCurrentEmployeer();
        setBankDetails(data);
        setOpenBankDialog(false);
    }

    const convertMonthsToDays = (months) => {
        return months * 30;
    };

    const [totalDays, setTotalDays] = React.useState(response ? convertMonthsToDays(parseInt(response.acceptedEmployerTimePeriodInMonths, 10)) : 0);
    const [isPreviousEmpReq, setPreviousEmpReq] = React.useState(false);
    const [prevEmpType, setPrevEmpType] = React.useState('');
    const [prevBankDetails, setPrevBankDetails] = React.useState();
    const handleWorkingSince = (e) => {
        setCurrentEmployeer({
            ...currentEmployeer,
            workfrom: e,
        });
        let formattedDate = new Date(e).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        let dateStr = formattedDate;
        let dateParts = dateStr.split("/");
        let date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
        let today = new Date();
        let differenceInTime = date.getTime() - today.getTime();
        let differenceInDays = Math.abs(Math.ceil(differenceInTime / (1000 * 3600 * 24)));
        if (differenceInDays < totalDays) {
            setPreviousEmpReq(true);
        } else {
            setPreviousEmpReq(false);
            setPrevEmpType();
            setPreviousEmployeer({
                'gstNumber': '',
                'udyamAadhaar': '',
                'shopActNumber': '',
                'firmName': '',
                'firmVintage': '',
                'numberOfEmployeesInFirm': '',
                'yearsInCurrentProfession': '',
                'workfrom': null,
                'worktill': null,
                'incorporationMonth': null,
                'firmPremisesOwner': '',
                'officialEmail': ''
            });
            setPrevBankDetails();
            setPreviousAddress({
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            });
        }

    }

    const onPrevBankDetailsSave = (data) => {
        setPrevBankDetails(data)
    }



    // const handleContinue = async () => {
    //     console.log( registrationDocument );
    // }

    if (formData) {
        //  setIsFormValid(true)
    }
    const [isAllDataFieldsFilled, setIsAllDataFieldsFilled] = useState(false);
    const buttonStyle = {
        color: "white",
        border: "none",
        backgroundColor:
            isAllDataFieldsFilled
                ? "rgba(255, 115, 0, 1)"
                : "rgb(255 98 0 / 39%)",
        opacity: isButtonClicked ? 0.39 : 1,
        pointerEvents: isButtonClicked ? "none" : "auto",
    };

    const clearButtonStyle = {
        color: "black",
        backgroundColor: isFormValid
            ? "rgba(255, 255, 255, 1)"
            : "rgb(255 255 255 / 39%)",
    };


    const areFieldsFilled = (requiredFields) => {
        for (let field of requiredFields) {
            if (field === 'registrationDocument') {
                if (registrationDocument === null) {
                    return false;
                }
            } else if (!formData[field]) {
                return false;
            }
        }
        return true;
    };


    const getRequiredFieldsForProfession = (profession) => {
        switch (profession) {
            case 'Doctor':
                return ['qualification', 'totalExperience', 'qualificationYear', 'registrationNumber', 'medicalCouncil', 'registrationDocument'];
            case 'CA':
                return ['qualification', 'totalExperience', 'registrationNumber', 'registrationYear', 'registrationDocument'];
            case 'CS':
                return ['qualification', 'totalExperience', 'registrationNumber', 'registrationYear', 'registrationDocument'];
            case 'CMA/ICWA (Certified Management Accountant)':
                return ['qualification', 'totalExperience', 'registrationNumber'];
            default:
                return [];
        }
    };
    // Function to validate the current employeer form
    const validateCurrentEmployeer = () => {
        const isAddressValid = isAddressAdded;
        const isBankDetailsValid = bankDetails !== null && bankDetails !== undefined;
        const isSupportingDocsValid = selectedDocs && selectedDocs.document && selectedDocs.document.length > 0;

        const requiredFields = getRequiredFieldsForProfession(professionType);  // Get required fields based on profession type
        const areProfessionalFieldsFilled = areFieldsFilled(requiredFields);  // Check if all required fields are filled

        const isOfficialEmailValid = validateObjectKeys(currentEmployeer, ['officialEmail']);

        if (isOfficialEmailValid && isAddressValid && isBankDetailsValid && isSupportingDocsValid && areProfessionalFieldsFilled) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };




    const handleContinue = async () => {
        setIsButtonClicked(true);
        setIsLoading3(true);
        let registrationPayload;
        if (professionType == 'Doctor') {
            registrationPayload = {
                'registrationNo': formData.registrationNumber,
                'state': formData.medicalCouncil,
                'qualificationYear': formData.qualificationYear,
                'professionType': professionType
            }
        } else if (professionType == 'CA' || professionType == 'CMA') {
            registrationPayload = {
                'registrationNo': formData.registrationNumber,
                'professionType': professionType
            }
        } else if (professionType == 'CS') {
            registrationPayload = {
                'registrationNo': formData.registrationNumber,
                'professionType': professionType,
                'memberType': ''
            }
        } else {
            console.log('else block');
        }

        try {
            const response1 = await ragistrationDocumentAPI(registrationPayload);
          
        } catch (error) {
            if (error.response.status === 401) {
                handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error storing employee history:", error);
        }
        console.log(registrationPayload);

        let currentEmployeerPayload = {
            address: currentAddress,
            bankAccDetails: bankDetails,
            documents: [
                {
                    documentData: "",
                    documentName: "",
                    documentType: "",
                    password: ""
                },
                {
                    documentData: "",
                    documentName: "",
                    documentType: "",
                    password: ""
                }
            ],
            businessEmployerType: "Current Employer",
            businessId: '',
            employerWebsite: '',
            documents: selectedDocs.document,
            empSource: 'USER',
            firmName: currentEmployeer.firmName || '',
            firmVintage: currentEmployeer.firmVintage || '',
            businessId: businessId,
            gstNumber: currentEmployeer.gstNumber || '',
            numberOfEmployeesInFirm: currentEmployeer.numberOfEmployeesInFirm || '',
            shopActNumber: currentEmployeer.shopActNumber || '',
            officialEmail: formData.officialEmail,
            udyamAadhaar: currentEmployeer.udyamAadhaar || '',
            workfrom: currentEmployeer.workfrom ? currentEmployeer.workfrom.format('YYYY-MM-DD') : '',
            yearsInCurrentProfession: currentEmployeer.yearsInCurrentProfession || '',
            natureOfBusiness: currentEmployeer.natureOfBusiness || '',
            firmClosureMonth: '',
            firmPremisesVintage: '',
            firmPremisesOwner: currentEmployeer.firmPremisesOwner,
            incorporationMonth: currentEmployeer.incorporationMonth,
            worktill: currentDate.format('YYYY-MM-DD'),
        };

        let prevSalariedEmployeer = {
            address: salariedPreviousAddress,
            bankAccDetails: '',
            businessEmployerType: 'Previous Employer 1',
            designation: prevSalariedEmployer.designation || '',
            employerId: '',
            documents: selectedPrevDocs.document,
            employmentType: 'salaried',
            empSource: 'UAN',
            firmName: prevSalariedEmployer.companyName || '',
            workfrom: prevSalariedEmployer.workfrom ? prevSalariedEmployer.workfrom.format('YYYY-MM-DD') : '',
            worktill: prevSalariedEmployer.worktill ? prevSalariedEmployer.worktill.format('YYYY-MM-DD') : ''
        }

        let firmDetailsPayload = [];
        if (isPreviousEmpReq) {
            if (prevEmpType == 'selfEmployed') {
                let prevSelfTemp = {
                    address: previousAddress,
                    bankAccDetails: prevBankDetails,
                    documents: [
                        {
                            documentData: "",
                            documentName: "",
                            documentType: "",
                            password: ""
                        }
                    ],
                    businessEmployerType: "Previous Employer 1",
                    businessId: '',
                    documents: selectedPrevDocs.document,
                    employerWebsite: '',
                    empSource: 'USER',
                    firmName: previousEmployeer.firmName || '',
                    firmVintage: previousEmployeer.firmVintage || '',
                    gstNumber: previousEmployeer.gstNumber || '',
                    numberOfEmployeesInFirm: previousEmployeer.numberOfEmployeesInFirm || '',
                    shopActNumber: previousEmployeer.shopActNumber || '',
                    officialEmail: previousEmployeer.officialEmail,
                    udyamAadhaar: previousEmployeer.udyamAadhaar || '',
                    workfrom: previousEmployeer.workfrom ? previousEmployeer.workfrom.format('YYYY-MM-DD') : ''
                };
                firmDetailsPayload = [currentEmployeerPayload, prevSelfTemp];
            } else if (prevEmpType == 'Salaried') {
                firmDetailsPayload = [currentEmployeerPayload, prevSalariedEmployeer];
            } else {
                firmDetailsPayload = [currentEmployeerPayload];
            }
        } else {
            firmDetailsPayload = [currentEmployeerPayload];
        }
        let payload = {
            "acceptedEmployerTimePeriodInMonths": '',
            "qualificationDetails": {
                medicalCouncil: formData.medicalCouncil,
                qualification: formData.qualification,
                qualificationYear: formData.qualificationYear ? `${formData.qualificationYear.year()}` : '',
                registrationNumber: formData.registrationNumber,
                registrationYear: formData.registrationYear ? `${formData.registrationYear.year()}` : '',
                totalExperience: formData.totalExperience,
                memberType: prevEmpType == 'Salaried' ? "Salaried" : '',
                registrationCertificateDoc:registrationDocument,
            },
            "firmDetails": firmDetailsPayload
        }
        console.log(payload);
        try {
            const response = await storeProfessionalDetailsAPI(payload);

            if (response.status === 200 && response.data.businessStatusCode === 2) {
                const response2 = await routeBankOrAccountStatementAPI();
                console.log(response2.data.businessStatusCode);

                if (response2.data.businessStatusCode === 2 && response2.data.businessStatusSubCode === 2.2) {
                    onContinue();
                    setActiveStep(5);
                    // aaSuccess(true);
                } else if (response2.data.businessStatusCode === 2 && response2.data.businessStatusSubCode === 2.3) {
                    setShowItr(true);
                    // onContinue();
                    // setItrFlagDetails(true);

                } else {
                    onContinue();
                    // setShowAccountAggregator(true);
                    // aaSuccess(false);
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error storing employee history:", error);
        }
        finally {
            // Reset loading state after the operation is complete
            setIsLoading3(false);
            setIsButtonClicked(false);
        }
    }
    
    // Fetching dropdown
    useEffect(() => {
        //fetching Qualification dropdown
        if (response) {
            let apiPayload = {
                "type": "PROFESSION",
                "key": professionType,
                "source": "json_value"
            }
            fetchDropDownValues(apiPayload, 'degree');
            let apiPayload1 = {
                "type": "TOTAL_EXPERIENCE",
                "key": "TOTAL_EXPERIENCE",
                "source": "json_value"
            }
            fetchDropDownValues(apiPayload1, 'TOTAL_EXPERIENCE');

            let apiPayload2 = {
                "type": "STATE",
                "key": "MEDICAL_COUNCIL",
                "source": "json_value"
            }
            fetchDropDownValues(apiPayload2, 'MEDICAL_COUNCIL');
            let apiPayload3 = {
                "type": "FIRM_PREMISES_OWNER",
                "key": "FIRM_PREMISES_OWNER",
                "source": "json_value"
            }
            fetchDropDownValues(apiPayload3, 'FIRM_PREMISES_OWNER');

            let apiPayload4 = {
                "type": "FIRM_VINTAGE",
                "key": "FIRM_VINTAGE",
                "source": "json_value"
            }
            fetchDropDownValues(apiPayload4, 'FIRM_VINTAGE');

            let apiPayload5 = {
                "type": "NUMBER_OF_YEARS_IN_CURRENT_PROFESSION",
                "key": "NUMBER_OF_YEARS_IN_CURRENT_PROFESSION",
                "source": "json_value"
            }
            fetchDropDownValues(apiPayload5, 'NUMBER_OF_YEARS_IN_CURRENT_PROFESSION');
        }
    }, [response])

    const fetchDropDownValues = async (apiPayload, dropdownType) => {
        //  setQualificationDropdown(["BHMS", "MBBS"]);
        try {
            const response = await fetchDataAPI(apiPayload)

            if (dropdownType == 'degree') {
                setQualificationDropdown(response.data[0].List);
            } else if (dropdownType == 'TOTAL_EXPERIENCE') {
                setTotalExpDropdown(response.data[0].values);
            } else if (dropdownType == 'MEDICAL_COUNCIL') {
                setMedicalCouncilDropdown(response.data[0].values);
            }
            else if (dropdownType == 'FIRM_PREMISES_OWNER') {
                setFirmVintageOwnerDropdown(response.data[0].values);
            }
            else if (dropdownType == 'FIRM_VINTAGE') {
                setFirmVintageDropdown(response.data[0].values);
            }
            else if (dropdownType == 'NUMBER_OF_YEARS_IN_CURRENT_PROFESSION') {
                setCurrentProfessionYearsDropdown(response.data[0].values);
            } else {

            }


        } catch (error) {
            if (error.response && error.response.status === 401) {
                await handleSignOut();
                toast.error("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            } else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
            } else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
            }
            console.error("Error fetching address vintage:", error);
        }
    };

    const currentYear = dayjs().year();
    const currentMonth = dayjs().month();
    const disableFutureYears = (date) => {
        return date.year() > currentYear;
    };

    const disableFutureYearsAndMonth = (date) => {
        if (!date) return false; // Ensure date is valid
        const year = date.year();
        const month = date.month();
        return year > currentYear || (year === currentYear && month > currentMonth);
    };
    const currentDate = dayjs();



    const handleChange1 = (e) => {
        const { value } = e.target;
        if (value.length <= 15) {
            setCurrentEmployeer({
                ...currentEmployeer,
                gstNumber: value,
            });

            if (value.length === 15) {
                const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
                // setIsValidGST(gstRegex.test(value));
            } else {
                setGstnVerified(false); // Reset validation status for incomplete input
            }
        } else {
            setGstnVerified(false);
        }
    };


    const handleChange2 = (e) => {
        const { value } = e.target;
        const upperCaseValue = value.toUpperCase(); // Convert to uppercase
        // Allow only valid characters and restrict the length to 19
        if (upperCaseValue.length <= 19) {
            setCurrentEmployeer({
                ...currentEmployeer,
                udyamAadhaar: upperCaseValue,
            });
            if (upperCaseValue.length === 19) {
                const udyamAdharRegex = /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/;
                console.log("UDYAM ", udyamAdharRegex.test(upperCaseValue));
                // setUdyamAdharNumberVerified(udyamAdharRegex.test(upperCaseValue)); // Update the verification status
            } else {
                setUdyamAdharNumberVerified(false); // Reset validation status for incomplete input
            }
        }
    };

    const getErrorMessage = () => {
        const udyamAadhaar = currentEmployeer.udyamAadhaar;

        if (udyamAadhaar && !/^UDYAM/.test(udyamAadhaar)) {
            return "The first five characters must be 'UDYAM'.";
        }
        if (udyamAadhaar && !/^UDYAM-/.test(udyamAadhaar)) {
            return "The sixth character must be a hyphen ('-').";
        }
        if (udyamAadhaar && !/^UDYAM-[A-Z]{2}/.test(udyamAadhaar)) {
            return "The seventh and eighth characters must be letters.";
        }
        if (udyamAadhaar && !/^UDYAM-[A-Z]{2}-/.test(udyamAadhaar)) {
            return "The ninth character must be a hyphen ('-').";
        }
        if (udyamAadhaar && !/^UDYAM-[A-Z]{2}-\d{2}/.test(udyamAadhaar)) {
            return "The tenth and eleventh characters must be digits.";
        }
        if (udyamAadhaar && !/^UDYAM-[A-Z]{2}-\d{2}-/.test(udyamAadhaar)) {
            return "The twelfth character must be a hyphen ('-').";
        }
        if (udyamAadhaar && !/^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/.test(udyamAadhaar)) {
            return "The last seven characters must be digits.";
        }
        if (udyamAadhaar && udyamAadhaar.length !== 19) {
            return "Udyam Aadhaar must be exactly 19 characters long.";
        }

        return ""; // No error message if everything is correct
    };

    const handleShopActNumberChange = (e) => {
        const { name, value } = e.target;
        setCurrentEmployeer({
            ...currentEmployeer,
            [name]: value,
        });
        if (!shopActNoTouched) {
            setShopActNoTouched(true);
        }
    };

    //Page landing Timer
    const [timer, setTimer] = useState(90);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        // Cleanup interval when component unmounts
        return () => clearInterval(countdownInterval);
    }, []);

    const handleResend = async () => {
        setOtp(["", "", "", "", "", ""]);
        setIsInvalidOtp(false);
        setTimer(90); // Set the countdown to 30 seconds
        const data = {
            // uid: uidprops,
        };

        // Define headers
        const headers = {
            "x-apid": '',
            "x-loanappid": '',
        };

        try {
            // const response = await axios.post('/personal-loan/initiate-kyc-auto', data, { headers: headers });
            const response = await initiateKycAutoAPI(data, { headers: headers })
        } catch (error) {
            if (error.response.status === 401) {
                await handleSignOut();
                alert("Your Session has expired.You will be redirected to Login Page.")
                navigate('/')
            }
            console.error('Error fetching loan data:', error);
        }
    };
    const handleRegitrationPopupClose = () => {
        setIsRegistrationPopupOpen(false);
    };
    const handlePopupClose = (selectedFileNames) => {
        console.log(selectedFileNames);
        //setFileNames1( selectedFileNames );
        if (selectedFileNames.length > 0) {
            let doc = selectedFileNames;
            setSelectedDocs((prevSelectedEmployer) => {
                if (prevSelectedEmployer.document) {
                    return {
                        ...prevSelectedEmployer,
                        document: [...prevSelectedEmployer.document, ...doc],
                    };
                } else {
                    return { ...prevSelectedEmployer, document: doc };
                }
            });
        }

        setPopupOpen(false);

    };
    const [selectedDocs, setSelectedDocs] = React.useState({});
    const [selectedPrevDocs, setSelectedPrevDocs] = React.useState({});

    const removeExistingDoc = (documentType) => {
        let updatedDocuments = [...selectedDocs.document];

        // Filter out the document with the given documentType
        updatedDocuments = updatedDocuments.filter(doc => doc.documentType !== documentType);

        // Update the state with the updated array
        setSelectedDocs(prevState => ({
            ...prevState,
            document: updatedDocuments
        }));

    }
    useEffect(() => {
        validateCurrentEmployeer();
    }, [currentEmployeer, prevSalariedEmployer, previousEmployeer, selectedDocs, areFieldsFilled, formData])
    return (
        <>
            {!showItr ? (
                <>
                    <h6 className="text">Qualification & Experience</h6>
                    <p>Share your academic background and industry experience</p>

                    <form className="mt-4">
                        {professionType == 'Doctor' &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="qualification-label">
                                                Qualification / Degree
                                            </InputLabel>
                                            <Select
                                                labelId="qualification-label"
                                                id="qualification"
                                                name="qualification"
                                                value={formData.qualification}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        qualification: e.target.value,
                                                    })
                                                }
                                            >
                                                {qualificationDropdown && qualificationDropdown.map((item) => {
                                                    return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="totalExperience-label">
                                                Total Experience
                                            </InputLabel>
                                            <Select
                                                labelId="totalExperience-label"
                                                id="totalExperience"
                                                name="totalExperience"
                                                value={formData.totalExperience}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        totalExperience: e.target.value,
                                                    })
                                                }
                                            >
                                                {totalExpDropdown.map((item, index) => {
                                                    const [value, label] = item.split(' ');
                                                    return (
                                                        <MenuItem key={index} value={value}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Qualification Year - (YYYY)"
                                                views={['year']}
                                                value={formData.qualificationYear}
                                                onChange={handleYearChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                className="yearPicker"
                                                fullWidth
                                                shouldDisableDate={disableFutureYears}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ArthaTextField
                                            name="registrationNumber"
                                            value={formData.registrationNumber}
                                            label="Member / Registration Number"
                                            fullWidth
                                            inputProps={{ maxLength: 80 }}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    registrationNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="medicalCouncil-label">
                                                State Medical Council
                                            </InputLabel>
                                            <Select
                                                labelId="medicalCouncil-label"
                                                id="medicalCouncil"
                                                name="medicalCouncil"
                                                value={formData.medicalCouncil}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        medicalCouncil: e.target.value,
                                                    })
                                                }
                                            >
                                                {medicalCouncilDropdown && medicalCouncilDropdown.map((item) => {
                                                    return (
                                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Registration Year - (YYYY) (Optional)"
                                                views={['year']}
                                                value={formData.registrationYear}
                                                onChange={handleRegistrationYearChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                className="yearPicker"
                                                fullWidth
                                                shouldDisableDate={disableFutureYears}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="row mt-3 mt-md-4">
                                        <div className="col-8 col-md-4">
                                            <p className="msg">Certificate of Registration</p>

                                            {
                                                registrationDocument ? <>
                                                    <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                    <span
                                                        className="mt-2 upload-lable"
                                                        style={{ position: "relative", top: "-10px" }}
                                                    >
                                                        {" "}
                                                        {registrationDocument.documentName}
                                                    </span>
                                                </> : <>
                                                    <FaExclamationCircle className="badge-icon" />
                                                    <span
                                                        className="mt-2 upload-lable"
                                                        style={{ position: "relative", top: "-10px" }}
                                                    >
                                                        {" "}
                                                        Not Uploaded
                                                    </span>
                                                </>

                                            }
                                        </div>
                                        <div className="col-4 col-md-2">
                                            <input
                                                type="file"
                                                ref={fileInputRefID}
                                                style={{ display: "none" }}
                                                onChange={handleFileChangeID}
                                            />
                                            <Button
                                                variant="contained"
                                                className="upload_btn btn_white shadow-none mt-3"
                                                onClick={handleRegistrationPopup}
                                            >
                                                Upload
                                            </Button>
                                            <UploadSingleDocument
                                                open={isRegistrationPopupOpen}
                                                onRemove={removeExistingDoc}
                                                handleClose={handleRegitrationPopupClose}
                                                onSave={onSave}
                                                documentTypeDropdowns={registrationDocumentTypes}
                                                existingDocument={registrationDocument}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </>
                        }
                        {(professionType == 'Company Secretry' || professionType == 'CS') &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="qualification-label">
                                                Qualification / Degree
                                            </InputLabel>
                                            <Select
                                                labelId="qualification-label"
                                                id="qualification"
                                                name="qualification"
                                                value={formData.qualification}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        qualification: e.target.value,
                                                    })
                                                }
                                            >
                                                {qualificationDropdown && qualificationDropdown.map((item) => {
                                                    return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="totalExperience-label">
                                                Total Experience
                                            </InputLabel>
                                            <Select
                                                labelId="totalExperience-label"
                                                id="totalExperience"
                                                name="totalExperience"
                                                value={formData.totalExperience}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        totalExperience: e.target.value,
                                                    })
                                                }
                                            >
                                                {totalExpDropdown.map((item, index) => {
                                                    const [value, label] = item.split(' ');
                                                    return (
                                                        <MenuItem key={index} value={value}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ArthaTextField
                                            name="registrationNumber"
                                            value={formData.registrationNumber}
                                            label="Member / Registration Number"
                                            fullWidth
                                            inputProps={{ maxLength: 80 }}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    registrationNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="medicalCouncil-label">
                                                Member Type
                                            </InputLabel>
                                            <Select
                                                labelId="medicalCouncil-label"
                                                id="medicalCouncil"
                                                name="medicalCouncil"
                                                value={formData.medicalCouncil}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        medicalCouncil: e.target.value,
                                                    })
                                                }
                                            >
                                                {medicalCouncilDropdown && medicalCouncilDropdown.map((item) => {
                                                    return (
                                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Registration Year - (YYYY) (Optional)"
                                                views={['year']}
                                                value={formData.registrationYear}
                                                onChange={handleRegistrationYearChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                className="yearPicker"
                                                fullWidth
                                                shouldDisableDate={disableFutureYears}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="row">
                                            <div className="col-7 col-md-7">
                                                <p className="msg">Certificate of Registration</p>

                                                {
                                                    registrationDocument && registrationDocument.documentType ? <>
                                                        <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                        <span
                                                            className="mt-2 upload-lable"
                                                            style={{ position: "relative", top: "-10px" }}
                                                        >
                                                            {" "}
                                                            {registrationDocument.documentType}
                                                        </span>
                                                    </> : <>
                                                        <FaExclamationCircle className="badge-icon" />
                                                        <span
                                                            className="mt-2 upload-lable"
                                                            style={{ position: "relative", top: "-10px" }}
                                                        >
                                                            {" "}
                                                            Not Uploaded
                                                        </span>
                                                    </>

                                                }
                                            </div>
                                            <div className="col-4 col-md-2">
                                                <input
                                                    type="file"
                                                    ref={fileInputRefID}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChangeID}
                                                />
                                                <Button
                                                    variant="contained"
                                                    className="upload_btn btn_white shadow-none mt-3"
                                                    onClick={handleRegistrationPopup}
                                                >
                                                    Upload
                                                </Button>
                                                <UploadSingleDocument
                                                    open={isRegistrationPopupOpen}
                                                    onRemove={removeExistingDoc}
                                                    handleClose={handleRegitrationPopupClose}
                                                    onSave={onSave}
                                                    documentTypeDropdowns={registrationDocumentTypes}
                                                    existingDocument={registrationDocument}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        {professionType == 'CA' &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="qualification-label">
                                                Qualification / Degree
                                            </InputLabel>
                                            <Select
                                                labelId="qualification-label"
                                                id="qualification"
                                                name="qualification"
                                                value={formData.qualification}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        qualification: e.target.value,
                                                    })
                                                }
                                            >
                                                {qualificationDropdown && qualificationDropdown.map((item) => {
                                                    return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="totalExperience-label">
                                                Total Experience
                                            </InputLabel>
                                            <Select
                                                labelId="totalExperience-label"
                                                id="totalExperience"
                                                name="totalExperience"
                                                value={formData.totalExperience}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        totalExperience: e.target.value,
                                                    })
                                                }
                                            >
                                                {totalExpDropdown.map((item, index) => {
                                                    const [value, label] = item.split(' ');
                                                    return (
                                                        <MenuItem key={index} value={value}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ArthaTextField
                                            name="registrationNumber"
                                            value={formData.registrationNumber}
                                            label="Member / Registration Number"
                                            fullWidth
                                            inputProps={{ maxLength: 80 }}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    registrationNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    {/* Chandu */}


                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Registration Year - (YYYY) (Optional)"
                                                views={['year']}
                                                value={formData.registrationYear}
                                                onChange={handleRegistrationYearChange}
                                                renderInput={(params) => <TextField {...params} />}
                                                className="yearPicker"
                                                fullWidth
                                                shouldDisableDate={disableFutureYears}
                                            />
                                        </LocalizationProvider>
                                    </div>


                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="row">
                                            <div className="col-7 col-md-7">
                                                <p className="msg">Certificate of Registration</p>

                                                {
                                                    registrationDocument && registrationDocument.documentType ? <>
                                                        <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                        <span
                                                            className="mt-2 upload-lable"
                                                            style={{ position: "relative", top: "-10px" }}
                                                        >
                                                            {" "}
                                                            {registrationDocument.documentName}
                                                        </span>
                                                    </> : <>
                                                        <FaExclamationCircle className="badge-icon" />
                                                        <span
                                                            className="mt-2 upload-lable"
                                                            style={{ position: "relative", top: "-10px" }}
                                                        >
                                                            {" "}
                                                            Not Uploaded
                                                        </span>
                                                    </>

                                                }
                                            </div>
                                            <div className="col-4 col-md-2">
                                                <input
                                                    type="file"
                                                    ref={fileInputRefID}
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChangeID}
                                                />
                                                <Button
                                                    variant="contained"
                                                    className="upload_btn btn_white shadow-none mt-3"
                                                    onClick={handleRegistrationPopup}
                                                >
                                                    Upload
                                                </Button>
                                                <UploadSingleDocument
                                                    open={isRegistrationPopupOpen}
                                                    onRemove={removeExistingDoc}
                                                    handleClose={handleRegitrationPopupClose}
                                                    onSave={onSave}
                                                    documentTypeDropdowns={registrationDocumentTypes}
                                                    existingDocument={registrationDocument}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {(professionType == 'CMA/ICWA (Certified Management Accountant)') &&
                            <>
                                <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="qualification-label">
                                                Qualification / Degree
                                            </InputLabel>
                                            <Select
                                                labelId="qualification-label"
                                                id="qualification"
                                                name="qualification"
                                                value={formData.qualification}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        qualification: e.target.value,
                                                    })
                                                }
                                            >
                                                {qualificationDropdown && qualificationDropdown.map((item) => {
                                                    return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id="totalExperience-label">
                                                Total Experience
                                            </InputLabel>
                                            <Select
                                                labelId="totalExperience-label"
                                                id="totalExperience"
                                                name="totalExperience"
                                                value={formData.totalExperience}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        totalExperience: e.target.value,
                                                    })
                                                }
                                            >
                                                {totalExpDropdown.map((item, index) => {
                                                    const [value, label] = item.split(' ');
                                                    return (
                                                        <MenuItem key={index} value={value}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                </div>

                                <div className="row mt-3 mt-md-4">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <ArthaTextField
                                            name="registrationNumber"
                                            value={formData.registrationNumber}
                                            label="Member / Registration Number"
                                            fullWidth
                                            inputProps={{ maxLength: 80 }}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    registrationNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                            </>
                        }



                        <div className="row mt-3 mt-md-4">
                            <div className="col-8 col-md-12">
                                <p>Please provide professional details for the past 2 years</p>
                            </div>
                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-8 col-md-12">
                                <h6 className="text">Professional Details</h6>
                                <p>Enter Details of current profession</p>
                            </div>
                        </div>
                        <div className="row mt-3 mt-md-4">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="gstNumber"
                                    value={currentEmployeer.gstNumber}
                                    label="GST Number (Optional)"
                                    fullWidth
                                    inputProps={{ maxLength: 15 }}
                                    className={gstnVerified ? 'verifiedBorder' : ''}
                                    InputProps={{
                                        endAdornment: gstnVerified ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                                <Typography variant="body2" style={{ color: 'green', marginLeft: '4px' }}>Verified</Typography>
                                            </div>
                                        ) : !gstnVerified && currentEmployeer.gstNumber ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CancelIcon style={{ color: 'grey' }} />
                                                <Typography variant="body2" style={{ color: 'grey', marginLeft: '4px' }}>Unverified</Typography>
                                            </div>
                                        ) : null,
                                    }}
                                    error={!gstnVerified && currentEmployeer.gstNumber !== ''}
                                    // helperText={!isValidGST && currentEmployeer.gstNumber !== '' ? 'Invalid GST Number' : ''}
                                    onChange={handleChange1}
                                    onBlur={(e) => { handleVerification(e) }}
                                />

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="udyamAadhaar"
                                    value={currentEmployeer.udyamAadhaar}
                                    label="Udyam Aadhaar (Optional)"
                                    fullWidth
                                    inputProps={{ maxLength: 19 }} // Set max length to 19
                                    className={udyamAdharNumberVerified ? 'verifiedBorder' : ''}
                                    InputProps={{
                                        endAdornment: udyamAdharNumberVerified ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                                <Typography variant="body2" style={{ color: 'green', marginLeft: '4px' }}>Verified</Typography>
                                            </div>
                                        ) : !udyamAdharNumberVerified && currentEmployeer.udyamAadhaar ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CancelIcon style={{ color: 'grey' }} />
                                                <Typography variant="body2" style={{ color: 'grey', marginLeft: '4px' }}>Unverified</Typography>
                                            </div>
                                        ) : '',
                                    }}
                                    error={!udyamAdharNumberVerified && currentEmployeer.udyamAadhaar !== ''}
                                    helperText={getErrorMessage()} // Show error message
                                    onChange={handleChange2}
                                    onBlur={(e) => { handleVerification(e) }}
                                />
                            </div>

                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="shopActNumber"
                                    value={currentEmployeer.shopActNumber}
                                    label="Shop Act Number (Optional)"
                                    fullWidth
                                    inputProps={{ maxLength: 80 }}
                                    // className={shopActNoVerified ? 'verifiedBorder' : ''}
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         shopActNoTouched && currentEmployeer.shopActNumber ? (
                                    //             <div style={{ display: 'flex', alignItems: 'center' }}>
                                    //                 <CancelIcon style={{ color: 'grey' }} />
                                    //                 <Typography variant="body2" style={{ color: 'grey', marginLeft: '4px' }}>Unverified</Typography>
                                    //             </div>
                                    //         ) : null
                                    //     ),
                                    // }}
                                    onChange={handleShopActNumberChange}
                                    // onBlur={(e) => { handleVerification(e) }}
                                />
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="firmName"
                                    value={currentEmployeer.firmName}
                                    label="Name of Firm"
                                    fullWidth
                                    inputProps={{ maxLength: 80 }}
                                    onChange={(e) =>
                                        setCurrentEmployeer({
                                            ...currentEmployeer,
                                            firmName: e.target.value,
                                        })
                                    }
                                />
                            </div>

                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="natureOfBusiness-label">
                                        Nature of Firm / Business
                                    </InputLabel>
                                    <Select
                                        labelId="natureOfBusiness-label"
                                        id="natureOfBusiness"
                                        name="natureOfBusiness"
                                        value={currentEmployeer.natureOfBusiness}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                natureOfBusiness: e.target.value,
                                            })
                                        }
                                    >
                                        {natureOfFirm?.map((item) => {
                                            return (
                                                <MenuItem key={item} value={item}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="firmVintage-label">
                                        Firm / Business Vintage
                                    </InputLabel>
                                    <Select
                                        labelId="firmVintage-label"
                                        id="firmVintage"
                                        name="firmVintage"
                                        value={currentEmployeer.firmVintage}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                firmVintage: e.target.value,
                                            })
                                        }
                                    >

                                        {firmVintageDropdown &&
                                            firmVintageDropdown.map((item, index) => {
                                                const [value, label] = item.split(" ");
                                                return (
                                                    <MenuItem key={index} value={value}>
                                                        {item}
                                                    </MenuItem>
                                                );
                                            })}
                                        {/* {firmVintageDropdown && firmVintageDropdown.map( ( item ) => {
                                            return ( <MenuItem key={item} value={item}>{item}</MenuItem> )
                                        } )} */}
                                    </Select>
                                </ArthaFormControl>
                            </div>



                        </div >

                        <div className="row mt-3 mt-md-4">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <TextField
                                    name="numberOfEmployeesInFirm"
                                    value={currentEmployeer.numberOfEmployeesInFirm}
                                    label="Number of Employees in Firm"
                                    fullWidth
                                    inputProps={{ maxLength: 80 }}
                                    onChange={(e) =>
                                        setCurrentEmployeer({
                                            ...currentEmployeer,
                                            numberOfEmployeesInFirm: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Incorporation / Commencement Month"
                                        value={currentEmployeer.incorporationMonth}
                                        onChange={(date) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                incorporationMonth: date,
                                            })
                                        }
                                        renderInput={(params) => <TextField {...params} />}
                                        className="yearPicker"
                                        views={['year', 'month']}
                                        openTo="month"
                                        inputFormat="MM-YYYY" // Correct prop for formatting
                                        shouldDisableDate={disableFutureYearsAndMonth}
                                    />
                                </LocalizationProvider>
                            </div>


                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="firmPremisesOwner-label">
                                        Firm Premises Owner
                                    </InputLabel>
                                    <Select
                                        labelId="firmPremisesOwner-label"
                                        id="firmPremisesOwner"
                                        name="firmPremisesOwner"
                                        value={currentEmployeer.firmPremisesOwner}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                firmPremisesOwner: e.target.value,
                                            })
                                        }
                                    >
                                        {firmVintageOwnerDropdown && firmVintageOwnerDropdown.map((item) => {
                                            return (
                                                <MenuItem key={item} value={item}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="firmPremisesVintage-label">
                                        Firm Premises Vintage
                                    </InputLabel>
                                    <Select
                                        labelId="firmPremisesVintage-label"
                                        id="firmPremisesVintage"
                                        name="firmPremisesVintage"
                                        value={currentEmployeer.firmPremisesVintage}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                firmPremisesVintage: e,
                                            })
                                        }
                                    >
                                        {firmVintageDropdown.map((item, index) => {
                                            const [value, label] = item.split(' ');
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </ArthaFormControl>
                            </div>

                        </div>

                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <ArthaFormControl fullWidth>
                                    <InputLabel id="yearsInCurrentProfession-label">
                                        Number of Year in current profession
                                    </InputLabel>
                                    <Select
                                        labelId="yearsInCurrentProfession-label"
                                        id="yearsInCurrentProfession"
                                        name="yearsInCurrentProfession"
                                        value={currentEmployeer.yearsInCurrentProfession}
                                        onChange={(e) =>
                                            setCurrentEmployeer({
                                                ...currentEmployeer,
                                                yearsInCurrentProfession: e.target.value,
                                            })
                                        }
                                    >
                                        {currentProfessionYearsDropdown.map((item, index) => {
                                            const [value, label] = item.split(' ');
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Working since"
                                        value={currentEmployeer.workfrom}
                                        onChange={(e) =>
                                            handleWorkingSince(e)
                                        }
                                        renderInput={(params) => <TextField {...params} />}
                                        className="yearPicker"
                                        maxDate={currentDate}
                                    />
                                </LocalizationProvider>
                            </div>


                        </div>
                        <div className="row mt-3 mt-md-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <p className="msg">Official mail address (Optional)</p>

                                        {
                                            isEmailAdded ? <>
                                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Email Added
                                                </span>
                                            </> : <>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Not Uploaded
                                                </span>
                                            </>

                                        }
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-3"
                                            onClick={() => { setOpenEmailDialog(true); }}
                                        >
                                            {isEmailAdded ? 'Edit' : 'Add Email'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <p className="msg">Firm Address</p>

                                        {
                                            isAddressAdded ? <>
                                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Address added!
                                                </span>
                                            </> : <>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Not Added
                                                </span>
                                            </>

                                        }
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-3"
                                            onClick={openAddrDialog}
                                        >
                                            {!isAddressAdded ? ' Add Address' : ' Edit'}
                                        </Button>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="row mt-3 mt-md-4">

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <p className="msg">Bank Account Details</p>

                                        {
                                            bankDetails ? <>
                                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Bank Added
                                                </span>
                                            </> : <>
                                                <FaExclamationCircle className="badge-icon" />
                                                <span
                                                    className="mt-2 upload-lable"
                                                    style={{ position: "relative", top: "-10px" }}
                                                >
                                                    {" "}
                                                    Not Added
                                                </span>
                                            </>

                                        }
                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-3"
                                            onClick={() => { setOpenBankDialog(true) }}
                                        >
                                            {bankDetails ? 'Edit' : 'Add Bank'}
                                        </Button>
                                        <AddBank openBankDialog={openBankDialog} onCancel={cancelBankDialog} onBankDataSave={onBankDataSave} prevData={bankDetails} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-8 col-md-7">
                                        <p className="msg">Supporting Documents</p>

                                        {
                                            (
                                                (selectedDocs && selectedDocs.document && selectedDocs.document.length > 0) ? (
                                                    selectedDocs.document.map((doc, index) => (
                                                        <React.Fragment key={index}>
                                                            <FaExclamationCircle className="badge-icon" style={{ marginTop: "-7px", color: 'green' }} />
                                                            <span
                                                                className="mt-2 upload-lable"
                                                                style={{ position: "relative", top: "-10px" }}
                                                            >
                                                                {" "}
                                                                {doc.documentType} <br />
                                                            </span>

                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    <>
                                                        <FaExclamationCircle className="badge-icon" />
                                                        <span
                                                            className="mt-2 upload-lable"
                                                            style={{ position: "relative", top: "-10px" }}
                                                        >
                                                            {" "}
                                                            Not Uploaded
                                                        </span>
                                                    </>
                                                )
                                            )
                                        }

                                    </div>
                                    <div className="col-4 col-md-5">
                                        <input
                                            type="file"
                                            ref={fileInputRefID}
                                            style={{ display: "none" }}
                                            onChange={handleFileChangeID}
                                        />
                                        <Button
                                            variant="contained"
                                            className="upload_btn btn_white shadow-none mt-3"
                                            onClick={handleUploadDocument}
                                        >
                                            Upload
                                        </Button>
                                        <UploadSupportiveDocs
                                            open={isPopupOpen}
                                            existingDocuments={selectedDocs.document}
                                            onRemove={removeExistingDoc}
                                            handleClose={handlePopupClose}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mt-md-4" style={{ display: isPreviousEmpReq ? 'block' : 'none' }}>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="text-primary">We need profession details of last 2 years. Add details of previous profession / Employeer</span>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                                <span className="text">Employment Type</span>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="radio" name="empType" value="Salaried" onChange={(e) => { setPrevEmpType(e.target.value); }} style={{ marginRight: '5px' }} />
                                <b>Salaried</b>
                                <span style={{ margin: '0 10px' }}></span>
                                <input type="radio" name="empType" value="selfEmployed" onChange={(e) => { setPrevEmpType(e.target.value); }} style={{ marginRight: '5px' }} />
                                <b>Self Employed</b>
                            </div>



                            {(prevEmpType == 'selfEmployed' || previousEmployeer.firmName != "") &&
                                <PreviousEmployer worksince={currentEmployeer.workfrom} firmVintageDropdown={firmVintageDropdown} onPrevBankDetailsSave={onPrevBankDetailsSave} previousAddress={previousAddress} setPreviousAddress={setPreviousAddress} previousEmployeer={previousEmployeer} setPreviousEmployeer={setPreviousEmployeer} totalExpDropdown={totalExpDropdown} firmVintageOwnerDropdown={firmVintageOwnerDropdown} prevBankDetails={prevBankDetails} selectedDocs={selectedDocs} setSelectedPrevDocs={setSelectedPrevDocs} />
                            }

                            {prevEmpType == 'Salaried' &&
                                <div className="row">
                                    <PreviousSalariedEmpDetails worksince={currentEmployeer.workfrom} prevSalariedEmployer={prevSalariedEmployer} setPrevSalariedEmployer={setPrevSalariedEmployer} salariedPreviousAddress={salariedPreviousAddress} setSalariedPreviousAddress={setSalariedPreviousAddress} />
                                </div>}

                        </div>
                        {/* Dialogs started */}


                        <Dialog
                            open={isOpenEmailDialog}

                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="modal row">
                                <div className="modal-content col-md-6">
                                    {!isEmailAdded ? <h6> Add Official Email ID</h6> : <h6>Edit Email</h6>}
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <span>Please enter official email id for your current employer. OTP validation will be needed for confirming the email id.</span>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <ArthaTextField
                                                name="line2"
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        officialEmail: e.target.value,
                                                    })
                                                }}
                                                value={formData.officialEmail}
                                                fullWidth
                                                label="Email ID"
                                                helperText={(!isEmailValid(currentEmployeer.officialEmail) && currentEmployeer.officialEmail != '') && 'Invalid email format'}
                                                error={!isEmailValid(formData.officialEmail)}
                                            />
                                        </div>

                                    </div>

                                    <div className="row mt-4 mx-auto">
                                        <div className="col-6 d-flex justify-content-end mt-4">
                                            <ArthaClearButton variant="contained" onClick={closeEmailDialog}>
                                                Cancel
                                            </ArthaClearButton>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start mt-4">
                                            <ArthaContinueButton
                                                variant="contained"
                                                disabled={!emailRegex.test(formData.officialEmail)} 

                                                onClick={() => { openOTPDialog(); }}
                                                style={{
                                                    opacity: isButtonClicked ? 0.39 : 1,
                                                    pointerEvents: isButtonClicked ? "none" : "auto"
                                                }}
                                            >
                                                {isLoading1 ? (
                                                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                                                ) : (
                                                    'Continue'
                                                )}
                                            </ArthaContinueButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>


                        <Dialog
                            open={isOpenOTPDialog}

                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="otpBox">
                                <div className="AdharOTPForm">
                                    <div className="AdharOTPHeading mt-3">
                                        <h5>Otp Validation</h5>

                                        <p className="msg mt-4" style={{ padding: "0", margin: "0" }}>
                                            Please Enter OTP Sent to
                                        </p>
                                        <span className="msg mt-4" style={{ fontSize: "10px", color: "blue", padding: "0", margin: "0" }}>
                                            {formData.officialEmail}
                                        </span>

                                        <div className="Verify-OTP mt-5">
                                            <div className="Verify-OTP-section">
                                                <div className="otp-inputs">
                                                    {otp.map((value, index) => (
                                                        <input
                                                            key={index}
                                                            type="text"
                                                            className={`otp-input ${isInvalidOtp ? "error" : ""}`}
                                                            maxLength="1"
                                                            value={value}
                                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                                            onKeyDown={(e) => handleInputKeyDown(index, e)}
                                                            ref={(ref) => (inputRefs.current[index] = ref)} 
                                                        />
                                                    ))}
                                                </div>

                                                <br />

                                                <h6 className="text-center">
                                                    Didn't Receive any Code?{" "}
                                                    <a
                                                        href="#"
                                                        style={{ color: "rgb(255, 136, 0)", marginLeft: "5px" }}

                                                    >
                                                        <h6 className="mt-3" onClick={handleResend}>Resend Code</h6>
                                                    </a>
                                                </h6>

                                            </div>
                                            <div className="row mt-5 ">
                                                <div className="col-6 d-flex justify-content-end">
                                                    <ArthaClearButton
                                                        variant="contained"
                                                        onClick={() => { setOpenOTPDialog(false); }}
                                                    >
                                                        Back
                                                    </ArthaClearButton>
                                                </div>

                                                <div className="col-6 d-flex justify-content-start">
                                                    <ArthaContinueButton
                                                        onClick={handleVerifyClick}
                                                        variant="contained"
                                                        disabled={isInvalidOtp}
                                                        style={{
                                                            opacity: isButtonClicked ? 0.39 : 1,
                                                            pointerEvents: isButtonClicked ? "none" : "auto"
                                                        }}
                                                    >
                                                        {isLoading2 ? (
                                                            <LoadingSpinner style={{ pointerEvents: 'none' }} />
                                                        ) : (
                                                            'Continue'
                                                        )}
                                                    </ArthaContinueButton>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Dialog>


                        {/* Address Dialog */}
                        <Dialog
                            open={openAddressDialog}

                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div className="modal row">
                                <div className="modal-content col-md-6">
                                    {!isAddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                                    <div className="row">
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="line1"
                                                fullWidth
                                                value={currentAddress && currentAddress.line1}
                                                onChange={(e) => { handleAddressChange(e) }}
                                                label="Address Line 1"

                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <ArthaTextField
                                                name="line2"
                                                onChange={(e) => { handleAddressChange(e) }}
                                                value={currentAddress && currentAddress.line2}
                                                fullWidth
                                                label="Address Line 2"
                                            />
                                        </div>
                                        <div className="col-md-6 mt-5 mt-md-3">
                                            <ArthaTextField
                                                name="pincode"
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                    handleAddressChange({ target: { name: 'pincode', value: inputValue } });
                                                }}
                                                value={currentAddress && currentAddress.pincode}
                                                label="Pincode"
                                                fullWidth
                                            />
                                            <span style={{ color: 'red', fontSize: '12px' }}>{pincodeError}</span>

                                        </div>
                                        <div className="col-md-6 mt-3 mt-md-3">
                                            <ArthaTextField
                                                name="city"
                                                label="City"
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                                    handleAddressChange({ target: { name: 'city', value: inputValue } });
                                                }}
                                                value={currentAddress && currentAddress.city}
                                                fullWidth

                                            />
                                        </div>
                                        <div className="col-md-6 mt-3 mt-md-3">
                                            <ArthaTextField
                                                name="state"
                                                label="State"
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                                    handleAddressChange({ target: { name: 'state', value: inputValue } });
                                                }}
                                                value={currentAddress && currentAddress.state}
                                                fullWidth
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-4 mx-auto">
                                        <div className="col-6 d-flex justify-content-end mt-4">
                                            <ArthaClearButton variant="contained" onClick={closeAddrDialog}>
                                                Cancel
                                            </ArthaClearButton>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start mt-4">
                                            <ArthaContinueButton
                                                variant="contained"
                                                disabled={!isFormFilled}
                                                onClick={() => { setOpenAddressDialog(false); setAddressAdded(true) }}
                                            >
                                                Save
                                            </ArthaContinueButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        <div className="row mt-4">
                            <div className="col-6 d-flex justify-content-end">
                                <ArthaClearButton variant="contained" onClick={handleBack}>
                                    Back
                                </ArthaClearButton>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <ArthaContinueButton
                                    variant="contained"
                                    onClick={handleContinue}
                                    disabled={!isFormValid}
                                    style={{
                                        opacity: isButtonClicked ? 0.39 : 1,
                                        pointerEvents: isButtonClicked ? "none" : "auto"
                                    }}

                                >
                                    {isLoading3 ? (
                                        <LoadingSpinner style={{ pointerEvents: 'none' }} />
                                    ) : (
                                        'Continue'
                                    )}
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </form >
                </>
            ) : (
                <ITrDetails
                    onContinue={onContinue}
                    onCancel={onCancel}
                //   onCancel={() => setShowItr( false )}
                />
            )}
        </>
    );
};

export default ProfessionalDetails;


