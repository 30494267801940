import React, { useEffect, useState } from "react";
import ArthaApplyButton from "../../fields/ArthaApplyButton";
import ArthaCancelButton from "../../fields/ArthaCancelButton";
import LoanOffer from "./LoanOffer";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { handleSignOut } from "./SessionExpiredApi";
const EditRequirement = ({ response, tenureLength, setShowAllOffersFun }) => {
  const [loanData, setLoanData] = React.useState();
  const [originalLoanData, setOriginalLoanData] = React.useState();
  const [updatedLoanData, setUpdatedLoanData] = React.useState();
  const [originalOfferSelected, setOriginalOfferSelected] =
    React.useState(false);
  const [updatedOfferSelected, setUpdatedOfferSelected] = React.useState(false);
  const [selectedLoanAmount, setSelectedLoanAmount] = useState(0);
  const [disableContinue, setDisableContinue] = useState(true);
  const [isCalculateDisabled, setIsCalculateDisabled] = useState(true);
  const [calculatedValues, setCalculatedValues] = useState({
    emi: 0,
    tenure: 0,
    interestRate: 0,
  });
  const handleApply = () => {
    if (updatedLoanData){
      setShowAllOffersFun( updatedLoanData );
    }
    else
        setShowAllOffersFun();
};

  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const navigate = useNavigate();

  const handleLoanAmountChange = (event) => {
    setLoanData({ ...loanData, loanAmount: event.target.value });
    setIsCalculateDisabled(false);
    setDisableContinue(true);
    setUpdatedLoanData("");
  };

  const handleLoanTermChange = (event) => {
    let tenureAmount = event.target.value;
    let tenures = response.loanOfferData.foirCheckJson[0].tenure;
    let loanAmountTemp = loanData.loanAmount;
    for (let index = 0; index < tenures.length; index++) {
      const element = tenures[index];

      if (element.Tenure == tenureAmount) {
        if (loanAmountTemp > element.MaXLoanAmount)
          loanAmountTemp = element.MaXLoanAmount;
        setLoanData((prevLoanData) => ({
          ...prevLoanData,
          loanAmount: loanAmountTemp,
          loanTenure: event.target.value,
          emi: element.EMIPerLakh,
        }));

        setMaxLoanAmount(element.MaXLoanAmount);
      }
    }
    setIsCalculateDisabled(false);
    setDisableContinue(true);
    setUpdatedLoanData("");
  };

  const handleCalculate = async () => {
    // setUpdatedLoanData(
    //     {
    //         "emi": 7738.235805273913,
    //         "fee": "1%",
    //         "loanAmount": 155000,
    //         "loanTenure": 24,
    //         "rate": "0.18"
    //     }

    // )
    // setIsCalculateDisabled( true );
    setDisableContinue(false);
    setSelectedLoanAmount(loanData.loanAmount);

    const apiPayload = {
      loan_amount: parseInt(loanData.loanAmount),
      loan_tenure: parseInt(loanData.loanTenure),
    };

    const headers = {
      "Content-Type": "application/json",
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };

    try {
      const response = await fetch("/personal-loan/loan-offer", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(apiPayload), // Make sure to stringify the payload
      });

      if (response.ok) {
        const responseData = await response.json(); // Assuming the response is JSON
        let obj = responseData; // Update obj with the actual response data
        let updatedLoanInfo = obj.loanOffer[0];
        setUpdatedLoanData(updatedLoanInfo);
        setIsCalculateDisabled(true);
      } else {
        // Handle error, e.g., show an error message
        console.error("Error in API call:", response.statusText);
      }
    } catch (error) {
      // Handle unexpected errors
      if ( error.response.status === 401 ) {
        handleSignOut();
        toast.error( "Your Session has expired.You will be redirected to Login Page." )
        navigate( '/' )
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error:", error);
    }
  };
  useEffect( () => {
    handleCalculate();
  }, [] )
  let [minLoanAmount, setMinLoanAmount] = React.useState();
  let [maxLoanAmount, setMaxLoanAmount] = React.useState();

  useEffect(() => {
    if (response) {
      let obj = {
        associationType:
          response.loanOfferData.loanOfferJson[0].associationType,
        emi: response.loanOfferData.loanOfferJson[0].emi,
        fee: response.loanOfferData.loanOfferJson[0].fee,
        lenderId: response.loanOfferData.loanOfferJson[0].lenderId,
        loanAmount: response.loanOfferData.loanOfferJson[0].loanAmount,
        loanTenure: response.loanOfferData.loanOfferJson[0].loanTenure,
        rate: response.loanOfferData.loanOfferJson[0].rate,
      };
      setLoanData(obj);
      setOriginalLoanData(obj);
      setMinLoanAmount(response.loanOfferData.foirCheckJson[0].minimumLoan);
      let tenures = response.loanOfferData.foirCheckJson[0].tenure;
      for (let index = 0; index < tenures.length; index++) {
        const element = tenures[index];
        if (
          element.Tenure == response.loanOfferData.loanOfferJson[0].loanTenure
        ) {
          setMaxLoanAmount(element.MaXLoanAmount);
        }
      }
    }
  }, [response]);

  // const onclickHandlerForOriginal = () => {
  //     setUpdatedOfferSelected( false );
  //     setOriginalOfferSelected( !originalOfferSelected );
  // }

  // const onclickHandlerForUpdated = () => {
  //     setUpdatedOfferSelected( !updatedOfferSelected );
  //     setOriginalOfferSelected( false );
  // }

  // useEffect( () => {
  //   setUpdatedOfferSelected( updatedOfferSelected );
  //   setOriginalOfferSelected( false );
  // }, [updatedOfferSelected ] )

  return (
    <>
      {loanData && (
        <>
          <div className="row mt-4 d-flex justify-content-center">
            <div
              className="col-11 shadow-none loan-offer-card card"
            >
              <div className="row card-body d-flex justify-content-center">
                <div className="col-4  text-center">
                  <h6>Original loan offer</h6>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <p className="offer-page-heading">EMI/Month</p>
                    <h4 className="loan-offer-amount">
                      ₹{originalLoanData.emi.toFixed(2)}
                    </h4>
                  </div>
                  <div className="col-3">
                    <p className="offer-page-heading">Loan Amount</p>
                    <p className="loan-offer-amount2">
                      ₹{originalLoanData.loanAmount}{" "}
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="offer-page-heading">Tenure</p>
                    <p className="loan-offer-amount2">
                      {originalLoanData.loanTenure} mths
                    </p>
                  </div>
                  <div className="col-2">
                    <p className="offer-page-heading">interest Rate</p>
                    <p className="loan-offer-amount2">
                      {(originalLoanData.rate * 100).toFixed(2)}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 d-flex justify-content-center">
            <div className="col-11 shadow-none card">
              <div className="row">
                <div className="col-12 mt-3 text-center ">
                  <h6>Edit Requirement</h6>
                  <p className="offer-page-heading">
                    Change loan amount, tenure and calculate to see the updated
                    EMI
                  </p>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    value={loanData.loanAmount}
                    onChange={handleLoanAmountChange}
                  />

                  <input
                    type="range"
                    className="form-range range-slider"
                    min={minLoanAmount}
                    max={maxLoanAmount}
                    step={25000}
                    value={loanData.loanAmount}
                    onChange={handleLoanAmountChange}
                  />

                  <div className="d-flex">
                    <p className="small-heading">₹{minLoanAmount}</p>
                    <p className="small-heading ms-auto">₹{maxLoanAmount}</p>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    value={loanData.loanTenure}
                    onChange={handleLoanTermChange}
                  />
                  <input
                    type="range"
                    className="form-range range-slider"
                    min={
                      response.loanOfferData.foirCheckJson[0].tenure[0].Tenure
                    }
                    max={
                      response.loanOfferData.foirCheckJson[0].tenure[
                        tenureLength - 1
                      ].Tenure
                    }
                    step={12}
                    value={loanData.loanTenure}
                    onChange={handleLoanTermChange}
                  />

                  <div className="d-flex">
                    <p className="small-heading">
                      {response.loanOfferData.foirCheckJson[0].tenure[0].Tenure}
                    </p>
                    <p className="small-heading ms-auto">
                      {
                        response.loanOfferData.foirCheckJson[0].tenure[
                          tenureLength - 1
                        ].Tenure
                      }
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-4 mb-4 mx-auto">
                <div className="col-6 d-flex justify-content-end ">
                  <ArthaCancelButton
                    variant="contained"
                    onClick={() => {
                      setShowAllOffersFun();
                    }}
                    className="mt-1 m-0"
                  >
                    {" "}
                    <span className="calculate-text"> Cancel</span>
                  </ArthaCancelButton>
                </div>
                <div className="col-6 d-flex justify-content-start  ">
                  <ArthaApplyButton
                    variant="contained"
                    className="continue-btn mt-1 m-0"
                    disabled={isCalculateDisabled}
                    onClick={handleCalculate}
                  >
                    <span className="calculate-text">Calculate</span>
                  </ArthaApplyButton>
                </div>
              </div>

              {updatedLoanData && (
                <div className="row mt-4 d-flex justify-content-center">
                  <div
                    className="col-11 shadow-none card"
                  >
                    <div className="row card-body d-flex justify-content-center">
                      <div className="col-4  text-center">
                        <h6>Updated loan offer</h6>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4">
                          <p className="offer-page-heading">EMI/Month</p>
                          <h4 className="loan-offer-amount">
                            ₹{updatedLoanData.emi.toFixed(2)}
                          </h4>
                        </div>
                        <div className="col-3">
                          <p className="offer-page-heading">Loan Amount</p>
                          <p className="loan-offer-amount2">
                            ₹{updatedLoanData.loanAmount}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="offer-page-heading">Tenure</p>
                          <p className="loan-offer-amount2">
                            {updatedLoanData.loanTenure} mths
                          </p>
                        </div>
                        <div className="col-2">
                          <p className="offer-page-heading">Interest Rate</p>
                          <p className="loan-offer-amount2">
                            {(updatedLoanData.rate * 100).toFixed(2)}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center mt-4">
                    <ArthaApplyButton
                      variant="contained"
                      onClick={handleApply}
                      disabled={disableContinue}
                    >
                      Apply
                    </ArthaApplyButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EditRequirement;
