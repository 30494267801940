import React from 'react'
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { useNavigate } from "react-router-dom";
import Congrats from './images/congrats.png';

function Disbursement() {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate("/home");
    };
    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className=" text-center col-8">
       
                      <img src={Congrats} className='ps-5' style={{ width: "45%",justifyContent:'center' }} />
                  
            <h6 className="col-12 mt-1">
               {/* Yay! You have completed the loan application */}
               Congratulations! Your loan application is approved. Our admin team will contact you soon before final disbursement.
            </h6>
           
            <div className="row mt-5 d-flex justify-content-center">
              <div className="col-6 ">
                <ArthaContinueButton type="button" className="orange custom_btn " onClick={handleClick}>
                  Go to Home
                </ArthaContinueButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Disbursement