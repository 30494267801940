import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaTextField from "../../fields/ArthaTextField";
import PinCodeValidation from "./pinCodeValidation";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import { color } from "@mui/system";
import { createLoanApplicationAPI, userDetailsAPI, updatePersonalDetailsAPI } from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";
const PersonalDetailsForm = ({ onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    panCardNo: "",
    mobileNo: "",
    fullName: "",
    dateOfBirth: "",
    emailId: "",
    pinCode: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isValidPanCard, setIsValidPanCard] = useState(true);
  const [isValidDOB, setIsValidDOB] = useState(true);
  const navigate = useNavigate();
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [loading, setLoading] = useState(true);
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pinCodeError, setPinCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await createLoanApplicationAPI();
        if (response.businessStatusCode === 2) {
          setApid(response.ap_id);
          setLoanAppId(response.loan_app_id);
        }
        const loanApplicationStatus = response.status;
        navigate('/home/personalloans', {
          state: {
            loanApplicationStatus
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired.You will be redirected to Login Page.");
          navigate("/");
        } else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        } else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [ap_id, loan_app_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userDetailsAPI();
        const { mobile, pan, fullName, email, dob, pinCode } = response.data;

        setFormData((prevData) => ({
          ...prevData,
          panCardNo: pan,
          mobileNo: mobile,
          fullName: fullName,
          dateOfBirth: formatDate(dob),
          emailId: email,
          pinCode: pinCode,
        }));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired.You will be redirected to Login Page.");
          navigate("/");
        }
        //else if (error.response && error.response.status === 429) {
        //   toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        // }else {
        //   toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        // }
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, [ap_id, loan_app_id, navigate]);

  const formatDate = (inputDate) => {
    console.log("input date : ", inputDate);
    if (inputDate) {
      const [day, month, year] = inputDate.split("/");
      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return "";
      }

      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Apply specific validation based on the field name
    let updatedValue = value;

    if (name === "panCardNo") {
      // Validate Pan card format: First 5 letters, 4 digits, 1 letter
      updatedValue = value.toUpperCase();
      const enteredPanCard = updatedValue;
      const panCardRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
      setIsValidPanCard(panCardRegex.test(enteredPanCard));
    } else if (name === "fullName") {
      // Avoid digits and special symbols in Full Name
      updatedValue = value.replace(/[\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]/g, "");
    } else if (name === "mobileNo") {
      // Allow only numeric values in Mobile Number
      updatedValue = value.replace(/\D/g, "");
    } else if (name === "pinCode") {
      // Allow only numeric values in Pincode and ensure it has at least 6 digits
      updatedValue = value.replace(/\D/g, "").slice(0, 6);

      const isPinCodeValid = /^\d{6}$/.test(updatedValue);

      if (!isPinCodeValid) {
        setPinCodeError("Pincode must be 6 digits");
      } else {
        setPinCodeError(""); // Clear the error message if pincode is valid
      }
    } else if (name === "emailId") {
      // Validate email format
      const isEmailValid =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);

      if (!isEmailValid) {
        setEmailError("Invalid Email. Please enter a valid email address.");
      } else {
        setEmailError(""); // Clear the error message if email is valid
      }

      setIsEmailValid(isEmailValid);
      updatedValue = value;
    } else if (name === "dateOfBirth") {
      const [year, month, day] = value.split("-");

      if (!/^\d{4}$/.test(year)) {
        console.error("Invalid year format");
        return;
      }

      updatedValue = `${year}-${month}-${day}`;
    }

    setFormData((prevData) => ({ ...prevData, [name]: updatedValue }));
    validateForm();
  };

  const validateForm = () => {
    const { panCardNo, mobileNo, fullName, dateOfBirth, emailId, pinCode } =
      formData;

    const isPanCardValid = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/.test(panCardNo);
    const isMobileValid = /^\d{10}$/.test(mobileNo);
    const isFullNameValid = fullName && fullName.trim() !== "";
    let isDateOfBirthValid = false;
    const isEmailValid = /^\S+@\S+\.\S+$/.test(emailId);
    const isPinCodeValid = /^\d{6}$/.test(pinCode);


    const currentDate = new Date();
    const selectedDate = new Date(dateOfBirth);
    const minDate = new Date(currentDate);
    minDate.setFullYear(minDate.getFullYear() - 100); // Minimum age: 100 years
    const maxDate = new Date(currentDate);
    maxDate.setFullYear(maxDate.getFullYear() - 18); // Maximum age: 18 years

    if (selectedDate < minDate || selectedDate > maxDate) {
      isDateOfBirthValid = false;
      setIsValidDOB(false);
    } else {
      isDateOfBirthValid = true;
      setIsValidDOB(true);
    }

    setIsFormValid(
      isPanCardValid &&
      isMobileValid &&
      isFullNameValid &&
      isDateOfBirthValid &&
      isEmailValid &&
      isPinCodeValid
    );
  };

  const handleClear = () => {
    navigate("/home");
  };

  const pinCodeRef = useRef(null);

  useEffect(() => {
    // Focus on the Pincode input field when the component mounts
    const pinCodeInput = document.getElementById("pin-code-inpt");
    if (pinCodeInput) {
      pinCodeInput.focus();
    }
  }, []);


  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor:
      isFormValid && isEmailValid
        ? "rgba(255, 115, 0, 1)"
        : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const clearButtonStyle = {
    color: "black",
    backgroundColor: isFormValid
      ? "rgba(255, 255, 255, 1)"
      : "rgb(255 255 255 / 39%)",
  };

  const handleContinue = async () => {
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);
    // Optionally, you can perform additional logic or validation before moving to the next step
    const formattedDateOfBirth = reverseFormatDate(formData.dateOfBirth);
    // Include pincode in the payload
    const data = {
      pinCode: formData.pinCode,
      email: formData.emailId,
      fullName: formData.fullName,
      dob: formattedDateOfBirth,
    };
    try {
      const response = await updatePersonalDetailsAPI(data);
      const { pincode, dob, username, usermobile, userpan, email } =
        response.data;

      if (response.status === 200 && response.data.businessStatusCode === 1) {
        // Check if the response is successful
        setShowEligibilityCheckForm(false);
        setIsOpen(true);

        if (pincode && dob && username && usermobile && userpan && email) {
          // Navigate to the Emi_Calculator page
          navigate({
            state: {
              pincode,
              dob,
              username,
              usermobile,
              userpan,
              email,
            },
          });
        } else {
          console.error("Invalid loan data in response.");
        }
      } else {
        setShowEligibilityCheckForm(true);
        onSubmit(formData);
      }
    } catch (error) {
      if (error.response.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching loan data:", error);
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    };

  };

  const reverseFormatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const closeModal = () => {
    setIsOpen(false);
    let inpt = document.getElementById("pin-code-inpt");
    inpt.focus();
  };

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const minDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
  //   const formattedMinDate = minDate.toISOString().split('T')[0];

  //   // Update the minimum date for the dateOfBirth field
  //   const dateOfBirthInput = document.getElementsByName('dateOfBirth')[0];
  //   if (dateOfBirthInput) {
  //     dateOfBirthInput.setAttribute('min', formattedMinDate);
  //   }
  // }, []);
  const currentDate = new Date().toISOString().split('T')[0];
  return (
    <>
      {!showEligibilityCheckForm ? (
        <>
          <h6 className="text">Personal Details</h6>

          <form className="mt-4">
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="panCardNo"
                  value={formData.panCardNo}
                  onChange={handleChange}
                  label="Pan Card No."
                  fullWidth
                  variant="outlined"
                  // style={{ pointerEvents: "none" }}
                  disabled
                  className="form-control"
                />

                <div className="subtitle">
                  <div
                    className={`pan-card-validation ${isValidPanCard ? "" : "invalid-input"
                      }`}
                  >
                    {isValidPanCard
                      ? ""
                      : "Invalid Pan Card. Please enter in the specified format."}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleChange}
                  label="Mobile No."
                  fullWidth
                  // style={{ pointerEvents: "none" }}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3 mt-md-4">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  name="fullName"
                  value={formData.fullName}
                  disabled
                  onChange={handleChange}
                  label="Full Name"
                  fullWidth
                  inputProps={{ maxLength: 80 }}
                />
                <p className="msg">As per PAN</p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="date"
                  name="dateOfBirth"
                  label="Date of birth"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  // style={{ pointerEvents: "none" }}
                  disabled
                  inputProps={{
                    max: currentDate // Set max date to current date
                  }}
                  fullWidth
                />
                {!isValidDOB && <p className="msg" style={{ color: 'red' }}>Invalid date</p>}
              </div>
            </div>

            <div className="row mt-3 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  label="Email"
                  fullWidth
                  error={Boolean(emailError)}
                  helperText={emailError}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="pinCode"
                  type="text"
                  maxLength="6"
                  value={formData.pinCode}
                  onChange={handleChange}
                  label="Pincode of your current address"
                  id="pin-code-inpt"
                  fullWidth
                  error={Boolean(pinCodeError)}
                  helperText={pinCodeError}
                  autoFocus

                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>

            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                <ArthaClearButton
                  variant="contained"
                  onClick={handleClear}
                // disabled={!isFormValid}
                // style={clearButtonStyle}
                >
                  Cancel
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start mt-4">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  style={buttonStyle}

                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                </ArthaContinueButton>
              </div>
            </div>
            <PinCodeValidation isOpen={isOpen} closeModal={closeModal} />
          </form>
        </>
      ) : (
        <EligibilityCheckForm
          onContinue={onSubmit}
          onCancel={() => setShowEligibilityCheckForm(false)}
        />
      )
      }
    </>
  );
};

export default PersonalDetailsForm;
