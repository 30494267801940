import { createStore } from "redux";
import loanReducer from "./components/reducers/loanReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
    key: 'root',
    storage,
};
const persistedReducer = persistReducer( persistConfig, loanReducer );

const store = createStore( persistedReducer );

const persistor = persistStore( store );

export { store, persistor };