import React from "react";
import ArthaContinueButton from "../../../fields/ArthaContinueButton";
import { useNavigate } from "react-router-dom";

const CompletedLoanApplication = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="inavlid-box  text-center     mx-auto col-8  text-center ">
          <h5 className="col-12 failed-heading">
             {/* Yay! You have completed the loan application */}
             Your Loan Application is under verification and you will be notified
            when it moves to the next step
          </h5>
          {/* <p className="failed-subheading">
            Your Loan Application is under verification and you will be notified
            when it moves to the next step
          </p> */}
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-6 ">
              <ArthaContinueButton type="button" className="orange custom_btn " onClick={handleClick}>
                Go to Home
              </ArthaContinueButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedLoanApplication;
