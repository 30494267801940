import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { handleSignOut } from "./SessionExpiredApi";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";
import EligibilityTenureForm from "./EligibilityTenureForm";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl.js";
import ArthaInputLabel from "../../fields/ArthaInputLabel.js";
import "./personalLoan.css";
import LoadingSpinner from "../../fields/spinner";
import { fetchDataAPI, preDe1CallAPI, decisionEngine1API } from "../../actioncreators/actioncreators.js";
import { toast } from 'react-toastify';

const EligibilityCheckForm = ({ onContinue, onBack, userData, ckycSuccess }) => {
  const [showEligibilityTenureForm, setShowEligibilityTenureForm] =
    useState(false);

  const [ currentFormData, setCurrentFormData ] = React.useState( {} );
  const [ purposeDropdown, setPurposeDropdown ] = React.useState( [ "Doctor", "CA", "CS", "CMA/ICWA (Certified Management Accountant)", "Lawyer", "Architect" ] );
  const [ selfEmpTypeDropdown, setSelfEmpTypeDropdown ] = React.useState( [ 'Professional', 'Non Professional' ] );
  const [ professionTypeDropdown, setProfessionTypeDropdown ] = React.useState( [ "Doctor", "CA", "CS", "CMA/ICWA (Certified Management Accountant)", "Lawyer", "Architect" ] );
  const [ isNonProfessionalSelected, setNonProfessionalSelected ] = React.useState( false );
  const [ isSelfEmpSelected, setSelfEmpSelected ] = React.useState( false );
  const [ isEmploymentDisabled, setIsEmploymentDisabled ] = React.useState( false );

  // useEffect( () => {
  //   if ( !isNonProfessionalSelected && isSelfEmpSelected ) {
  //     fetchDropDownValues();
  //   }
  // }, [ isNonProfessionalSelected ] );


  const fetchDropDownValues = async () => {
    setProfessionTypeDropdown(["Doctor", "CA", "CS", "CMA/ICWA (Certified Management Accountant)", "Lawyer", "Architect"])
    try {
      let payload = {
        "type": "SELF_EMPLOYED",
        "key": "PROFESSIONAL",
        "source": "json_value"
      }
      const response = await fetchDataAPI(payload)
      setProfessionTypeDropdown(response.data.data[0].values)
    } catch (error) {
      if (error.response.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      } else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }
      else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching address vintage:", error);
    }
  };

  const fetchPurposeValues = async () => {
    setPurposeDropdown( [] )
    try {
      let payload = {
        "type": "PERSONAL_LOAN",
        "key":"PURPOSE",
        "source":"json_value"
    }
      const response = await fetchDataAPI(payload)
      setPurposeDropdown( response.data[ 0 ].List )
    } catch ( error ) {
      if ( error.response.status === 401 ) {
        await handleSignOut();
        toast.error( "Your Session has expired.You will be redirected to Login Page." )
        navigate( '/' )
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }
      else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error( "Error fetching address vintage:", error );
    }
  };
  useEffect( () => {
      fetchPurposeValues();
  }, [] );
  
useEffect( () => {
  if ( !isNonProfessionalSelected && isSelfEmpSelected ) {
    fetchDropDownValues();
  }
}, [ isNonProfessionalSelected ] );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await preDe1CallAPI();
        if (response.data.hasOwnProperty('employmentType')) {
          setIsEmploymentDisabled(true);
        }
        const {
          employmentType,
          salary,
          existingObligations,
          initialLoanAmount,
          initialLoanTenure,
          purpose,
          employmentSubType
        } = response.data;



        setFormData({
          employmentType: employmentType,
          monthlyIncome: salary,
          existingObligationEMI: existingObligations,
          loanAmount: initialLoanAmount,
          tenure: initialLoanTenure,
          purpose: purpose,
          employmentSubType: employmentSubType,
          selfEmploymentType: "Professional"
        });

      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired. You will be redirected to the Login Page.");
          navigate('/');
        } else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }
        else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);


  // Initialize formData state with default values
  const [formData, setFormData] = useState({
    employmentType: "Salaried", // Set the default value to "salaried"
    monthlyIncome: "",
    existingObligationEMI: "",
    loanAmount: "",
    tenure: "",
    purpose: "",
    employmentSubType: "",
    selfEmploymentType: "Professional"
  });


  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "employmentType") {
      if (value === "Self Employed") {
        setSelfEmpSelected(true);
        localStorage.setItem('isSelfEmpSelected', 'true');
      } else {
        setSelfEmpSelected(false);
        localStorage.setItem('isSelfEmpSelected', 'false');
        localStorage.setItem('profession', '')
      }

    }

    if (name === "existingObligationEMI" || name === "loanAmount" || name === "monthlyIncome") {
      // Sanitize input by removing non-digit characters
      const sanitizedValue = value.replace(/\D/g, '');
      // Define regex pattern for validation
      const regex = /^[0-9]{0,8}$/;

      // Validate the sanitized value
      if (regex.test(sanitizedValue)) {
        setFormData({
          ...formData,
          [name]: sanitizedValue,
        });
      } else {
        // Optionally handle the case where validation fails
        console.error("Invalid input. Please enter a number with up to 8 digits.");
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Validate the form on each change
    validateForm();
  };


  const [isFormValid, setIsFormValid] = useState(true);


  const handleBack = () => {
    onBack(); // Call the onBack function passed as a prop
  };


  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const navigate = useNavigate();


  const handleContinue = async () => {
    setIsButtonClicked(true);
    setIsLoading(true);
    setIsEmploymentDisabled(true);
    let data;
    if (formData.employmentType === 'Salaried') {
      data = {
        income: formData.monthlyIncome,
        emi: formData.existingObligationEMI,
        amount: formData.loanAmount,
        purpose: formData.purpose,
        employmentType: formData.employmentType,
        tenure: formData.tenure.toString(),
        exactPurpose:formData.exactPurpose
      };
    } else {
      data = {
        income: formData.monthlyIncome,
        emi: formData.existingObligationEMI,
        amount: formData.loanAmount,
        purpose: formData.purpose,
        employmentType: formData.employmentType,
        tenure: formData.tenure.toString(),
        employmentSubType: formData.employmentSubType,
        exactPurpose:formData.exactPurpose
      };
    }

    try {
      const response = await decisionEngine1API(data);

      const {
        interestRate,
        maxLoanAmount,
        maxTenure,
        minimumLoan,
        tentativeEmi,
        minimumTenure,
        loanAmount,
        loanTenure,
        tenure,
        businessStatusCode,
        message
      } = response.data;

      setIsLoading(false);

      if (response.status === 200) {
        if (businessStatusCode === 2) {
          toast.success('Financial Details Added Successfully.')
          setShowEligibilityTenureForm(true);
          navigate(EligibilityTenureForm, {
            state: {
              interestRate,
              maxLoanAmount,
              maxTenure,
              minimumLoan,
              tentativeEmi,
              minimumTenure,
              ap_id,
              loan_app_id,
              loanAmount,
              loanTenure,
              tenure
            }
          });
        } else if (businessStatusCode === 1) {
          setShowEligibilityTenureForm(false);
          toast.error(message);
        } else {
          console.error("Invalid loan data in response.");
        }
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response && error.response.status === 401) {
        handleSignOut();
        toast.error("Your session has expired. You will be redirected to the login page.");
        navigate('/');
      } else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      } else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error fetching loan data:", error);

    }
    finally {
      // Reset loading state after the operation is complete
      setIsLoading(false);
      setIsButtonClicked(false);
    }

  };


  const validateForm = () => {
    const {
      employmentType,
      monthlyIncome,
      existingObligationEMI,
      loanAmount,
      tenure,
      purpose,
    } = formData;

    // Validate each field
    const isEmploymentTypeValid = employmentType !== "";
    const isMonthlyIncomeValid = /^\d+$/.test(monthlyIncome);
    const isExistingObligationEMIValid = /^\d+$/.test(existingObligationEMI);
    const isLoanAmountValid = /^\d+$/.test(loanAmount);
    const isTenureValid = tenure !== "" && tenure !== "0";
    const isPurposeValid = purpose && purpose.trim() !== "";
    // Update the overall form validity
    if (isNonProfessionalSelected) {
      setIsFormValid(false);
    }
    else {
      setIsFormValid(
        isEmploymentTypeValid &&
        isMonthlyIncomeValid &&
        isExistingObligationEMIValid &&
        isLoanAmountValid &&
        isTenureValid &&
        isPurposeValid
      );
    }
  };


  return (
    <>
      {!showEligibilityTenureForm ? (
        <>
          <h6 className="text">Financial Details</h6>

          <form className="mt-4">
            <div className="row mb-2">
              <p className="small-heading mb-0">Employment Type</p>
              <div className="form-outline col-12 col-md-6">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="employmentType"
                    name="employmentType"
                    value={formData.employmentType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Salaried"
                      control={<Radio size="small" />}
                      label={
                        <span
                          className={formData.employmentType === "Salaried" ? "selected-label" : ""}
                        >
                          Salaried
                        </span>
                      }
                      labelPlacement="end"
                      disabled={isEmploymentDisabled}
                    />
                    <FormControlLabel
                      value="Self Employed"
                      control={<Radio size="small" />}
                      label={
                        <span
                          className={formData.employmentType === "Self Employed" ? "selected-label" : ""}
                        >
                          Self Employed
                        </span>
                      }
                      labelPlacement="end"
                      disabled={isEmploymentDisabled}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {(isSelfEmpSelected || formData.employmentSubType) && <div className="row">
              <div className="col-md-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="qualification-label">
                    Self Employment Type
                  </InputLabel>
                  <Select
                    labelId="qualification-label"
                    id="selfEmploymentType"
                    name="selfEmploymentType"
                    value={formData.selfEmploymentType}
                    onChange={(e) => {
                      if (e.target.value == 'Non Professional') {
                        setNonProfessionalSelected(true);
                        setFormData({
                          ...formData,
                          'selfEmploymentType': e.target.value,
                        });
                      } else {
                        setNonProfessionalSelected(false);
                        setFormData({
                          ...formData,
                          'selfEmploymentType': e.target.value,
                        });
                      }
                    }
                    }
                  >
                    {selfEmpTypeDropdown && selfEmpTypeDropdown.map((item) => {
                      return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                    })}
                  </Select>
                </ArthaFormControl>
              </div>
              {!isNonProfessionalSelected ? <div className="col-md-6">
                <ArthaFormControl fullWidth>
                  <InputLabel id="qualification-label">
                    Type of Profession
                  </InputLabel>
                  <Select
                    labelId="qualification-label"
                    id="employmentSubType"
                    name="employmentSubType"
                    value={formData.employmentSubType}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        'employmentSubType': e.target.value,
                      });
                      localStorage.setItem('employmentSubType', e.target.value);
                    }

                    }
                  >
                    {professionTypeDropdown && professionTypeDropdown.map((item) => {
                      return (<MenuItem key={item} value={item}>{item}</MenuItem>)
                    })}
                  </Select>
                </ArthaFormControl>
              </div> :
                <div className="col-md-6">
                  <span className="text-success">You are eligible only for Business Loan Click here to apply</span>
                </div>
              }
            </div>}

            <div className="row mt-3">
              <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="monthlyIncome"
                  label="₹ Monthly Income"
                  fullWidth
                  variant="outlined"
                  value={formData.monthlyIncome}
                  onChange={handleChange}
                />
                <p className="msg">As per Bank Statement</p>
              </div>
              <div className="col-md-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="existingObligationEMI"
                  label="₹ Existing Obligation EMI"
                  fullWidth

                  variant="outlined"
                  value={formData.existingObligationEMI}
                  onChange={handleChange}

                />
                {/* {isInvalidAmou && (
              <p style={{ color: "red", fontSize: "10px" }}>Invalid PAN</p>
            )} */}
                <p className="msg">Total amount per month</p>
              </div>
            </div>

            <h6 className="text mt-4">Loan Requirement</h6>
            <div className="row mt-4">
              <div className="col-md-6 ">
                <ArthaTextField
                  name="loanAmount"
                  label="₹ Loan Amount"
                  defaultValue=""
                  fullWidth
                  variant="outlined"
                  value={formData.loanAmount}
                  onChange={handleChange}
                />
                <p className="msg">Preferred Loan Amount</p>
              </div>
              <div className="col-md-6">
                <ArthaFormControl fullWidth>
                  <ArthaInputLabel id="tenure-label">Tenure</ArthaInputLabel>
                  <Select
                    labelId="tenure-label"
                    id="tenure"
                    name="tenure"
                    required
                    value={formData.tenure}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Tenure</MenuItem>
                    <MenuItem value={12}>12 Months</MenuItem>
                    <MenuItem value={24}>24 Months</MenuItem>
                    <MenuItem value={36}>36 Months</MenuItem>
                    <MenuItem value={48}>48 Months</MenuItem>
                    <MenuItem value={60}>60 Months</MenuItem>
                  </Select>
                  <p className="msg">Preferred Tenure</p>
                </ArthaFormControl>
              </div>
            </div>

            <div className="row mt-2">
      <div className="col-md-6">
        <ArthaFormControl fullWidth>
          <ArthaInputLabel id="qualification-label">Purpose</ArthaInputLabel>
          <Select
            labelId="qualification-label"
            label="Purpose"
            name="purpose"
            value={formData.purpose}
            onChange={(e) => {
              setFormData({
                ...formData,
                purpose: e.target.value,
                exactPurpose: e.target.value === 'Other' ? formData.exactPurpose : '',
              });
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              PaperProps: {
                className: 'custom-scrollbar', // Apply custom scrollbar class
                style: {
                  maxHeight: 120, // Adjust the max height to control the size of the dropdown
                },
              },
            }}
          >
            {purposeDropdown && purposeDropdown.map((item) => (
              <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </ArthaFormControl>
      </div>
      {formData.purpose === 'Other' && (
        <div className="col-md-6 mt-2">
          <ArthaFormControl fullWidth>
            <ArthaTextField
              label="Exact Purpose"
              name="exactPurpose"
              value={formData.exactPurpose}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  exactPurpose: e.target.value,
                });
              }}
            />
          </ArthaFormControl>
        </div>
      )}
    </div>

            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-end">
                <ArthaClearButton variant="contained"
                  onClick={handleBack}

                >
                  Back
                </ArthaClearButton>
              </div>

              <div className="col-6 d-flex justify-content-start">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  // Add style based on form validity
                  style={{
                    backgroundColor: isFormValid
                      ? "rgba(255, 115, 0, 1)" // Original color when form is valid
                      : "rgb(255 98 0 / 39%)", // Lower opacity when form is not valid
                    opacity: isFormValid
                      ? (isButtonClicked ? 0.39 : 1)
                      : 0.39,
                    pointerEvents: isFormValid
                      ? (isButtonClicked ? "none" : "auto")
                      : "none"
                  }}                  

                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <EligibilityTenureForm
          onContinue={onContinue}
          data={currentFormData}
          onCancel={() => {
            setShowEligibilityTenureForm(false);
            validateForm();
          }}
          ckycSuccess={ckycSuccess}
        />
      )
      }
    </>
  );
};

export default EligibilityCheckForm;
