import React from "react";
import ArthaContinueButton from "../../../fields/ArthaContinueButton";

import { useNavigate } from "react-router-dom";
const Congrats = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="inavlid-box  text-center     mx-auto col-8  text-center ">
          <h5 className="col-12 failed-heading">
            Congrats! You have accepted the loan offer
          </h5>
          <p className="failed-subheading">
            {/* Your application will passed to our partner and someone will contact
            for next step */}
            You will soon receive further communication
          </p>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-6 ">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn "
                onClick={handleClick}
              >
                Go to Home
              </ArthaContinueButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Congrats;
