import React from 'react';
import './dashboard.css'; // Assuming you have a corresponding CSS file for styling
import Logo from './public/logo.svg';
import  chev from './public/chev1-down.svg';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
const Header = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      // const userId = localStorage.getItem('user-id');
      // if(userId === null){
      //   console.error("No User ID found in localStorage");
      //   return;
      // }
      console.log("Preparation for signing out...");
      const res = await axios.get("/authn/sign-out");
      console.log("Signed out response:", res);
    } catch (err) {
     } finally {
      console.log("clearing local storage")
      localStorage.clear()
      caches.keys().then(names => {
        for (let name of names) caches.delete(name);
      });
      navigate("/");
    }
  };
  return (
      <div className="header">
        <div className="content">
          <div className="logo">
            <img
                className="hamburger-menu-icon"
                alt=""
                src="./public/hamburger-menu.svg"
            />
            <img className="logo-icon" alt="" src={Logo} />
          </div>
          <div className="profile-component">
            <div className="profile">
              <div className="profile-child"></div>
              {/*<div className="rs">RS</div>*/}
            </div>
            <button
                type="button"
                className="orange signout_btn"
                onClick={handleSignOut}
            >
              Sign Out
            </button>
            {/*<img className="profile" alt="" src={chev}/>*/}
          </div>
        </div>
      </div>
  );
};

export default Header;
