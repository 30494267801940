import React from "react";
import Header from "./Header";
import Drawer from "./Drawer";
import "./dashboard.css";

import { NavLink, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import BusinessLoan from "../Loan/BusinessLoan/BusinessLoan";
import PersonalLoan from "../Loan/PersonalLoan/PersonalLoan";
import ProcessFailed from "../Loan/PersonalLoan/MessagePages/ProcessFailed";
import SessionExpired from "../Loan/PersonalLoan/MessagePages/SessionExpired";
import PinNotServiceable from "../Loan/PersonalLoan/PinNotServiceable";
import SuccessLoanApplicationPage from "../Loan/PersonalLoan/MessagePages/SuccessLoanApplicationPage";
import CreditScoreOffMark from "../Loan/PersonalLoan/MessagePages/CreditScoreOffMark";
import CompletedLoanApplication from "../Loan/PersonalLoan/MessagePages/CompletedLoanApplication";
import Congrats from "../Loan/PersonalLoan/MessagePages/Congrats";
import LoanAgainstProperty from "../Loan/LoanAgainstProperty/LoanAgainstPropertyStepper";

const Sidebar = () => {
  return (
    <>
      <div className="main-header">
        <Header />
      </div>

      <div className="main-sidebar d-none d-md-block">
        <Drawer />
      </div>

      <div className="main-content">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/home" element={<Dashboard />} />
          <Route path="/businessloan" element={<BusinessLoan />} />
          <Route path="/personalloans" element={<PersonalLoan />} />
          <Route path="/loanagainstproperty" element={<LoanAgainstProperty/>} />
          <Route path="/failed" element={<ProcessFailed />} />
          <Route path="/sessionexpired" element={<SessionExpired />} />
          <Route path="/inserviceablepin" element={<PinNotServiceable />} />
          <Route
            path="/applicationsuccess"
            element={<SuccessLoanApplicationPage />}
          />
          <Route path="/congrats" element={<Congrats />} />
          <Route path="/creditscoreoffmark" element={<CreditScoreOffMark />} />
          <Route
            path="/completedapplication"
            element={<CompletedLoanApplication />}
          />
        </Routes>
      </div>
    </>
  );
};

export default Sidebar;
