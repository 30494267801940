import React, { useEffect, useState } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField.js";
import MandateSetup from "./MandateSetup.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import { toast } from 'react-toastify';
import { skipBankAccountVerification } from "../../actioncreators/actioncreators.js";

const BankAccountVerification = ({ onContinue, onBack, setActiveStep }) => {
  const [formData, setFormData] = useState({
    bankName: "",
    accountType: "",
    accountNumber: "",
    ifscCode: "",
  });

  const navigate = useNavigate();
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [showMandate, setShowMandate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
      const fetchData = async() => {
      try {
        const response = await axios.get("/personal-loan/account-details-get", {
          headers: {
            "x-apid": ap_id,
            "x-loanappid": loan_app_id,
          },
        });

        //         let response = {
        //     bankName: "HDFC BANK LTD",
        //     accountNumber: "45647876776",
        //     ifscCode: "ERTF6767887",
        //     accountType: "SAVINGS",
        //     bankId: "SomeBankID",
        //     httpResponseCode: 200,
        //     businessStatusCode: 2
        // }

        const { accountType, bankName, accountNumber, ifscCode, bankId } =
          response.data;

        setFormData((prevData) => ({
          ...prevData,
          bankName: bankName,
          accountType: accountType,
          accountNumber: accountNumber,
          ifscCode: ifscCode,
          bankId: bankId
        }));
      } catch (error) {
        if (error.response.status === 401) {
          await handleSignOut();
          toast.error("Your Session has expired.You will be redirected to Login Page.")
          navigate('/')
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, [ap_id, loan_app_id]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const skipAIS = async () => {
   
    try {
      const response = await skipBankAccountVerification(); 
      if ( response.data.businessStatusCode === 2) {
        onContinue();
        setActiveStep(11);
        setShowMandate(false); // Set to false to hide the current component
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired. You will be redirected to the Login Page.");
        navigate("/");
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error making GET request:", error);
      // Handle error if needed
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [ifscError, setIfscError] = useState(false); // State to manage IFSC code error
  const [ifscHelperText, setIfscHelperText] = useState(''); // State to manage IFSC code helper text

  const handleChange = (e) => {
    const { name, value } = e.target;


    let updatedValue = value;
    if (name === 'ifscCode') {
      updatedValue = value.toUpperCase();
      if (/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {

        setIfscError(false);
        setIfscHelperText('');
      } else {
        setIfscError(true);
        setIfscHelperText('Invalid IFSC code');
      }
    }
    setFormData((prevData) => ({ ...prevData, [name]: updatedValue }));
    validateForm();
  };


  const validateForm = () => {
    const { bankName, accountType, accountNumber, ifscCode } = formData;

    const isBankNameValid = bankName.trim() !== '';
    const isAccountTypeValid = accountType.trim() !== '';
    const isAccountNumberValid = accountNumber.trim() !== '';
    const isIFSCValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode);

    setIsFormValid(isBankNameValid && isAccountTypeValid && isAccountNumberValid && isIFSCValid);
  };

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor:
      isFormValid && isEmailValid
        ? "rgba(255, 115, 0, 1)"
        : "rgb(255 98 0 / 39%)",
    opacity: isButtonClicked ? 0.39 : 1,
    pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const clearButtonStyle = {
    color: "black",
    backgroundColor: isFormValid
      ? "rgba(255, 255, 255, 1)"
      : "rgb(255 255 255 / 39%)",
  };

  const handleContinue = async () => {
    // Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);
    // Define headers
    const headers = {
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };
    const data = {
      bankId: formData.bankId, // Access bankId from formData
      ifscCode: formData.ifscCode, // Access ifscCode from formData
    };
    try{
    const response = await axios.post("/personal-loan/bankaccount-verification-call", data, {
        headers: headers,
      });
        if (response.status === 200 && response.data.businessStatusCode === 2) {
          onContinue();
          setActiveStep(11);
          setShowMandate(false); // Set to false to hide the current component
        } else {
          toast.error(response.data.message);
        }
     
      }catch(error){
        if (error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired.You will be redirected to Login Page.")
          navigate('/')
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error fetching loan data:", error);
      }finally{
        // Reset loading state after the operation is complete
        setIsLoading(false);
        setIsButtonClicked(false);
      };
  };


  return (
    <>
      {!showMandate ? (
        <>
        <div className="d-flex">
          <h6 className="text">Bank Account Verification</h6>
          <div className="col-2 ms-auto ">
              <a
                onClick={skipAIS}
                style={{
                  color: "rgb(255, 115, 0)",
                  fontWeight: "bold",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Skip
              </a>
            </div>
            </div>
          <p style={{ fontSize: "14px" }} className="d-flex">
            Your below account will be authenticated before eNACH and
            disbursement is done.A small amount will be credited to vaildate
            this account.
          
          </p>
         
          <form className="mt-4">
            <div className="row mt-4 mb-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  label="Bank Name"
                  fullWidth
                  variant="outlined"
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                  className="form-control"
                />

                <div className="subtitle"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="accountType"
                  value={formData.accountType}
                  onChange={handleChange}
                  label="Account Type"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="email"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  label="Account Number"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="ifscCode"
                  type="text"
                  value={formData.ifscCode}
                  onChange={handleChange}
                  label="IFSC Code"
                  id="pin-code-inpt"
                  fullWidth
                  error={ifscError} // Pass error state to TextField component
                  helperText={ifscHelperText} // Pass helper text to TextField component
                />
              </div>
            </div>

            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                {/* <ArthaClearButton variant="contained">Cancel</ArthaClearButton> */}
              </div>

              <div className="col-6 d-flex justify-content-start mt-4">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  style={buttonStyle}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <MandateSetup
          onContinue={onContinue}
          onCancel={() => setShowMandate(true)}
        />
      )}
    </>
  );
};

export default BankAccountVerification;
