import React from "react";
import ArthaContinueButton from "../../../fields/ArthaContinueButton";
import Alert from '../images/Alert_3.png';
import { useNavigate } from "react-router-dom";

const ProcessFailed = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="row">
        <div className="inavlid-box mx-auto col-8 text-center ">
          <img src={Alert} style={{ width: "15%", justifyContent: 'center', marginTop: "7%" }} />
          <h5 className="col-12  mt-4">
            Oops! We are unable to process your request further
          </h5>
          <p className="failed-subheading">
            Sorry, we cannot proceed further with your current application due
            to lending policies
          </p>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-6 ">
              <ArthaContinueButton type="button" className="orange custom_btn" onClick={handleClick}>
                Go To Home
              </ArthaContinueButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default ProcessFailed;
