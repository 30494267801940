import React, { useState, useRef, useEffect } from "react";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { MdOutlineFileDownload } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import {
  FaIdBadge,
  FaCheck,
  FaExclamationTriangle,
  FaExclamationCircle,
} from "react-icons/fa";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Vkyc from "./vKYC.jsx";
import { handleSignOut } from "./SessionExpiredApi";
import { businessgetDocumentDataByDocumentIdAPI, businesssanctionLetterMitcInfoAPI,businesssanctionLetterContinueAPI } from "../../actioncreators/actioncreators.js";
import { toast } from "react-toastify";



const SanctionLetter = ( { onBack, onContinue, setActiveStep } ) => {
  const fileInputRefAIS1 = useRef( null );
  const [ isAgreed, setIsAgreed ] = useState( false );

  const fileInputRefAIS2 = useRef( null );
  const navigate = useNavigate();
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [ stateResponse, setStateResponse ] = useState();
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  //GET API TO FETCH SANCTION LETTER DETAILS START -------------------------------------------------------------
  useEffect( () => {
    const fetchData = async () => {
    try {
      const response = await businesssanctionLetterMitcInfoAPI(); 

        console.log( "API Response:", response );

        if ( response ) {
          setStateResponse( response.data );
        }
    } catch ( error ) {
      handleApiError(error);
      console.error( "Error fetching user data:", error );
    }
  };
  fetchData();
  }, [ ap_id, loan_app_id ] );


  // const handleBack = () => {
  //   onBack();
  // };

  const handleAgreeChange = () => {
    setIsAgreed( !isAgreed );
  };

  const handleDownloadButton = async ( documentId ) => {
    try {
      const payload = {
        documentId: documentId,
      };

      const documentresponse = await businessgetDocumentDataByDocumentIdAPI( payload );
        if (
          documentresponse.status === 200 &&
          documentresponse.data.businessStatusCode === 2
        ) {

          const base64Data = documentresponse.data.documentBase64;
          const byteCharacters = atob( base64Data );
          const byteNumbers = new Array( byteCharacters.length );
          for ( let i = 0; i < byteCharacters.length; i++ ) {
            byteNumbers[ i ] = byteCharacters.charCodeAt( i );
          }
          const byteArray = new Uint8Array( byteNumbers );

          const blob = new Blob( [ byteArray ], { type: "application/pdf" } );

          const link = document.createElement( "a" );
          link.href = window.URL.createObjectURL( blob );
          link.download = documentresponse.data.documentName;
          document.body.appendChild( link );
          link.click();
          document.body.removeChild( link );
        } else {
          console.error( "Error downloading document:", documentresponse );
        }
    } catch ( error ) {
      handleApiError(error);
      console.error( "Error downloading document:", error );
    }
  };
 
  const handleDownloadButtonBusiness = async (documentId) => {
    await handleDownloadButton(documentId);
  }; 

  const [ showVKYC, setShowVKYC ] = useState( false );

  const handleContinue = () => {
    const fetchData = async () => {
      try {
        const storeResponse = await businesssanctionLetterContinueAPI();
  
        if (storeResponse?.status === 200) {
          setActiveStep(8);
        }
      } catch (error) {
        handleApiError(error);
      }
    };
    
    fetchData();
  };
  
  return (
    <>
      {!showVKYC ? (
        <>
          <div className="row">
            <div className="col-6 mt-3 ">
              <h6>Sanction Letter and Other Documents</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-11 mt-2">
              <p className="small-heading kyc-form-text-size">
                Your 'Sanction Letter' and 'Most important Terms and
                Conditions'documents are sent to your registered email and
                mobile number.These documents can also be downloaded from here.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-md-9 mt-0 mt-md-4 ">
              <p className="msg">Sanction Letter </p>

              <>
                {/* <FaExclamationCircle className="badge-icon" /> */}
                <CgFileDocument
                  className="badge-icon1"
                  style={{ fontSize: "18px" }}
                />
                <span
                  className="mt-2 upload-lable"
                  style={{
                    position: "relative",
                    top: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {stateResponse?.sanctionLetterDocumentName}
                </span>
              </>
            </div>
            <div className="col-4 col-md-2 mt-0 mt-md-4">
              <input
                type="file"
                ref={fileInputRefAIS1}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={() =>
                  handleDownloadButtonBusiness( stateResponse?.sanctionLetterDocumentId )
                }
              >
                <MdOutlineFileDownload style={{ fontSize: "20px" }} /> Download
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-8 col-md-9 mt-3 mt-md-4 ">
              <p className="msg">Terms & conditions</p>

              <>
                {/* <FaExclamationCircle className="badge-icon" /> */}
                <CgFileDocument
                  className="badge-icon1"
                  style={{ fontSize: "18px" }}
                />
                <span
                  className="mt-2 upload-lable"
                  style={{
                    position: "relative",
                    top: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {stateResponse?.mitcDocumentName}
                </span>
              </>
            </div>
            <div className="col-4 col-md-2 mt-4">
              <input
                type="file"
                ref={fileInputRefAIS2}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={() =>
                  handleDownloadButtonBusiness( stateResponse?.mitcDocumentId )
                }
              >
                <MdOutlineFileDownload style={{ fontSize: "20px" }} /> Download
              </Button>
            </div>
            <div className="col-12 col-md-12 mt-3 checkbox">
              <input
                type="checkbox"
                id="agree"
                checked={isAgreed}
                onChange={handleAgreeChange}
              />
              <label htmlFor="agree">
                I accept the terms of the above sanction letter
              </label>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-8 d-flex justify-content-end">
              {/* <ArthaClearButton
                type="button"
                className="custom_btn"
                onClick={handleBack}
              >
                Back
              </ArthaClearButton> */}
            </div>
            <div className="col-3 d-flex justify-content-start">
              <ArthaContinueButton
                type="button"
                className={`get-opt-sbmt ${!isAgreed && "disabled"}`}
                style={{ opacity: !isAgreed ? 0.39 : 1 }}
                onClick={handleContinue}
                disabled={!isAgreed}
                // disabled
              >
                Continue
              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : (
        <Vkyc
          setActiveStep={setActiveStep}
        />
      )
      }

    </>
  );
};

export default SanctionLetter;