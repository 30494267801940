

import React, { useState, useRef, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";

const UploadSupportiveDocs = ({ open, handleClose, popupLable, existingDocuments, onRemove }) => {
    const [files, setFiles] = useState([]);
    const [passwords, setPasswords] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [isDocumentTypeSelected, setIsDocumentTypeSelected] = useState(false);
    const [documentType, setDocumentType] = useState("");
    const [documentTypes, setDocumentTypes] = useState([]);

    const hiddenFileInput = useRef(null);

    const handleFileChange = (e) => {

        setRenderCount(renderCount + 1)
        const file = e.target.files[0];
        if (file) {
            setFiles((prevFiles) => [...prevFiles, file]);
            setPasswords((prevPasswords) => [...prevPasswords, ""]);
            setDocumentTypes((prevDocumentTypes) => [...prevDocumentTypes, ""]);
            setCurrentFile(file);
        }
    };

    const handleDeleteFile = (index) => {
        setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });

        setPasswords((prevPasswords) => {
            const updatedPasswords = [...prevPasswords];
            updatedPasswords.splice(index, 1);
            return updatedPasswords;
        });

        setCurrentFile(null);
    };

    const handlePasswordChange = (index, value) => {
        setPasswords((prevPasswords) => {
            const updatedPasswords = [...prevPasswords];
            updatedPasswords[index] = value;
            return updatedPasswords;
        });
    };

    const handleAddFile = () => {
        setCurrentFile(null);
        setIsDocumentTypeSelected(false);
        hiddenFileInput.current && hiddenFileInput.current.click();
    };

    const handleDocumentTypeChange = (index, value) => {
        setDocumentTypes((prevDocumentTypes) => {
            const updatedDocumentTypes = [...prevDocumentTypes];
            updatedDocumentTypes[index] = value;
            return updatedDocumentTypes;
        });
        setIsDocumentTypeSelected(!!value);
    };

    function fileToBase64(file, callback) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const base64String = event.target.result.split(',')[1]; // Extracting the base64 data part
            callback(base64String);
        };

        reader.onerror = function (error) {
            console.error('Error reading the file:', error);
        };

        reader.readAsDataURL(file);
    }

    const handleSave = () => {
        const promises = files.map((file, index) => {
            return new Promise((resolve) => {
                fileToBase64(file, (base64Data) => {
                    resolve({

                        documentType: documentTypes[index] || "",
                        documentName: file.name, // Uncomment if needed
                        documentData: base64Data,
                        password: passwords[index] || ""
                    });
                });
            });
        });

        Promise.all(promises).then((documentsInfo) => {
            handleClose(documentsInfo);
            setFiles([]);
            setPasswords([]);
            setCurrentFile(null);
            setIsDocumentTypeSelected(false);
        });

    };
    const [isContinueEnabled, setIsContinueEnabled] = useState(false);
    useEffect(() => {
        // Check if all files have a document type and password
        const allFilesHaveInfo = files.every(
            (file, index) => documentTypes[index]
        );
        setIsContinueEnabled(allFilesHaveInfo);
    }, [files, documentTypes]);
    const renderButtons = (index) => {
        if (currentFile) {
            return (
                <>

                    <div className="row ">
                        <div className="uploadadhar mt-4 row">
                            <div className="col-md-8 mt-3 mt-md-4 mx-auto ">
                                <ArthaFormControl fullWidth style={{ marginLeft: "15px" }}>
                                    <InputLabel id="documentType-label">Type of Document</InputLabel>
                                    <Select
                                        labelId={`documentType-label-${index}`}
                                        id={`documentType-${index}`}
                                        name={`documentType-${index}`}
                                        value={documentTypes[index] || ""}
                                        onChange={(e) => handleDocumentTypeChange(index, e.target.value)}
                                    >
                                        <MenuItem value="Shop Act Licence">Shop Act Licence</MenuItem>
                                        <MenuItem value="Photo of Firm with Business Name">Photo of Firm with Business Name</MenuItem>
                                        <MenuItem value="Photo of Firm (Inside Premises)">Photo of Firm (Inside Premises)</MenuItem>
                                        <MenuItem value="Business Address Prrof">Business Address Proof</MenuItem>
                                    </Select>
                                </ArthaFormControl>
                            </div>
                            <div className="col-8">
                                <span>
                                    <label
                                        htmlFor="fileInput"
                                        className="upload-label d-flex justify-content-center"
                                    >
                                        <span className="mt-1">+Add File</span>
                                    </label>
                                    <input
                                        ref={hiddenFileInput}
                                        type="file"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                        onClick={(e) => (e.target.value = null)}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="row mt-4 ">
                            <div className="col-6 d-flex justify-content-end">
                                <ArthaClearButton
                                    type="button"
                                    className="custom_btn"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </ArthaClearButton>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <ArthaContinueButton
                                    type="button"
                                    className="orange custom_btn"
                                    onClick={handleSave}
                                    disabled={!isContinueEnabled}
                                >
                                    Continue
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return null;
        }
    };
    const [renderCount, setRenderCount] = React.useState(0);
    return (
        <Dialog open={open} onClose={() => handleClose([])} maxWidth="lg">
            <DialogContent>
                <div className="row ">
                    <div className="col-md-8 mt-3 mt-md-4 mx-auto ">
                        {existingDocuments && existingDocuments.map(doc => {
                            return (
                                <>
                                    <div className="row">
                                        <div className="col-10"> <p>{doc.documentType}</p></div>
                                        <div className="col-2"> <img
                                            src={Cancel}
                                            style={{ width: "20px", justifyContent: "center" }}
                                            alt="Cancel"
                                            onClick={() => { onRemove(doc.documentType) }}
                                        /></div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 mt-3 mt-md-4 mx-auto">
                        <ArthaFormControl fullWidth>
                            <InputLabel id="documentType-label">Type of Document</InputLabel>
                            <Select
                                labelId={`documentType-label-`}
                                id={`documentType-`}
                                name={`documentType-`}
                                value={documentTypes[0] || ""}
                                onChange={(e) => handleDocumentTypeChange(0, e.target.value)}
                            >
                                 <MenuItem value="Shop Act Licence">Shop Act Licence</MenuItem>
                                 <MenuItem value="Photo of Firm with Business Name">Photo of Firm with Business Name</MenuItem>
                                 <MenuItem value="Photo of Firm (Inside Premises)">Photo of Firm (Inside Premises)</MenuItem>
                                 <MenuItem value="Business Address Prrof">Business Address Proof</MenuItem>
                            </Select>
                        </ArthaFormControl>
                    </div>
                </div>



                {files.map((file, index) => (
                    <div key={index} className="uploadadhar mt-4 row">
                        <div className="uploaded-image-container col-8">
                            {index !== 0 && (
                                <div className="col-md-12 mt-3 mt-md-4 mx-auto">
                                    <ArthaFormControl fullWidth>
                                        <InputLabel id={`documentType-label-${index}`}>Type of Document</InputLabel>
                                        <Select
                                            labelId={`documentType-label-${index}`}
                                            id={`documentType-${index}`}
                                            name={`documentType-${index}`}
                                            value={documentTypes[index] || ""}
                                            onChange={(e) => handleDocumentTypeChange(index, e.target.value)}
                                        >
                                        <MenuItem value="Shop Act Licence">Shop Act Licence</MenuItem>
                                        <MenuItem value="Photo of Firm with Business Name">Photo of Firm with Business Name</MenuItem>
                                        <MenuItem value="Photo of Firm (Inside Premises)">Photo of Firm (Inside Premises)</MenuItem>
                                        <MenuItem value="Business Address Prrof">Business Address Proof</MenuItem>
                                        </Select>
                                    </ArthaFormControl>
                                </div>
                            )}
                            {/* <p className="doc-label">{`Document ${index + 1}`}</p> */}
                            <div className="col-12 d-flex mt-2">
                                <span className="" style={{ marginRight: "10px" }}>
                                    {file.name}
                                </span>
                                <span
                                    className=""
                                    onClick={() => handleDeleteFile(index)}
                                    title="Delete"
                                    style={{ width: "50px" }}
                                >
                                    <img src={Cancel} alt="Cancel" />
                                </span>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-8 mx-auto">
                                <TextField
                                    type="password"
                                    label={`Enter password for Document ${index + 1}`}
                                    value={passwords[index]}
                                    onChange={(e) => handlePasswordChange(index, e.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                />
                            </div>
                        </div>
                    </div>
                ))}



                {!currentFile && (
                    <>
                        <div className="uploadadhar mt-4 row ">
                            <span>
                                <label
                                    htmlFor="fileInput"
                                    className="upload-label d-flex justify-content-center"
                                >
                                    <span className="upload-icon">
                                        <MdOutlineFileUpload />
                                    </span>
                                    <span className="mt-1">Upload</span>
                                </label>
                                <input
                                    ref={hiddenFileInput}
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    onClick={(e) => (e.target.value = null)}
                                />
                            </span>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 d-flex justify-content-center">
                                <ArthaClearButton
                                    type="button"
                                    onClick={() => {
                                        setFiles([]);
                                        setPasswords([]);
                                        setCurrentFile(null);
                                        setIsDocumentTypeSelected(false);
                                        handleClose([]);
                                    }}
                                >
                                    Cancel
                                </ArthaClearButton>
                            </div>
                        </div>
                    </>
                )}

                {renderButtons(renderCount)}
            </DialogContent>
        </Dialog>
    );
};

export default UploadSupportiveDocs;
