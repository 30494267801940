import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const handleSignOut = async () => {
  const url = "/authn/sign-out";
  try {
    const userId = localStorage.getItem('user-id');
    if (userId === null) {
      console.error("No User ID found in localStorage");
      return;
    }
    console.log("Preparation for signing out...");

    // Use axios instance or create one if needed
    const response = await axios.post(url, { customerId: userId });
    console.log("Signed out response:", response);

    // Check if the response status is successful
    if (!response.status === 200) {
      throw new Error(response.data.message || "Sign-out failed");
    }
  } catch (error) {
    console.error("Error during sign out:", error.message);
    // Optionally, show a toast notification for better user experience
    toast.error("Failed to sign out.");
  } finally {
    // Ensure local storage is cleared even if an error occurs
    localStorage.clear();
    caches.keys().then(names => {
      for (let name of names) caches.delete(name);
    });
  }
};

export { handleSignOut };
