import React, { useEffect, useState, useRef } from "react";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";
import {
  propertyLoanFirstFeePaymentAPI,propertyLoanSendPaymentLinkAPI
} from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";
export default function FirstFeePayment({
  onContinue,
  ckycSuccess,
  onBack,setActiveStep,changeStatus
}) {
  const navigate = useNavigate();
  // Default values or values from location.state
  const location = useLocation();
  const { tentativeEmi, loanAmount, loanTenure } = location.state || {};

  const [loanAmount1, setLoanAmount] = useState(loanAmount);
  const [loanTenure1, setTenure] = useState(loanTenure);
  const [fee, setFee] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);

  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  const handleSendLinkClick = async () => {
    onContinue();
    changeStatus("APPLICANT_DETAILS")
    setIsModalOpen(true);
    setIsButtonClicked(true);
    setIsLoading(true);

    const data = {
      fee_stage: "Initial",
      fee_type: "Login"
  }

    try {
      const response = await propertyLoanSendPaymentLinkAPI(data);
        if ( response.businessStatusSubCode === 2) {
          setIsModalOpen(true);
        } else {
          setIsModalOpen(false);
        } 
      } 
     catch (error) {
      handleApiError(error);
      console.error("Error fetching loan data:", error);
    } finally {
      setIsLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    onBack();
  };

  const handleContinue = () => {
   navigate('/home')
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        fee_stage: "Initial",
        fee_type: "Login",
      };
  
      try {
        const response = await propertyLoanFirstFeePaymentAPI(payload);
        setFee(response.feeAmount);
      } catch (error) {
        handleApiError(error);
        console.error('Error fetching fee data:', error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseModal();
          }
        }}
        style={{ textAlign: "center" }}
      >
        <Button
          onClick={handleCloseModal}
          style={{
            color: "black",
            background: "white",
            fontWeight: "bold",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          X
        </Button>
        <DialogContent>
          <Typography className="fw-bold mt-3 p-2" style={{ color: "green" }}>
            Fee-payment link is successfully sent to your <br /> registered
            email Id / mobile number.
          </Typography>
        </DialogContent>
      </Dialog>

      <>
        <div className="row mt-4">
          <div className="col-12 col-md-2"></div>
          <div className="border p-4 rounded-0 col-12 col-md-8">
            <h6 className="fw-bold">
              Thank you for showing interest in our loan products.
            </h6>
            <h6 className=" fw-bold d-flex" style={{ marginTop: "30px" }}>
              First installment of your Login-Fees is ₹{" "}
              <p className="fs-4 "> {fee?.toLocaleString("en-IN")}.00</p>
            </h6>
            <h6 className="fw-bold">
              Please click on 'Send Link' to send a fee-payment link to your
              registered email Id / mobile number.
            </h6>
            <div className="float-end ">
              <ArthaContinueButton
                type="button"
                onClick={handleSendLinkClick}
                style={{
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto",
                  fontSize: "12px",
                  width: "120px",
                  height: "40px",
                  marginBottom: "30px",
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: "none" }} />
                ) : (
                  "Send Link"
                )}
              </ArthaContinueButton>
            </div>

            <div className="row mt-5">
              <div className="col-7 d-flex justify-content-end mt-5">
                <ArthaClearButton type="button" onClick={handleBack}>
                  Back
                </ArthaClearButton>
              </div>
              <div className="col-5 justify-content-start mt-5">
                <ArthaContinueButton
                  type="button"
                  onClick={handleContinue}
                  style={{
                    opacity: isButtonClicked ? 0.39 : 1,
                    pointerEvents: isButtonClicked ? "none" : "auto",
                  }}
                >
                  {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: "none" }} />
                  ) : (
                    "Go To Home"
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2"></div>
        </div>
      </>
    </>
  );
}
