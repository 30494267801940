

import React, { useState, useRef, useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";

const UploadSingleDocument = ({ open, handleClose, onSave, onRemove, existingDocument, documentTypeDropdowns }) => {

    const [selectedDocumentType, setSelectedDocumentType] = useState();
    const [selectedFile, setSelectedFile] = React.useState('');

    const hiddenFileInput = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    function fileToBase64(file, callback) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const base64String = event.target.result.split(',')[1];
            callback(base64String);
        };

        reader.onerror = function (error) {
            console.error('Error reading the file:', error);
        };

        reader.readAsDataURL(file);
    }

    const handleSave = () => {
        handleClose(); setSelectedFile(''); setSelectedDocumentType();
        console.log(selectedFile.name);
        if (selectedFile) {
            fileToBase64(selectedFile, (base64Data) => {
                const documentInfo = {
                    documentType: selectedDocumentType || "",
                    documentData: base64Data,
                    documentName: selectedFile.name
                };
                onSave(documentInfo);
            });

        }
    };




    return (
        <Dialog open={open} onClose={() => { handleClose(); setSelectedFile(''); setSelectedDocumentType(); }} maxWidth="lg">
            <DialogContent>
                {existingDocument && existingDocument.documentType &&
                    <div className="row bg-light">
                        <div className="col-10">
                            <p style={{ paddingTop: '5px', paddingBottom: '5px' }}>{existingDocument.documentType}</p>
                        </div>
                        <div className="col-2">
                            <img
                                src={Cancel}
                                style={{ width: "20px", justifyContent: "center" }}
                                alt="Cancel"
                                onClick={() => { onRemove() }}
                            />
                        </div>
                    </div>

                }
                {selectedFile &&
                    <div className="row bg-light">
                        <div className="col-10">
                            <p style={{ paddingTop: '5px', paddingBottom: '5px' }}>{selectedDocumentType}</p>
                        </div>
                    </div>

                }
                <div className="row">
                    <div className="col-md-8 mt-3 mt-md-4 mx-auto">
                        <ArthaFormControl fullWidth>
                            <InputLabel id="documentType-label">Type of Document</InputLabel>
                            <Select
                                labelId={`documentType-label-`}
                                id={`documentType-`}
                                name={`documentType-`}
                                value={selectedDocumentType || ""}
                                onChange={(e) => setSelectedDocumentType(e.target.value)}
                            >
                                {documentTypeDropdowns && documentTypeDropdowns.map((item) => {
                                    return (<MenuItem value={item}>{item}</MenuItem>)
                                })}
                            </Select>
                        </ArthaFormControl>
                    </div>

                    <>
                        <div className="uploadadhar mt-4 row ">
                            <span>
                                <label
                                    htmlFor="fileInput"
                                    className="upload-label d-flex justify-content-center"
                                >
                                    <span className="upload-icon">
                                        <MdOutlineFileUpload disabled={!selectedDocumentType} />
                                    </span>
                                    <span className="mt-1" disabled={!selectedDocumentType}>Upload</span>
                                </label>
                                <input
                                    ref={hiddenFileInput}
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    disabled={!selectedDocumentType}
                                    onClick={(e) => (e.target.value = null)}
                                />
                            </span>
                        </div>

                        <div className="row mt-4 ">
                            <div className="col-6 d-flex justify-content-end">
                                <ArthaClearButton
                                    type="button"
                                    className="custom_btn"
                                    onClick={() => {
                                        handleClose(); setSelectedFile(''); setSelectedDocumentType();
                                    }}
                                >
                                    Cancel
                                </ArthaClearButton>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <ArthaContinueButton
                                    type="button"
                                    className="orange custom_btn"
                                    onClick={handleSave}
                                    disabled={!selectedFile}
                                >
                                    Continue
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default UploadSingleDocument;
