/**
 * Validates if data is present in all the mandatory object keys.
 * 
 * @param {Object} obj - The object to be validated.
 * @param {Array<string>} [optionalKeys=[]] - An array of keys that are optional and should be excluded from validation.
 * 
 * @returns {boolean} - Returns true if all mandatory keys have non-null, non-empty, and non-undefined values, otherwise false.
 * 
 * @example
 * // Define an object with some keys
 * const obj = {
 *     'gstNumber': '',
 *     'udyamAadhaar': '',
 *     'shopActNumber': '',
 *     'firmName': '',
 *     'firmVintage': '',
 *     'numberOfEmployeesInFirm': '',
 *     'yearsInCurrentProfession': '',
 *     'workfrom': null,
 *     'incorporationMonth': null,
 *     'firmPremisesOwner': '',
 *     'natureOfBusiness': '',
 *     'officialEmail': '',
 * };
 * 
 * // Define optional keys
 * const optionalKeys = ['gstNumber', 'udyamAadhaar', 'shopActNumber'];
 * 
 * // Validate the object
 * const isValid = validateObjectKeys(obj, optionalKeys);
 * console.log(isValid); // Output: false (based on the validation)
 */

export const validateObjectKeys = (obj, optionalKeys = []) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && !optionalKeys.includes(key)) {
            // Check if the value is null, an empty string, or undefined
            if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
                return false;
            }
        }
    }
    return true;
}