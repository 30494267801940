import React from "react";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import ArthaClearButton from "../../fields/ArthaClearButton";
const PinNotServiceable = () => {
  return (
    <>
      <div className="row">
        <div className="inavlid-box   mx-auto col-8  text-center ">
          <div className="row mt-5 p-5">
            <div className="col-12">
              <LocationOffIcon className="pin-location-icon" />
            </div>
          </div>
          <h5 className="col-12  pin-failed-heading">
            Oops! We are unable to process your request further
          </h5>
          <p className="failed-subheading">
            Sorry we cannot proceed further with your current application due to
            lending policies
          </p>
          <div className="row mt-4 mx-auto">
            <div className="col-6 d-flex justify-content-end mt-4">
              <ArthaClearButton variant="contained">
                Change Pincode
              </ArthaClearButton>
            </div>

            <div className="col-6 d-flex justify-content-start mt-4">
              <ArthaContinueButton variant="contained">
                Go to Home
              </ArthaContinueButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PinNotServiceable;
