import React from 'react';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import ArthaContinueButton from '../../../fields/ArthaContinueButton';
import ArthaClearButton from '../../../fields/ArthaClearButton';
import { useNavigate } from "react-router-dom";
import { useEffect} from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
const SessionExpired = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignOut = async () => {
      try {
    
        const res = await axios.get("/authn/sign-out");
        console.log("Signed out response:", res);
        navigate('/')
      } catch (err) {
        if ( err.response.status === 401 ) {
          handleSignOut();
          toast.error( "Your Session has expired.You will be redirected to Login Page." )
          navigate( '/' )
        }else if (err.response && err.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }
        console.error("Error during sign out:", err.message);   // Handle error here
      }
    };

    handleSignOut();
  }, []);

    // const handleClick = () => {
    //     navigate('/')
        
    //   };
    return (
        <div>
            {/* {props.isOpen && (
                <div className="modal row">
                    <div className="  col-12 col-md-4 " style={{backgroundColor:"white",borderRadius:"5px"}}>
                        
                        <div className="row ">
                            <div className=" text-center py-2 " style={{ background: "rgb(255, 115, 0)",
  color: "white",borderRadius:"5px"}}>
                                <h5 className='text-start mt-2'>Session Expired.</h5>
                            </div>
                            <p className='text-center mt-5'>Your Session has expired.</p><br />
                            <p className='text-center'>You will be redirected to Login Page.</p>
                        </div>
                        <div className="row mt-2 mx-auto">
                            

                            <div className="col-12 text-center my-3">
                                <ArthaContinueButton
                                    variant="contained"
                                    onClick={handleClick}
                                >
                                   LOGIN AGAIN
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default SessionExpired;
