import React, { useState } from "react";
import { MdOutlineFileUpload, MdDelete } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArthaFormControl from "../../fields/ArthaFormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaClearButton from "../../fields/ArthaClearButton";
import Cancel from "./images/Cancel_1.png";
const ManualKYCAddressVerification = ({ open, handleClose, popupLable, handleSaveFileName }) => {
    const [addressType, setAddressType] = useState("");
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
        useState(true);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadedImage(URL.createObjectURL(file));
            setIsContinueButtonDisabled(false);
        }
    };

    const handleDeleteImage = () => {
        setUploadedImage(null);
        setIsContinueButtonDisabled(true);
    };

    const handleSave = () => {
        handleClose(false);
        setUploadedImage(null);
        handleSaveFileName(selectedFile ? selectedFile.name : null);
    };

    //Add new file
    const [selectedFile, setSelectedFile] = useState(null);

    const handleUploadFileChange = (e) => {
        setIsContinueButtonDisabled(false);
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleDeleteFile = () => {
        setSelectedFile(null);
        setIsContinueButtonDisabled(true);
    };

    const handleCancel = () => {
        // Reset values and close the popup
        setSelectedFile(null);
        setUploadedImage(null);
        setAddressType("");
        setIsContinueButtonDisabled(true);
        handleClose(true);
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel(); // Call handleCancel when closing the popup
                }}
                maxWidth="lg"
            >
                <DialogContent>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 text-center">
                            Upload {popupLable} Address Proof
                        </div>
                    </div>
                    <div className="col-md-8 mt-3 mt-md-4 mx-auto">
                        <ArthaFormControl fullWidth>
                            <InputLabel id="addressType-label">Type of Proof</InputLabel>
                            <Select
                                labelId="addressType-label"
                                id="addressType"
                                name="addressType"
                                value={addressType}
                                onChange={(e) => setAddressType(e.target.value)}
                            >
                                <MenuItem value="">Type of Proof</MenuItem>
                                <MenuItem value="adharCard">Adhar Card</MenuItem>
                                <MenuItem value="panCard">Pan Card</MenuItem>
                                <MenuItem value="electricityBill">Electricity Bill</MenuItem>
                            </Select>
                        </ArthaFormControl>
                    </div>
                    <div className="uploadadhar mt-4 row ">
                        {selectedFile && (
                            <div className="uploaded-image-container col-8 ">
                                <p className="doc-label">Document 1</p>
                                <div className="col-12">
                                    <span className="" style={{ marginRight: "30px" }}>
                                        {selectedFile.name}
                                    </span>
                                    <span
                                        className=""
                                        onClick={handleDeleteFile}
                                        title="Delete"
                                        style={{ width: "50px" }}
                                    >
                                        <img src={Cancel} />
                                    </span>
                                </div>
                            </div>
                        )}

                        {!selectedFile && (
                            <span>
                                <label
                                    htmlFor="fileInput"
                                    className="upload-label d-flex justify-content-center"
                                >
                                    <span className="upload-icon">
                                        <MdOutlineFileUpload />
                                    </span>
                                    <span className="mt-1">Add File</span>
                                </label>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleUploadFileChange}
                                />
                            </span>
                        )}
                    </div>

                    <div className="row mt-4 ">
                        <div className="col-6 d-flex justify-content-end">
                            <ArthaClearButton
                                type="button"
                                className="custom_btn"
                                onClick={handleCancel}
                            >
                                Cancel
                            </ArthaClearButton>
                        </div>

                        <div className="col-6 d-flex justify-content-start">
                            <ArthaContinueButton
                                type="button"
                                className="orange custom_btn"
                                onClick={handleSave}
                                disabled={isContinueButtonDisabled || !addressType}
                            >
                                Continue
                            </ArthaContinueButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ManualKYCAddressVerification;
