import React, { useState, useEffect } from 'react';
import ArthaTextField from '../../fields/ArthaTextField';
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import { Button } from '@mui/material';
import ArthaClearButton from '../../fields/ArthaClearButton';
import ArthaContinueButton from '../../fields/ArthaContinueButton';
import "./personalLoan.css";
import axios from "axios";
import { storeItrAPI,fetchItrDetailsAPI } from '../../actioncreators/actioncreators';
import BankStatement from './BankStatement';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";

const ITrDetails = ({ onBack,changeStatus,onContinue,setItrFlagDetails,setActiveStep }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [ showBankStatement, setShowBankStatement ] = useState( false );
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        file1: '',
        file2: '',
        fileName1: '',
        fileName2: ''
    });
    const navigate = useNavigate();
    const [res, setRes] = useState(
       
);
   

const fetchItrReport = async () => {
    try {
        const response2 = await fetchItrDetailsAPI();
        setRes(response2.data);
        if (response2.data?.itrFiles?.[0]?.fileName) {
            setIsITRUploaded(true);
        }
        setFormData({
            username: response2.data?.username,
            password: '',
            file1: '',
            file2: '',
            fileName1: response2.data?.itrFiles?.[0]?.fileName,
            fileName2: response2.data?.itrFiles?.[1]?.fileName,
        });
    } catch (error) {
        if ( error.response.status === 401 ) {
            handleSignOut();
            toast.error( "Your Session has expired.You will be redirected to Login Page." )
            navigate( '/' )
       }else if (error.response && error.response.status === 429) {
            toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
          }else {
            toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
          }
        console.error("Error fetching ITR report:", error);
    }
}

useEffect(() => {
    fetchItrReport();
}, []);


    useEffect(() => {
        setFormData({
            ...formData,
            username: res?.username || '',
        });
    }, [res]);

    const [isFormValid, setIsFormValid] = useState(false);
    const [panProvided, setPanProvided] = useState(false);
    const [isITRUploaded, setIsITRUploaded] = useState(false);
    const [isITRUploaded2, setIsITRUploaded2] = useState(false);

    const handleFileUpload = (event) => {  
        const file = event.target.files[0];
        if (file) {
            setIsITRUploaded(true);

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setFormData({
                    ...formData,
                    file1: base64String,
                    fileName1: file.name,
                });
            };

            reader.readAsDataURL(file);
        }
    };

    const handleFileUpload2 = (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsITRUploaded2(true);

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                setFormData({
                    ...formData,
                    file2: base64String,
                    fileName2: file.name,
                });
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if ((formData.username !== "" && formData.password !== "") || (formData.file1 !== "" && formData.file2 !== "")) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
        if (formData.username !== "" && formData.password !== "") {
            setPanProvided(true);
        } else {
            setPanProvided(false);
        }
    }, [formData]);

    const handleContinue = async () => {
        setIsButtonClicked(true);
        setIsLoading(true);

        const payload = {
            itrFiles: [
                {
                    fileData: formData.file1,
                    fileName: formData.fileName1
                },
                {
                    fileData: formData.file2,
                    fileName: formData.fileName2
                }
            ],
            password: formData.password,
            username: formData.username
        };
    
        try {
            const response = await storeItrAPI(payload);
            if (
                response.data.businessStatusCode === 2 &&
                response.data.businessStatusSubCode === 2.2
            ) {
                setShowBankStatement(true); 
                setActiveStep(5);
                // onContinue();
                setItrFlagDetails(false); 
                changeStatus('BANK_STATEMENT_DETAILS');
              
            } else {
                console.log("Business status code or subcode does not match criteria.");
            }
            console.log("Response:", response.data);
        } catch (error) {
            if ( error.response.status === 401 ) {
                handleSignOut();
                toast.error( "Your Session has expired.You will be redirected to Login Page." )
                navigate( '/' )
            }else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
              }else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
              }
            console.error("Error uploading ITR files:", error);
        }
        finally {
            // Reset loading state after the operation is complete
            setIsLoading(false);
            setIsButtonClicked(false);
          }
    };
    
      //address dialog ended
  const handleBack = () => {
    changeStatus('EMPLOYMENT_DETAILS')
    onBack();
  };

  
    return (
        <>
        {!showBankStatement ? (
        <>

        <div className="row">
            <div className="col-12">
                <h6>Income Tax Return</h6>
                <div className="row itrBox">
                    <div className="col-12">
                        <h6>Fetch ITR Reports</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: 600 }}>Provide Income Tax Portal Username and Password to fetch ITR Details</h6>
                        <div className="row pt-1">
                            <div className="col-3 pt-2">
                                <span style={{ fontSize: "16px", marginLeft: "10px", marginTop: "10px", fontWeight: 600 }}>UserName / Pan</span>
                            </div>
                            <div className="col-6">
                                <ArthaTextField label="Income Tax Portal UserName / PAN" fullWidth style={{ marginLeft: "-40px" }}
                                    name="username"
                                    value={formData.username}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            username: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-3 pt-2">
                                <span style={{ fontSize: "16px", marginLeft: "10px", marginTop: "10px", fontWeight: 600 }}>Password</span>
                            </div>
                            <div className="col-6">
                                <ArthaTextField label="Income Tax Portal Password" fullWidth style={{ marginLeft: "-40px" }}
                                    name="password"
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            password: e.target.value,
                                        })
                                    } />
                            </div>
                        </div>
                        <h6 style={{ fontSize: "16px", fontWeight: 600, marginTop: "10px" }}>This option will help us to get your details directly from income tax portal and expedite loan processing</h6>
                        <h6 className="mt-2" style={{ color: "#00d208" }}>Please Note that username and password are for one time use and are not stored in system. </h6>
                    </div>
                </div>

                <div className="row itrBox mt-3" style={{ backgroundColor: panProvided ? 'lightgray' : 'initial' }}>
                    <div className="col-12">
                        <h6>If you do not wish to share the income tax login details, you can directly upload past two year's ITR using below option</h6>

                        <div className="row">
                            <div className="col-8 col-md-4 mt-3 mt-md-4">
                                <span className="msg">ITR for {res?.itrFiles?.[0]?.financialYear}</span><br />
                                {isITRUploaded ? (
                                    <>
                                        <FaCheck className="check-icon" style={{ marginTop: "3px" }} />
                                        <span> {formData.fileName1}</span>
                                    </>
                                ) : (
                                    <>
                                        <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                                        <span> Not Uploaded</span>
                                    </>
                                )}
                            </div>
                            <div className="col-4 col-md-2 mt-4">
                                <input
                                    accept=".pdf"
                                    style={{ display: 'none' }}
                                    id="itr-upload"
                                    type="file"
                                    disabled={panProvided}
                                    onChange={handleFileUpload}
                                />
                                <label htmlFor="itr-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        className="upload_btn btn_white shadow-none mt-3"
                                        disabled={panProvided}
                                    >
                                        Upload
                                    </Button>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-8 col-md-4 mt-3 mt-md-4">
                                <span className="msg">ITR for {res?.itrFiles?.[1]?.financialYear}</span><br />
                                {isITRUploaded2 ? (
                                    <>
                                        <FaCheck className="check-icon" style={{ marginTop: "3px" }} />
                                        <span> {formData.fileName2}</span>
                                    </>
                                ) : (
                                    <>
                                        <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                                        <span> Not Uploaded</span>
                                    </>
                                )}
                            </div>
                            <div className="col-4 col-md-2 mt-4">
                                <input
                                    accept=".pdf"
                                    style={{ display: 'none' }}
                                    id="itr-upload-2"
                                    type="file"
                                    disabled={panProvided}
                                    onChange={handleFileUpload2}
                                />
                                <label htmlFor="itr-upload-2">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        className="upload_btn btn_white shadow-none mt-3"
                                        disabled={panProvided}
                                    >
                                        Upload
                                    </Button>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6 d-flex justify-content-end">
                        <ArthaClearButton
                            type="button"
                            className="custom_btn"
                            onClick={handleBack}
                        >
                            Back
                        </ArthaClearButton>
                    </div>

                    <div className="col-6 d-flex justify-content-start">
                        <ArthaContinueButton type="button" className="orange custom_btn" onClick={handleContinue}
                            disabled={!isFormValid}
                            style={{ opacity: isButtonClicked ? 0.39 : 1,
                                pointerEvents: isButtonClicked ? "none" : "auto"}} 
                            >
                            {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                        </ArthaContinueButton>
                    </div>
                </div>
            </div>
        </div>
        </>
         ) : (
            <BankStatement
              onContinue={onContinue}
              onBack={ onBack}
            //   onCancel={() => setShowItr( false )}
            />
          )}
          </>
    );
}

export default ITrDetails;
