import React from 'react';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import ArthaContinueButton from '../../fields/ArthaContinueButton';
import ArthaClearButton from '../../fields/ArthaClearButton';
import { useNavigate } from "react-router-dom";

const PinCodeValidation = ( props ) => {
  const navigate = useNavigate();

    const handleClear = () => {
        navigate('/home')
        
      };
    return (
        <div>
            {props.isOpen && (
                <div className="modal row">
                    <div className="modal-content p-5 col-12 col-md-6 ">
                        <div className='row'>
                            <div className='col-md-12 d-flex justify-content-center'>
                                <LocationOffIcon className='location-icon' />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col text-center">
                                <h5>Oops! We're yet to start our services in your area</h5>

                                <p className='modal-text'>Be assured, we'll keep you posted when we expand to your location</p>
                            </div>
                        </div>
                        <div className="row mt-4 mx-auto">
                            <div className="col-6 d-flex justify-content-end mt-4">
                                <ArthaClearButton
                                    variant="contained"
                                    onClick={props.closeModal}
                                >
                                    Change Pincode
                                </ArthaClearButton>
                            </div>

                            <div className="col-6 d-flex justify-content-start mt-4">
                                <ArthaContinueButton
                                    variant="contained"
                                    onClick={handleClear}
                                >
                                    Go to Home
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PinCodeValidation;
