import React, { useState, useRef, useEffect } from "react";
import KYCDetailsForm from "./KYCDetailsForm";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { handleSignOut } from "./SessionExpiredApi";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaClearButton from "../../fields/ArthaClearButton";
import KYCAdharVerification from "./KYCAdharVerification";
import LoadingSpinner from "../../fields/spinner";
import { toast } from 'react-toastify';

const AdharOTPVerification = ({ onContinue, onBack, handleBackForOTP, uidprops, setTenureFlag }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Updated to have six elements in the array
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [showKYCDetailsForm, setShowKYCDetailsForm] =
    useState(false);
  const [showKYCAdharVerification, setShowKYCAdharVerification] = useState(false);
  // const [uid, setUid] = useState();
  const [resendCountdown, setResendCountdown] = useState(0);
  const inputRefs = useRef([]);
  // const resendCountdown = 0; // You may need to implement the countdown logic separately
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const location = useLocation();
  const { ap_id, loan_app_id, uid } = location.state || {};
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < otp.length - 1 && value !== '') {
      // Move focus to the next input if not on the last input and a digit is entered
      inputRefs.current[index + 1].focus();
    }
  };
  const otpString = otp.join('');
  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      // Move focus to the previous input on Backspace
      inputRefs.current[index - 1].focus();
    }
  };
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
    console.log(uidprops);
  }, []);


  const handleBack = () => {
    handleBackForOTP(false)
    // setShowKYCAdharVerification( true ); // Call the onBack function passed as a prop
  };
  // const handleContinue = () => {
  //   setShowKYCDetailsForm(true);
  // };
  function formatOTP(otpArray) {
    return otpArray.join("");
  }

  // const otpArray = {otp};
  const formattedOTP = formatOTP(otp);


  const handleResend = async () => {
    setOtp(["", "", "", "", "", ""]);
    setIsInvalidOtp(false);
    setTimer(90); // Set the countdown to 30 seconds
    const data = {
      uid: uidprops,
    };

    // Define headers
    const headers = {
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };

    try {
      const response = await axios.post('/personal-loan/initiate-kyc-auto', data, { headers: headers });
    } catch (error) {
      if (error.response.status === 401) {
        await handleSignOut();
        toast.error("Your Session has expired.You will be redirected to Login Page.")
        navigate('/')
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error('Error fetching loan data:', error);
    }
  };
 

  const handleContinue = async () => {

    if (otpString.trim() === '') {
      setIsInvalidOtp(true);
      inputRefs.current[0].focus(); // Corrected usage
      return;
    }
    const data = {
      otp: formattedOTP,
    };
    // Define headers
    const headers = {
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };
    	// Set loading state to true
      setIsButtonClicked(true);
      setIsLoading(true);
      try{
      const response = await axios.post('/personal-loan/aadhaar-otp-validation', data, { headers: headers });      
        if (response.status === 200 && response.data.businessStatusCode === 2) {
          setShowKYCDetailsForm(true);
          navigate.push({
            state: {
              otp
            },
          });
        } else {
          console.error('Invalid loan data in response.');
        }
   }
      catch(error)  {
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired. You will be redirected to the Login Page.");
          navigate('/');
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
          console.error('Error fetching loan data:', error);
      }
      finally{
        // Reset loading state after the operation is complete
        setIsLoading(false);
        setIsButtonClicked(false);
      };
      
  };
 
  // Effect to focus on the first input field when component mounts
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  // Resend Timer 
  const handleResendCode = () => {
    setOtp(["", "", "", "", "", ""]);
    setIsInvalidOtp(false);
    setTimer(90); // Set the countdown to 30 seconds

  };


  //Page landing Timer
  const [timer, setTimer] = useState(90);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Cleanup interval when component unmounts
    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      // Handle the case when the timer reaches 0, e.g., redirect or trigger a resend
    }
  }, [timer]);
  return (
    <>
      {!showKYCDetailsForm ? (
        <>
          <div className="AdharOTPForm">
            <div className="AdharOTPHeading mt-3">
              <h5>Verification For Aadhaar Card Number</h5>
              {uidprops && (
                <h5>{`${uidprops.slice(0, 2)}XX XXXX ${uidprops.slice(-4)}`}</h5>
              )}
              <p className="msg mt-4">
                Please Enter OTP Sent to Aadhaar linked Mobile Number
              </p>

              {timer > 0 && (
                <h6 className="text-center">
                  Resend in: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                </h6>
              )}
              <div className="Verify-OTP mt-5">
                <div className="Verify-OTP-section">
                  <div className="otp-inputs">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        className={`otp-input ${isInvalidOtp ? "error" : ""}`}
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => handleInputKeyDown(index, e)}
                        ref={(ref) => (inputRefs.current[index] = ref)} // Store the reference to each input field
                      />
                    ))}
                  </div>
                  {isInvalidOtp && (
                    <p style={{ color: "red", fontSize: "10px" }}>
                      Invalid OTP
                    </p>
                  )}
                  <br />
                  {timer > 0 ? (
                    <h6 className="text-center" style={{ display: "none" }}>
                      Resend in: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                    </h6>
                  ) : (
                    <h6 className="text-center">
                      Didn't Receive any Code?{" "}
                      <a
                        href="#"
                        style={{ color: "rgb(255, 136, 0)", marginLeft: "5px" }}
                      >
                        <h6 className="mt-3" onClick={handleResend}>Resend Code</h6>
                      </a>
                    </h6>
                  )}
                </div>
                <div className="row mt-5 ">
                  <div className="col-6 d-flex justify-content-end">
                    <ArthaClearButton
                      variant="contained"
                      onClick={handleBack}
                    >
                      Back
                    </ArthaClearButton>
                  </div>

                  <div className="col-6 d-flex justify-content-start">
                    <ArthaContinueButton
style={{opacity: isButtonClicked ? 0.39 : 1,
  pointerEvents: isButtonClicked ? "none" : "auto",}}
                      variant="contained"
                      onClick={handleContinue}
                    >
                    {isLoading ? (
            <LoadingSpinner style={{ pointerEvents: 'none' }} />
          ) : (
            'Continue'
          )}
                    </ArthaContinueButton>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </>
      ) : (
        <KYCDetailsForm
          onContinue={onContinue}
          onBack={onBack}
          setTenureFlag={setTenureFlag}
        />
      )}

    </>

  );
};

export default AdharOTPVerification;