import React, { useEffect, useState, useRef } from "react";
import "./personalLoan.css";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton.js";
import ArthaTextField from "../../fields/ArthaTextField.js";
import EligibilityCheckForm from "./EligibilityCheckForm.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import { toast } from 'react-toastify';
import { BusinessenachGetDetailsAPI, BusinessinitiateENachApi, BusinessloanDocketCreationAPI, BusinessskipEnachAPI } from "../../actioncreators/actioncreators.js";
const MandateSetup = ({ onContinue, onBack, setActiveStep }) => {
  const [formData, setFormData] = useState({
    bankName: "",
    accountType: "",
    accountNumber: "",
    ifscCode: "",
    authMode: "",
    userName: "",
    emiStartDt: "",
    emiEndtDt: "",
    emiAmount: "",
  });
  const [ap_id, setApid] = useState("");
  const [loan_app_id, setLoanAppId] = useState("");
  const [showEligibilityCheckForm, setShowEligibilityCheckForm] =
    useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await BusinessenachGetDetailsAPI();

        // let response = {
        //   bankName: "HDFC BANK LTD",
        //   accountNumber: "45647876776",
        //   ifscCode: "ERTF6767887",
        //   accountType: "SAVINGS",
        //   authMode: "Netbanking",
        //   userName: "Kishore Karunakaran",
        //   emiStartDt: "2020/07/06",
        //   emiEndtDt: "2023/07/06",
        //   emiAmount: "1",
        //   httpResponseCode: 200,
        //   businessStatusCode: 2,
        // };

        const {
          accountType,
          bankName,
          accountNumber,
          ifscCode,
          authMode,
          userName,
          emiStartDt,
          emiEndtDt,
          emiAmount,
        } = response.data;

        setFormData((prevData) => ({
          ...prevData,
          bankName: bankName,
          accountType: accountType,
          accountNumber: accountNumber,
          ifscCode: ifscCode,
          authMode: authMode,
          userName: userName,
          emiStartDt: emiStartDt,
          emiEndtDt: emiEndtDt,
          emiAmount: emiAmount,
        }));
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, [ap_id, loan_app_id]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [response, setResponse] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Apply specific validation based on the field name
    let updatedValue = value;

    setFormData((prevData) => ({ ...prevData, [name]: updatedValue }));
    validateForm();
  };

  const validateForm = () => {
    const {
      bankName,
      accountType,
      accountNumber,
      ifscCode,
      authMode,
      userName,
      emiStartDt,
      emiEndtDt,
      emiAmount,
    } = formData;

    const isPanCardValid = bankName;
    const isMobileValid = accountType;
    const isEmailValid = accountNumber;
    const isPinCodeValid = ifscCode;
    const isAuthModeValid = authMode;
    const isUserNameValid = userName;
    const isEmiStartDtValid = emiStartDt;
    const isEmiEndtDtValid = emiEndtDt;
    const isEmiAmountValid = emiAmount;

    setIsFormValid(
      isPanCardValid &&
        isMobileValid &&
        isEmailValid &&
        isPinCodeValid &&
        isAuthModeValid &&
        isUserNameValid &&
        isEmiStartDtValid &&
        isEmiEndtDtValid &&
        isEmiAmountValid
    );
  };

  const buttonStyle = {
    color: "white",
    border: "none",
    backgroundColor:
      isFormValid && isEmailValid
        ? "rgba(255, 115, 0, 1)"
        : "rgb(255 98 0 / 39%)",
        opacity: isButtonClicked ? 0.39 : 1,
        pointerEvents: isButtonClicked ? "none" : "auto",
  };

  const clearButtonStyle = {
    color: "black",
    backgroundColor: isFormValid
      ? "rgba(255, 255, 255, 1)"
      : "rgb(255 255 255 / 39%)",
  };

  const skipMandate = async () => {
    try {
      const response = await BusinessskipEnachAPI();

      console.log("Response from API:", response);

      if (response.status === 200 && response.data.businessStatusCode === 2) {
        createLoanDocket();
      } else {
        console.error("Invalid response status.");
        // Handle error if needed
      }
    } catch (error) {
      handleApiError(error);
      console.error("Error making GET request:", error);
      // Handle error if needed
    }
  };
  
  async function redirect_Mandate() {
    try {
      const response = await BusinessinitiateENachApi();
      return response.data;
    } catch (error) {
      handleApiError(error);
      console.error(error);
    }
    return null;
  }
  
  async function createLoanDocket() {
    try {
      const response = await BusinessloanDocketCreationAPI();
      if (response.data.businessStatusCode === 2) {
        // alert("Your mandate setup is done. Please proceed for the e-sign.")
        setActiveStep(13);
      }
      return response.data;
    } catch (error) {
      handleApiError(error);
      console.error(error);
      throw error; // Rethrow the error to handle it outside of this function
    }
  }
 
  const handleContinue = async () => {
    // Made handleContinue async to correctly handle the asynchronous fetchData_back
    setIsButtonClicked(true);
    setIsLoading(true);
    let res = await redirect_Mandate();
    if (res.businessStatusCode === 2) {
      localStorage.setItem("isBusinessloanmandateRedirected", "true");
      let externalUrl = res.redirectionURL;
      window.location.href = externalUrl;
    }
    setResponse(res);
  };

  return (
    <>
      {!showEligibilityCheckForm ? (
        <>
          <p className="d-flex">
            {" "}
            <h6 className="text">Mandate Setup</h6>
            <a
              className="ms-auto"
              onClick={skipMandate}
              style={{
                color: "rgb(255, 115, 0)",
                fontWeight: "bold",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Skip
            </a>
          </p>

          <p style={{ fontSize: "14px" }}>
            Below account will be used for EMI processing. Please click
            'Continue' to set-up a mandate for this account.
          </p>

          <form className="mt-4">
            <div className="row my-4 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="userName"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  label="Name"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="authMode"
                  type="text"
                  value={formData.authMode}
                  onChange={handleChange}
                  label="Authentication Mode"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-4 mb-4">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  id="outlined-basic"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  label="Bank Name"
                  fullWidth
                  variant="outlined"
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                  className="form-control"
                />
                <div className="subtitle"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="accountType"
                  value={formData.accountType}
                  onChange={handleChange}
                  label="Account Type"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
            </div>

            <div className="row my-4 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="email"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  label="Account Number"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="ifscCode"
                  type="text"
                  maxLength="6"
                  value={formData.ifscCode}
                  onChange={handleChange}
                  label="IFSC Code"
                  id="pin-code-inpt"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
            </div>

            <div className="row my-4 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="text"
                  name="emiStartDt"
                  value={formData.emiStartDt}
                  onChange={handleChange}
                  label="EMI Start Date"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                <ArthaTextField
                  name="emiEndtDt"
                  type="text"
                  value={formData.emiEndtDt}
                  onChange={handleChange}
                  label="EMI End Date"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
            </div>

            <div className="row my-4 mt-md-2">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <ArthaTextField
                  type="text"
                  name="emiAmount"
                  value={formData.emiAmount}
                  onChange={handleChange}
                  label="EMI Amount"
                  fullWidth
                  // style={{ pointerEvents: "none", color: "black" }}
                  disabled
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-0"></div>
            </div>

            <div className="row mt-4 mx-auto">
              <div className="col-6 d-flex justify-content-end mt-4">
                {/* <ArthaClearButton variant="contained" disabled>Cancel</ArthaClearButton> */}
              </div>

              <div className="col-6 d-flex justify-content-end mt-4">
                <ArthaContinueButton
                  variant="contained"
                  onClick={handleContinue}
                  disabled={!isFormValid}
                  style={buttonStyle}
                >
               {isLoading ? (
                    <LoadingSpinner style={{ pointerEvents: 'none' }} />
                  ) : (
                    'Continue'
                  )}
                </ArthaContinueButton>
              </div>
            </div>
          </form>
        </>
      ) : (
        <EligibilityCheckForm
          onContinue={onContinue}
          onCancel={() => setShowEligibilityCheckForm(false)}
        />
      )}
    </>
  );
};

export default MandateSetup;
