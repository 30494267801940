import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { TextField, Button, InputLabel, Select, MenuItem, Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaFormControl from "../../fields/ArthaFormControl";
import ArthaTextField from "../../fields/ArthaTextField";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleSignOut } from "./SessionExpiredApi";

const AddBank = ( { openBankDialog, onCancel, onBankDataSave, prevData } ) => {

    const [ bankForms, setBankForms ] = useState( [ { bankName: "", accType: "", accNo: "" } ] );

    const handleAddForm = () => {
        if ( bankForms.length < 5 ) {
            setBankForms( [ ...bankForms, { bankName: "", accType: "", accNo: "" } ] );
        }
    };


    useEffect( () => {
        if ( prevData ) {
            console.log( prevData );
            setBankForms( prevData )
        }
    }, [ prevData ] )

    useEffect( () => {
        if ( prevData ) {
            console.log( bankForms );
        }
    }, [ bankForms ] )

    const navigate = useNavigate();
    const handleFormChange = ( index, field, value ) => {
        const updatedForms = [ ...bankForms ];
        updatedForms[ index ][ field ] = value;
        setBankForms( updatedForms );
    };
    const save = () => {
        onBankDataSave( bankForms );
    }

    const cancel = () => {
        setBankForms( [ { bankName: "", accType: "", accNo: "" } ] );
        onCancel();
    }
    const [ bankNames, setBankNames ] = React.useState( [] );
    useEffect( () => {
        fetchBankNames();
    }, [] );
    const fetchBankNames = async () => {
        setBankNames( [
            "HDFC Bank",
            "Airtel Payments Bank",
            "Allahabad Bank",
            "Andhra Bank",
            "AU Small Finance Bank",
            "Axis Bank",
            "Bandhan Bank",
            "Bank of Baroda",
            "Bank of India",
            "Bank of Maharashtra",
            "Canara Bank",
            "Central Bank of India",
            "Citibank",
            "CITY UNION BANK LTD",
            "Corporation Bank",
            "DCB Bank",
            "Dena Bank",
            "Equitas Small Finance Bank",
            "Esaf Small Finance Bank",
            "Federal Bank",
            "Fincare Small Finance Bank",
            "Fino Payments Bank",
            "ICICI Bank",
            "IDBI Bank",
            "IDFC FIRST Bank",
            "India Post Payments Bank",
            "Indian Bank",
            "Indian Overseas Bank",
            "IndusInd Bank",
            "Jana Small Finance Bank",
            "Karnataka Bank",
            "Karur Vysya Bank",
            "Kotak Mahindra Bank",
            "Municipal Bank",
            "Oriental Bank of Commerce",
            "Paytm Payments Bank",
            "Punjab National Bank",
            "RBL (Ratnakar) Bank",
            "Saraswat co-operative Bank Ltd",
            "South Indian Bank",
            "Standard Chartered Bank",
            "State Bank of India",
            "Syndicate Bank",
            "Tamilnad Mercentile Bank Ltd.",
            "UCO Bank",
            "Ujjivan Bank",
            "Union Bank of India",
            "United Bank of India",
            "Utkarsh Small Finance Bank",
            "Vijaya Bank",
            "Yes Bank"
        ] )
        try {
            const apiPayload = {
                type: "DIGITAP_BANK_STATEMENT_INSTITUTION_ID",
                key: "",
                source: "key",
            };
            const banknameRes = await axios.post(
                "/personal-loan/fetch-data",
                apiPayload,
                {
                    withCredentials: true,
                }
            );
            setBankNames( banknameRes.data.data );
        } catch ( error ) {
            if ( error.response && error.response.status === 401 ) {
                handleSignOut();
                toast.error( "Your Session has expired.You will be redirected to Login Page." )
                navigate( '/' )
              }else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
              }else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
              }
            console.error( "Error fetching address vintage:", error );
        }
    };

    return (
        <>
            <Dialog
                open={openBankDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal row overflow-auto">
                    <div className="modal-content col-md-6">
                        <h6 className="text">Add Bank Details</h6>
                        {bankForms.map( ( form, index ) => (
                            <div className=" border p-2 rounded" key={index}>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id={`bankName-${index}`}>
                                                Bank
                                            </InputLabel>
                                            <Select
                                                labelId={`bankName-${index}`}
                                                id={`bankName-${index}`}
                                                name={`bankName-${index}`}
                                                value={form.bankName}
                                                onChange={( e ) => handleFormChange( index, "bankName", e.target.value )}
                                            >
                                                {bankNames && bankNames.map( ( item ) => {
                                                    return ( <MenuItem value={item}>{item}</MenuItem> )
                                                } )}

                                            </Select>
                                        </ArthaFormControl>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaFormControl fullWidth>
                                            <InputLabel id={`accType-label-${index}`}>
                                                Account Type
                                            </InputLabel>
                                            <Select
                                                labelId={`accType-label-${index}`}
                                                id={`accType-${index}`}
                                                name={`accType-${index}`}
                                                value={form.accType}
                                                onChange={( e ) => handleFormChange( index, "accType", e.target.value )}
                                            >
                                                <MenuItem value="Saving">Saving</MenuItem>
                                            </Select>
                                        </ArthaFormControl>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <ArthaTextField
                                            name={`accNo-${index}`}
                                            value={form.accNo}
                                            onChange={( e ) => handleFormChange( index, "accNo", e.target.value )}
                                            label="Account Number"
                                            fullWidth
                                            inputProps={{ maxLength: 80 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) )}
                        <div className="row mt-2">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <ArthaClearButton variant="contained" onClick={handleAddForm}>
                                    Add Bank
                                </ArthaClearButton>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 pt-2">
                                <span className="text-primary">Maximum 5 account details can be added</span>
                            </div>
                        </div>
                        <div className="row mt-4 mx-auto">
                            <div className="col-6 d-flex justify-content-end mt-4">
                                <ArthaClearButton variant="contained" onClick={cancel}>
                                    Cancel
                                </ArthaClearButton>
                            </div>
                            <div className="col-6 d-flex justify-content-start mt-4">
                                <ArthaContinueButton variant="contained" onClick={save}>
                                    Save
                                </ArthaContinueButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default AddBank;
