import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ArthaCancelButton = styled(Button)({
  background: "white",
  color: "black",
  boxShadow: "none",
  width: "100px",
  height: "35px",

  textTransform: "capitalize",

  borderRadius: "40px",
  fontWeight: "800",
  margin: "0px 3px",
  border: "1px solid rgb(228 228 228) !important",

  "&:hover": {
    color: "black",
    boxShadow: "none !important",
    background: "white",
  },
});

export default ArthaCancelButton;
