import { FormControl } from "@mui/material";
import { styled } from "@mui/system";

const ArthaFormControl = styled(FormControl)({
  width: "100%", // Make the control full-width
  height: "80%",
  "& .MuiInputBase-root": {
    fontSize: "12px", // Adjust the font size of the input as needed
  },
  "& .MuiFormLabel-root": {
    fontSize: "14px", // Adjust the font size of the label as needed
    backgroundColor:"white", 
  },
});

export default ArthaFormControl;
