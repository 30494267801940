import React from 'react';

const LoadingSpinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
      <svg width="60" height="18" xmlns="http://www.w3.org/2000/svg" fill="none">
        <circle cx="5" cy="5" r="5" fill="#FFFFFF" style={{ pointerEvents: 'none' }}>
          <animate attributeName="r" values="5;0;5" dur="1.5s" repeatCount="indefinite" />
        </circle>
        <circle cx="20" cy="5" r="5" fill="#FFFFFF" style={{ pointerEvents: 'none' }}>
          <animate attributeName="r" values="5;0;5" begin="0.5s" dur="1.5s" repeatCount="indefinite" />
        </circle>
        <circle cx="35" cy="5" r="5" fill="#FFFFFF" style={{ pointerEvents: 'none' }}>
          <animate attributeName="r" values="5;0;5" begin="1s" dur="1.5s" repeatCount="indefinite" />
        </circle>
        <circle cx="50" cy="5" r="5" fill="#FFFFFF" style={{ pointerEvents: 'none' }}>
          <animate attributeName="r" values="5;0;5" begin="1s" dur="1.5s" repeatCount="indefinite" />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingSpinner;
