import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import ArthaClearButton from "../../fields/ArthaClearButton";
import AllOffers from "./AllOffers";
import SanctionLetter from "./SanctionLetter";
import Congrats from "./MessagePages/Congrats";
import axios from "axios";
import EditRequirement from "./EditRequirement"; // Replace with the correct path
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaApplyButton from "../../fields/ArthaApplyButton";
import { Button } from "@mui/material";
import { toast } from 'react-toastify';
const LoanOffer = ( { onBack, onContinue } ) => {
  const [ tenureLength, setTenureLength ] = React.useState();
  const [ showSanctionLetter, setShowSanctionLetter ] = useState( false );
  const [ showEditRequirement, setShowEditRequirement ] = useState( false );
  const [ updatedOffer, setUpdatedOffer ] = React.useState( false );
  const navigate = useNavigate();
  const location = useLocation();
  const { ap_id, loan_app_id, response } = location.state || {};

  const [ feeCalculated, setFeeCalculated ] = useState( false );
  const [ stateResponse, setStateResponse ] = useState(
    // {
    //   "businessStatusCode": 2,
    //   "httpResponseCode": 200,
    //   "loanOfferData": {

    //     "foirCheckJson": [ //
    //       {
    //         "interestRate": 15,
    //         "loanAmount": 500000,
    //         "loanTenure": 36,
    //         "minimumLoan": 25000,
    //         "tentativeEmi": 17332,
    //         "tenure": [
    //           {
    //             "EMIPerLakh": 9025,
    //             "MaXLoanAmount": 199427,
    //             "Tenure": 12
    //           },
    //           {
    //             "EMIPerLakh": 4848,
    //             "MaXLoanAmount": 371236,
    //             "Tenure": 24
    //           },
    //           {
    //             "EMIPerLakh": 3466,
    //             "MaXLoanAmount": 519250,
    //             "Tenure": 36
    //           },
    //           {
    //             "EMIPerLakh": 2783,
    //             "MaXLoanAmount": 646766,
    //             "Tenure": 48
    //           },
    //           {
    //             "EMIPerLakh": 2378,
    //             "MaXLoanAmount": 756622,
    //             "Tenure": 60
    //           }
    //         ]
    //       }
    //     ],
    //     "loanOfferJson": [
    //       {
    //         "associationType": "PARTNER",
    //         "emi": 18581.791679870264,
    //         "fee": "2%",
    //         "lenderId": "L1",
    //         "loanAmount": 500000,
    //         "loanTenure": 36,
    //         "rate": 0.2
    //       }
    //     ]
    //   }
    // }
  );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ isButtonClicked, setIsButtonClicked ] = useState( false );

  // useEffect(() => {
  //   // Assuming `response` from `uselocation` is available in the `location.state`
  //   if (response) {
  //     setStateResponse(response);
  //   }
  // }, [response]);

  //GET API TO FETCH LOAN OFFER DETAILS START -------------------------------------------------------------
  useEffect( () => {
    const fetchLoanOfferData = async () => {
      try {
        const response = await axios.get( "/personal-loan/loan-offers-info", {
          headers: {
            "x-apid": ap_id,
            "x-loanappid": loan_app_id,
          },
        } );
        setStateResponse( response.data );
      } catch ( error ) {
        if ( error.response.status === 401 ) {
          await handleSignOut();
          toast.error( "Your Session has expired.You will be redirected to Login Page." )
          navigate( '/' )
        }else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
       }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
        console.error( "Error fetching user data:", error );
      }
    };

    fetchLoanOfferData();
  }, [ ap_id, loan_app_id ] );
  //GET API TO FETCH SANCTION LETTER DETAILS END -------------------------------------------------------------

  useEffect( () => {
    if ( stateResponse && stateResponse.loanOfferData ) {
      setTenureLength(
        stateResponse.loanOfferData.foirCheckJson[ 0 ].tenure.length
      );
    }
  }, [ stateResponse ] );



  // setResponse(response);
  // useEffect( () => {
  //   //Call API https://arthavedh.com:6542/personal-loan/integration-api here and whatever response is comming set to response variable as given below
  //   //setResponse(res)
  // }, [ response ] );

  useEffect( () => {
    if ( stateResponse && stateResponse.loanOfferData )
      setTenureLength(
        stateResponse.loanOfferData.foirCheckJson[ 0 ].tenure.length
      );
  }, [ stateResponse ] );

  useEffect( () => {
    if ( stateResponse && stateResponse.loanOfferData && !feeCalculated ) {
      // Calculate the fee value as a percentage of loanAmount
      const updatedLoanOfferJson =
        stateResponse.loanOfferData.loanOfferJson.map( ( offer ) => {
          const feePercentage = parseFloat( offer.fee.replace( "%", "" ) ) / 100;
          const calculatedFee = offer.loanAmount * feePercentage;

          return {
            ...offer,
            fee: calculatedFee.toFixed( 2 ), // Set fee as a decimal with 2 decimal places
          };
        } );

      // Update only the necessary fields in the state
      setStateResponse( ( prevStateResponse ) => ( {
        ...prevStateResponse,
        loanOfferData: {
          ...prevStateResponse.loanOfferData,
          loanOfferJson: updatedLoanOfferJson,
        },
      } ) );

      // Set feeCalculated to true to avoid an infinite loop
      setFeeCalculated( true );
    }
  }, [ stateResponse, feeCalculated ] );

  const setShowAllOffersFun = ( data ) => {
    setShowSanctionLetter( false );
    setShowEditRequirement( false );
    setUpdatedOffer( data );
  };

  const handleBack = () => {
    onBack();
  };

  // const handleContinue = () => {
  //   setShowAllOffers( true );
  // };

  const handleContinue = async () => {


    // Set loading state to true
    setIsButtonClicked( true );
    setIsLoading( true );

    try {

      let loanOffer = {};
      if ( updatedOffer ) {
        updatedOffer.lenderId = stateResponse.loanOfferData.loanOfferJson[ 0 ].lenderId;
        updatedOffer.associationType = stateResponse.loanOfferData.loanOfferJson[ 0 ].associationType;
        loanOffer = updatedOffer;
      } else {
        loanOffer = stateResponse.loanOfferData.loanOfferJson[ 0 ]
      }

      const requestData = {
        loan_amount: loanOffer.loanAmount,
        loan_tenure: loanOffer.loanTenure,
        interest_rate: loanOffer.rate,
        emi: loanOffer.emi,
        fee: parseFloat( loanOffer.fee ),
        lender_id: loanOffer.lenderId,
        association_type: loanOffer.associationType,
      };

      const headers = {
        "Content-Type": "application/json",
        "x-apid": ap_id, // Make sure ap_id is defined
        "x-loanappid": loan_app_id, // Make sure loan_app_id is defined
      };


      const apiResponse = await axios.post(
        "/personal-loan/accepted-loan-offer",
        requestData,
        { headers }
      );

      if ( apiResponse.status === 200 ) {
        if ( apiResponse.data ) {
          const nextApiResponse = await axios.get(
            "/personal-loan/sanction-letter-and-mitc-generation",
            { headers }
          );
          const responseBody = nextApiResponse.data;
          if (
            nextApiResponse.status === 200 &&
            nextApiResponse.data.businessStatusCode === 2
          ) {
            navigate( SanctionLetter, {
              state: {
                "x-apid": ap_id,
                "x-loanappid": loan_app_id,
                response: responseBody
              },
            } );
            onContinue();
            setShowSanctionLetter( true );
            console.log( "Successfully called the next API:", nextApiResponse.data );
          } else {
            toast.error( nextApiResponse.data.message );
            console.error( "Error in next API call:", nextApiResponse.statusText );
          }
        }
      } else {
        console.error( "Error in API call:", apiResponse.statusText );
        toast.error( apiResponse.data.message );
      }
    } catch ( error ) {
      console.log( error );
      if ( error.response.status === 401 ) {
        await handleSignOut();
        toast.error( "Your Session has expired.You will be redirected to Login Page." )
        navigate( '/' )
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error( "Error:", error );
    } finally {
      // Reset loading state after the operation is complete
      setIsLoading( false );
      setIsButtonClicked( false );
    }
  };

  return (
    <>
      {!showSanctionLetter && !showEditRequirement ? (
        <>
          <div className="row">
            <div className="AdharOTPHeading col-12 mt-2">
              <h6>
                Congrats, You have passed all your checks and qualify for the
                following offer
              </h6>
              <p className="offer-page-heading">
                Offer is based on your credit score
              </p>
            </div>
          </div>

          {stateResponse &&
            stateResponse.loanOfferData &&
            stateResponse.loanOfferData.loanOfferJson &&
            stateResponse.loanOfferData.loanOfferJson.length > 0 &&
            stateResponse.loanOfferData.loanOfferJson.map( ( item, index ) => {
              return (
                <div className="row mt-4 d-flex justify-content-center">
                  {updatedOffer ? (
                    <div className="col-11 shadow-none loan-offer-card card">
                      <div className="row card-body d-flex justify-content-center">
                        <div className="col-4  text-center">
                          <h6>Updated Loan Offer</h6>
                        </div>
                        <div className="row mt-3">
                          <div className="col-4">
                            <p className="offer-page-heading">EMI/Month</p>
                            <h4 className="loan-offer-amount">
                              ₹{updatedOffer.emi.toFixed( 2 )}
                            </h4>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Loan Amount</p>
                            <p className="loan-offer-amount2">
                              ₹ {updatedOffer.loanAmount}
                              <FaEdit
                                onClick={() => setShowEditRequirement( true )}
                              />
                            </p>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Tenure</p>
                            <p className="loan-offer-amount2">
                              {updatedOffer.loanTenure} mths
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="offer-page-heading">interest Rate</p>
                            <p className="loan-offer-amount2">
                              <p className="loan-offer-amount2">{( updatedOffer.rate * 100 ).toFixed( 2 )}%</p>                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-11 shadow-none loan-offer-card card">
                      <div className="row card-body d-flex justify-content-center">
                        <div className="col-4  text-center">
                          <h6>Loan Offer</h6>
                        </div>
                        <div className="row mt-3">
                          <div className="col-3">
                            <p className="offer-page-heading">EMI/Month</p>
                            <h5 className="loan-offer-amount">
                              ₹{item.emi.toFixed( 2 )}
                            </h5>
                          </div>
                          <div className="col-3">
                            <p className="offer-page-heading">Loan Amount</p>
                            <p className="loan-offer-amount2">
                              ₹ {item.loanAmount}
                              <FaEdit onClick={() => setShowEditRequirement( true )} />
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="offer-page-heading">Tenure</p>
                            <p className="loan-offer-amount2">
                              {item.loanTenure} mths
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="offer-page-heading">Interest Rate</p>
                            <p className="loan-offer-amount2">{( item.rate * 100 ).toFixed( 2 )}%</p>
                          </div>

                          <div className="col-2">
                            <p className="offer-page-heading">Processing Fee</p>
                            <p className="loan-offer-amount2">{item.fee}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            } )}

          <div className="row mt-3 mt-md-4 d-flex justify-content-center">
            <div className="col-sm-8 col-md-4 col-lg-4 ">
              <ArthaTextField
                name="promo"

                label="Promo Code"
                fullWidth
                inputProps={{ maxLength: 80 }}
              />

            </div>
            <div className="col-sm-4 col-md-2 col-lg-2 p-0">
              <Button
                variant="contained"
                className="upload_btn  apply_btn_white  shadow-none mt-2 "
                style={{ color: "orange !important" }}
              >
                Apply
              </Button>

            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center mt-3">
              <span style={{ color: "green", fontWeight: 800, fontSize: "14px", cursor: "pointer" }}>View Offers</span>
            </div>
          </div>
          <div className="row mt-5 ">
            {/* <div className="col-6 d-flex justify-content-end">
              <ArthaClearButton
                type="button"
                className="custom_btn"
                onClick={handleBack}
              >
                Decline Offer
              </ArthaClearButton>
            </div> */}

            <div className="col-12 d-flex justify-content-center">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn "
                onClick={handleContinue}
                style={{
                  opacity: isButtonClicked ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto"
                }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: 'none' }} />
                ) : (
                  ' Accept Offer'
                )}

              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : null
      }

      {
        showSanctionLetter ? (
          <SanctionLetter
            onContinue={onContinue}
            onBack={onBack}
            onCancel={() => setShowSanctionLetter( false )}
          />
        ) : null
      }

      {
        showEditRequirement ? (
          <EditRequirement
            setShowAllOffersFun={setShowAllOffersFun}
            onCancel={() => setShowEditRequirement( false )}
            response={stateResponse}
            tenureLength={tenureLength}
          />
        ) : null
      }
    </>
  );
};

export default LoanOffer;
