import React, { useState, useRef ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineFileUpload } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { toast } from "react-toastify";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import Cancel from "./images/Cancel_1.png";
import { DocumentDisplay } from "../../actions/loanAction";
import { businessLoanFetchGstrDetailsAPI } from "../../actioncreators/actioncreators";
 
const UploadGSTRPopup = ({
  open,
  handleClose,
  bank,
  fileNames,
  response,
  documents,
  filesData,
  removeFileFromFileNames,
  setFileNames,
}) => {
  const dispatch = useDispatch();
  const [fileInfo, setFileInfo] = useState([]);
  const [currentFile, setCurrentFile] = useState();
 
  const hiddenFileInput = useRef(null);
  const [documents1, setDocuments1] = useState([]);
  // const documentsww = response?.data?.gstResponse?.documents || [];
  // setDocuments1(documentsww);
  useEffect(() => {
    console.log("response inside useEffect:", documents);
 
    console.log("documents inside useEffect:", documents);
   
  }, [response]);
 
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      if (fileInfo.length + selectedFiles.length > 6) {
        toast.error("You can only upload a maximum of 6 documents.");
        return;
      }
 
      const isEPDF = Array.from(selectedFiles).every(
        (file) => file.type === "application/pdf"
      );
 
      if (!isEPDF) {
        toast.error("Only ePDF documents are allowed.");
        return;
      }
 
      const newFiles = Array.from(selectedFiles).map((file) => ({
        file,
        password: "",
      }));
 
      setFileInfo((prevFileInfo) => [...prevFileInfo, ...newFiles]);
      setCurrentFile(selectedFiles[0]);
    }
  };
 
  const handleDeleteFile1 = (file) => {
    removeFileFromFileNames(file);
    setCurrentFile(null);
  };
  const [res, setRes] = useState();
  const [isITRUploaded, setIsITRUploaded] = useState(false);
  const [isITRUploaded2, setIsITRUploaded2] = useState(false);
 
  // const fetchGstrReport = async () => {
  //   try {
  //     const response2 = await businessLoanFetchGstrDetailsAPI();
  //     console.log('eeeeee', response2)
  //     console.log('Doc Name ', response2.data.gstResponse.documents[0].documentName)
  //     setRes(response2);
  //     if (response2.data?.gstResponse?.documents?.[0]?.documentName) {
  //       setIsITRUploaded(true);
  //     }
  //     if (response2.data?.gstResponse?.documents?.[0]?.documentName) {
  //       setIsITRUploaded2(true);
  //     }
  //     // setFormData({
  //     //   username: response2.data?.username,
  //     //   password: "",
  //     //   gstin: response2.data?.gstResponse.gstin,
  //     //   fileName1: response2.data?.gstResponse?.documents?.[0]?.documentName,
  //     //   fileName2: response2.data?.gstResponse?.documents?.[1]?.documentName,
  //     // });
  //   } catch (error) {
  //     // handleApiError(error);
  //     console.error("Error fetching ITR report:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchGstrReport();
  // }, []);
  const handleDeleteFile = (index) => {
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = [...prevFileInfo];
      updatedFileInfo.splice(index, 1);
      return updatedFileInfo;
    });
    setCurrentFile(null);
  };
 
  const handlePasswordChange = (index, value) => {
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = [...prevFileInfo];
      updatedFileInfo[index].password = value;
      return updatedFileInfo;
    });
  };
 
  const handlePasswordEdit = (outerIndex, innerIndex, value) => {
    if (fileNames) {
      let outerFile = fileNames[outerIndex];
      let existingFile = outerFile.files[innerIndex];
      existingFile.password = value;
      const updatedFileNames = [...fileNames];
      updatedFileNames[outerIndex].files[innerIndex].password = value;
      setFileNames(updatedFileNames);
    }
  };
  // console.log("Files Data:", response?.data?.gstResponse?.documents[0]?.documentName);
  // setFileNames(response?.data?.gstResponse?.documents);
  const handleAddFile = () => {
    setCurrentFile(null);
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
 
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        console.error("Error reading the file:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
 
  const handleSave = async () => {
    const filesData = await Promise.all(
      fileInfo.map(async (file) => ({
        documentName: file.file.name,
        // password: file.password,
        documentData: await fileToBase64(file.file),
      }))
    );
 
    console.log("Files Data:", filesData);
 
    // dispatch(DocumentDisplay(filesData));
 
    setFileInfo([]);
    setCurrentFile(null);
    handleClose(filesData);
  };
 
  const renderButtons = () => {
    if (currentFile) {
      return (
        <>
          <div className="row mt-4">
            <div className="uploadadhar row">
              <span>
                <label
                  htmlFor="fileInput"
                  className="upload-label d-flex justify-content-center"
                >
                  <span className="mt-1">+Add File</span>
                </label>
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                />
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6 d-flex justify-content-center">
              <ArthaClearButton
                type="button"
                onClick={() => {
                  setFileInfo([]);
                  setCurrentFile(null);
                }}
              >
                Cancel
              </ArthaClearButton>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <ArthaContinueButton
                type="button"
                className="orange custom_btn"
                onClick={handleSave}
              >
                Continue
              </ArthaContinueButton>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };
  console.log("API Response:", response);
 
  return (
    <Dialog open={open} onClose={() => handleClose([])} filesData={filesData} maxWidth="lg">
      <DialogContent>
        <div className="row d-flex justify-content-center mb-4">
          <div className="col-12 text-center">Upload GSTR Report for previous 24 Months</div>
        </div>
 
        <div className="row d-flex justify-content-center">
            {/* <div className="col-12 text-center">
              {fileNames &&
                fileNames.map((item, outerIndex) => {
                  return item.files.map((file, innerIndex) => (
                    <div key={innerIndex} className="uploadadhar row p-0 m-0">
                      <div className="uploaded-image-container col-12 d-flex">
                        <p
                          className="doc-label col-2 align-items-top"
                          style={{ fontSize: "12px", paddingTop: "7px" }}
                        >{`Document ${outerIndex + 1}`}</p>
                        <div className="col-5 mb-1">
                          <span
                            style={{
                              fontSize: "12px",
                              wordWrap: "break-word",
                            }}
                          >
                            {file.name}
                          </span>
                        </div>
                        <div className="col-1">
                          <span
                            onClick={() => handleDeleteFile1(file)}
                            title="Delete"
                            style={{ width: "50px", fontSize: "12px" }}
                          >
                            <img
                              src={Cancel}
                              style={{ width: "20px", justifyContent: "center" }}
                              alt="Cancel"
                            />
                          </span>
                        </div>
                       
                      </div>
                      <hr
                        style={{
                          height: "1px",
                          borderWidth: "0",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      />
                    </div>
                  ));
                })}
            </div> */}
               <div className="col-12 text-center">
               {documents1.length > 0 ? (
        documents1.map((file, index) => (
          <div key={file.docId} className="uploadadhar row p-0 m-0">
            <div className="uploaded-image-container col-12 d-flex">
              <p
                className="doc-label col-2 align-items-top"
                style={{ fontSize: "12px", paddingTop: "7px" }}
              >
                {`Document ${index + 1}`}
              </p>
              <div className="col-5 mb-1">
                <span
                  style={{
                    fontSize: "12px",
                    wordWrap: "break-word",
                  }}
                >
                  {file.documentName}
                </span>
              </div>
              <div className="col-1">
                <span
                  onClick={() => removeFileFromFileNames(file)}
                  title="Delete"
                  style={{ width: "50px", fontSize: "12px" }}
                >
                  <img
                    src={Cancel} // Ensure this path is correct
                    style={{ width: "20px", justifyContent: "center" }}
                    alt="Cancel"
                  />
                </span>
              </div>
            </div>
            <hr
              style={{
                height: "1px",
                borderWidth: "0",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            />
          </div>
        ))
      ) : (
        <p></p>
      )}
    {/* </div>
  );
}; */}
    {/* </div> */}
  {/* );
}; */}
    </div>
  {/* );
}; */}
 
    {/* </div> */}
  {/* ); */}
 
 
 
      {/* </div> */}
 
        </div>
 
        {fileInfo.map((file, index) => (
          <div key={index} className="uploadadhar row p-0 m-0">
            <div className="uploaded-image-container col-12 d-flex">
              <p
                className="doc-label col-4 align-items-top"
                style={{ fontSize: "12px", paddingTop: "7px" }}
              >{`Document ${index + 1}`}</p>
              <div className="col-5 mb-1">
                <span
                  style={{
                    fontSize: "12px",
                    wordWrap: "break-word",
                  }}
                >
                  {file.file.name}
                </span>
              </div>
              <div className="col-1">
                <span
                  onClick={() => handleDeleteFile(index)}
                  title="Delete"
                  style={{ width: "50px", fontSize: "12px" }}
                >
                  <img
                    src={Cancel}
                    style={{ width: "20px", justifyContent: "center" }}
                    alt="Cancel"
                  />
                </span>
              </div>
              {/* <div className="col-4">
                <input
                  type="password"
                  placeholder={`Enter Document password ${index + 1}`}
                  value={file.password}
                  onChange={(e) => handlePasswordChange(index, e.target.value)}
                  style={{
                    width: "100%",
                    height: "33px",
                    padding: "6px 12px",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    color: "#495057",
                    backgroundColor: "#fff",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    boxShadow: "none",
                    transition:
                      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                  }}
                />
              </div> */}
            </div>
            <hr
              style={{
                height: "1px",
                borderWidth: "0",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            />
          </div>
        ))}
 
        {!currentFile && (
          <>
            <div className="uploadadhar row">
              <span>
                <label
                  htmlFor="fileInput"
                  className="upload-label d-flex justify-content-center"
                >
                  <span className="upload-icon">
                    <MdOutlineFileUpload />
                  </span>
                  <span className="mt-1">Upload</span>
                </label>
                <input
                  ref={hiddenFileInput}
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                />
              </span>
            </div>
            <div className="row mt-4">
              <div className="col-6 d-flex justify-content-center">
                <ArthaClearButton
                  type="button"
                  onClick={() => {
                    setFileInfo([]);
                    setCurrentFile(null);
                    handleClose([]);
                  }}
                >
                  Cancel
                </ArthaClearButton>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <ArthaContinueButton
                  type="button"
                  className="orange custom_btn"
                  onClick={handleSave}
                >
                  Continue
                </ArthaContinueButton>
              </div>
            </div>
          </>
        )}
        {renderButtons()}
      </DialogContent>
    </Dialog>
  );
};
 
export default UploadGSTRPopup;