import React, { useState, useRef, useEffect } from "react";
import {
    TextField,
    Checkbox,
    Button,
    FormControlLabel,
    Radio,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog
} from "@mui/material";
import {
    FaIdBadge,
    FaCheck,
    FaExclamationTriangle,
    FaExclamationCircle,
} from "react-icons/fa";
import ArthaTextField from "../../fields/ArthaTextField";
import ArthaFormControl from "../../fields/ArthaFormControl";

import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import UploadDocument from "./UploadDocument";
import dayjs from 'dayjs';
export const EmployeerForm = ( { setPrev2Info, prev2Info, setPrev1Info, prev1Info, isPrev1AddressAdded, isPrev2AddressAdded, setPrev1Address, setPrev2Address, prev1Address, prev2Address, setPrev1AddressAdded, setPrev2AddressAdded, previousEmployerList, setPreviousEmployerList, updatePrevDoc, currentIndex, previousEmployeerDocuments, setPreviousEmployeerTillDate, setPreviousEmployeerFromDate, previousEmployeerFromDate, previousEmployeerTillDate, employeerCategoryList, setPreviousEmployeerType, previousEmployeerType, res, minDate, formData, selectedEmployeerNames, previoudEmployerClick, setFormData, workedFromCounter, workedTillCounter, getWorkTill, bankNames, selectedPreviuosEmployer, fileName2, fileInputRefID2, handleFileChangeID2, handleUploadButtonClickID2, employeers, prevCount, isValidateEmployeerForm } ) => {
    const [ isFormFilled, setIsFormFilled ] = useState( false );
    let [ minDates, setMinDates ] = React.useState( [] );
    useEffect( () => {
        setMinDates( previousEmployeerFromDate );
    }, [ previousEmployeerFromDate ] )


    const [ customText, setCustomText ] = useState( [] );

    const handlePreviousNewEmployerChange = ( e, index ) => {
        const newValue = e.target.value;

        setCustomText( ( prevCustomText ) => {
            const updatedCustomText = [ ...prevCustomText ];
            updatedCustomText[ index ] = newValue;
            return updatedCustomText;
        } );

        const updatedPreviousEmployers = [ ...formData.previousEmployer ];
        updatedPreviousEmployers[ index ] = newValue;

        setFormData( {
            ...formData,
            previousEmployer: updatedPreviousEmployers,
        } );
    };
    const handleTextFieldClick = ( event, index ) => {
        // Stop event propagation to prevent closing the dropdown
        //customText[ index ] = "";
        event.stopPropagation();
    };
    const handlePreviousEmployerChange = ( e, index ) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [ ...formData.previousEmployer ];
        updatedPreviousEmployers[ index ] = newValue;

        setFormData( {
            ...formData,
            previousEmployer: updatedPreviousEmployers,
        } );
    };

    const handlePreviousFromChange = ( e, index ) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [ ...formData.workedFrom ];
        updatedPreviousEmployers[ index ] = newValue;

        const updatedpreviousEmployeerFromDate = [ ...previousEmployeerFromDate ];
        updatedpreviousEmployeerFromDate[ index ] = newValue;

        setFormData( {
            ...formData,
            workedFrom: updatedPreviousEmployers,
        } );

        setPreviousEmployeerFromDate( updatedpreviousEmployeerFromDate );

    };


    const [ dateValidationError, setDateValidationError ] = useState( '' );
    // const handlePreviousWorkedTillChange = ( e, index ) => {
    //     let fromDate = previousEmployeerFromDate[ index ];
    //     let currentDate = e.target.value;

    //     if ( currentDate <= fromDate ) {
    //         setDateValidationError( 'Worked Till date should be greater than Working From date' );
    //     } else {
    //         setDateValidationError( '' ); // Reset error message if the date is valid

    //         const newValue = e.target.value;
    //         const updatedPreviousEmployers = [ ...formData.workedTill ];
    //         updatedPreviousEmployers[ index ] = newValue;

    //         const updatedpreviousEmployeerTillDate = [ ...previousEmployeerTillDate ];
    //         updatedpreviousEmployeerTillDate[ index ] = newValue;

    //         setFormData( {
    //             ...formData,
    //             workedTill: updatedPreviousEmployers,
    //         } );

    //         setPreviousEmployeerTillDate( updatedpreviousEmployeerTillDate );
    //     }
    // };

    const handlePreviousWorkedTillChange = ( e, index ) => {
        const fromDate = previousEmployeerFromDate[ index ];//4 .  14
        let currentDateValue = '';
        if ( index == 0 ) {
            currentDateValue = workedTiiList[ 'workedTill-0' ];
        } else {
            currentDateValue = workedTiiList[ 'workedTill-1' ];
        }
        let temp = dayjs( currentDateValue ); // Create a dayjs object for the date
        let now = dayjs();
        if ( temp.isAfter( now, 'day' ) ) {
            setDateValidationError( 'The date is in the future.' );
        } else {
            setDateValidationError( '' );
        }
        const currentDate = currentDateValue;
        if ( previousEmployeerTillDate[ prevCount - 1 ] ) {
            if ( currentDate >= previousEmployeerFromDate[ prevCount - 1 ] || currentDate <= fromDate ) {
                setDateValidationError( 'Worked Till date should be greater than current employer start date' );
            } else {
                setDateValidationError( '' ); // Reset error message if the date is valid

                const updatedPreviousEmployers = [ ...formData.workedTill ];
                updatedPreviousEmployers[ index ] = currentDate;

                const updatedpreviousEmployeerTillDate = [ ...previousEmployeerTillDate ];
                updatedpreviousEmployeerTillDate[ index ] = currentDate;

                setFormData( {
                    ...formData,
                    workedTill: updatedPreviousEmployers,
                } );

                setPreviousEmployeerTillDate( updatedpreviousEmployeerTillDate );
                getWorkTill( currentDateValue, index );
            }
        } else {
            if ( currentDate >= formData.workingSince || currentDate <= fromDate ) {
                setDateValidationError( 'Worked Till date should be greater than Working From date' );
            } else {
                setDateValidationError( '' ); // Reset error message if the date is valid

                const updatedPreviousEmployers = [ ...formData.workedTill ];
                updatedPreviousEmployers[ index ] = currentDate;

                const updatedpreviousEmployeerTillDate = [ ...previousEmployeerTillDate ];
                updatedpreviousEmployeerTillDate[ index ] = currentDate;

                setFormData( {
                    ...formData,
                    workedTill: updatedPreviousEmployers,
                } );

                setPreviousEmployeerTillDate( updatedpreviousEmployeerTillDate );
                getWorkTill( currentDateValue, index );
            }
        }

    };

    const [ emailValidationError, setEmailValidationError ] = useState( false );
    const handlePreviousPreviousOfficialEmailIDChange = ( e, index ) => {
        const newValue = e.target.value;

        // Check if the value is empty
        if ( newValue.trim() === '' ) {
            setEmailValidationError( false );
        } else {
            // Validate the email format
            const isValidEmail =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test( newValue );



            if ( !isValidEmail ) {
                setEmailValidationError( true );
            } else {
                setEmailValidationError( false );
            }
        }

        const updatedPreviousEmployers = [ ...formData.previousOfficialEmailID ];
        updatedPreviousEmployers[ index ] = newValue;

        setFormData( {
            ...formData,
            previousOfficialEmailID: updatedPreviousEmployers,
        } );
    };

    const calculateMaxWorkedTillDate = ( index ) => {
        const workingSinceDate = new Date( formData.workingSince );
        const oneDayBeforeWorkingSince = new Date( workingSinceDate );
        oneDayBeforeWorkingSince.setDate( workingSinceDate.getDate() - 1 );

        if ( previousEmployeerFromDate[ index - 1 ] ) {
            const maxDate = new Date( previousEmployeerFromDate[ index - 1 ] );
            if ( !isNaN( maxDate.getTime() ) ) {
                maxDate.setDate( maxDate.getDate() - 1 );
                return maxDate.toISOString().split( "T" )[ 0 ];
            }
        } else {
            if ( !isNaN( oneDayBeforeWorkingSince.getTime() ) ) {
                return oneDayBeforeWorkingSince.toISOString().split( "T" )[ 0 ];
            }
        }
    };


    const handlePreviousEmployerTypeChange = ( e, index ) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [ ...formData.previousEmployerType ];
        updatedPreviousEmployers[ index ] = newValue;

        const updatedEmployeerCategory = [ ...previousEmployeerType ];
        updatedEmployeerCategory[ index ] = newValue;

        setFormData( {
            ...formData,
            previousEmployerType: updatedPreviousEmployers,
        } );

        setPreviousEmployeerType( updatedEmployeerCategory )
    };

    const handlePreviousBankChange = ( e, index ) => {
        const newValue = e.target.value;
        const updatedPreviousEmployers = [ ...formData.previousSalaryAccountBank ];
        updatedPreviousEmployers[ index ] = newValue;

        setFormData( {
            ...formData,
            previousSalaryAccountBank: updatedPreviousEmployers,
        } );
    };


    //Document Popup
    const [ isPopupOpen1, setPopupOpen1 ] = useState( false );
    const [ fileNames1, setFileNames1 ] = useState( [] );
    const [ isIDUploaded2, setIsIDUploaded2 ] = useState( false );
    const handleUploadDocument = () => {
        setPopupOpen1( true );
    };
    const handlePopupClose1 = ( selectedFileNames ) => {
        if ( selectedFileNames.length > 0 ) {
            updatePrevDoc( currentIndex, selectedFileNames );
        }

        setIsIDUploaded2( true )
        setPopupOpen1( false );
    };
    const isFormValid = () => {

        const isPreviousEmployerFilled = formData.previousEmployer.every( value => value !== "" );
        const isPreviousEmployerTypeFilled = previousEmployeerType.every( value => value !== "" );
        const isWorkedFromFilled = formData.workedFrom.every( value => value !== "" );
        const isWorkedTillFilled = formData.workedTill.every( value => value !== "" );
        const isSalaryAccountBankFilled = formData.previousSalaryAccountBank.every( value => value !== "" );
        let isValidEmailFlag1 = true;
        let isValidEmailFlag2 = true;
        if ( formData.previousOfficialEmailID ) {
            for ( let index = 0; index < formData.previousOfficialEmailID.length; index++ ) {
                const element = formData.previousOfficialEmailID[ index ];
                if ( element ) {
                    if ( element != "" ) {
                        if ( index == 0 ) {
                            isValidEmailFlag1 = isValidEmail( element );
                        } if ( index == 1 ) {
                            isValidEmailFlag2 = isValidEmail( element );
                        }
                    }
                }

            }
        }

        return (
            isPreviousEmployerFilled &&
            isPreviousEmployerTypeFilled &&
            isWorkedFromFilled &&
            isWorkedTillFilled &&
            isSalaryAccountBankFilled &&
            ( isValidEmailFlag1 && isValidEmailFlag2 )

        );
    };


    const isValidEmail = ( email ) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test( email );
    };
    useEffect( () => {
        checkFormFilled();
        isValidateEmployeerForm( isFormValid() );
    }, [ formData, prev1Address, isFormFilled ] );

    const removeExistingDoc = ( documentType ) => {
        let updatedList = [ ...previousEmployerList ];

        // Make a copy of the document array at the specified currentIndex
        let updatedDocuments = [ ...updatedList[ currentIndex ].document ];

        // Filter out the document with the given documentType
        updatedDocuments = updatedDocuments.filter( doc => doc.documentType !== documentType );

        // Update the document array at the specified currentIndex in the updatedList array
        updatedList[ currentIndex ] = { ...updatedList[ currentIndex ], document: updatedDocuments };

        // Update the state with the updated list
        setPreviousEmployerList( updatedList );
    }

    const [ openPrev1AddressDialog, setOpenPrev1AddressDialog ] = useState( false );
    const openPrev1AddrDialog = () => {
        setOpenPrev1AddressDialog( true )
    }
    const closePrev1AddrDialog = () => {
        if ( isPrev1AddressAdded ) {
            setOpenPrev1AddressDialog( false );
        } else {
            setOpenPrev1AddressDialog( false );
            setPrev1Address( {
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            } )
        }
    }
    const [ openPrev2AddressDialog, setOpenPrev2AddressDialog ] = useState( false );
    const openPrev2AddrDialog = () => {
        setOpenPrev2AddressDialog( true )
    }
    const closePrev2AddrDialog = () => {
        if ( isPrev2AddressAdded ) {
            setOpenPrev2AddressDialog( false );
        } else {
            setOpenPrev2AddressDialog( false );
            setPrev2Address( {
                'line1': '',
                'line2': '',
                'pincode': '',
                'city': '',
                'state': ''
            } )
        }

    }

    const handleAddressChange = ( e ) => {
        let name = e.target.name;
        let value = e.target.value;
        setPrev1Address( prevAddress => ( {
            ...prevAddress,
            [ name ]: value
        } ) );
    }

    const handlePrev2AddressChange = ( e ) => {
        let name = e.target.name;
        let value = e.target.value;
        setPrev2Address( prevAddress => ( {
            ...prevAddress,
            [ name ]: value
        } ) );
    }

    const handlePrev1Info = ( e ) => {
        let name = e.target.name;
        let value = e.target.value;
        setPrev1Info( prevAddress => ( {
            ...prevAddress,
            [ name ]: value
        } ) );
    }

    const handlePrev2Info = ( e ) => {
        let name = e.target.name;
        let value = e.target.value;
        setPrev2Info( prevAddress => ( {
            ...prevAddress,
            [ name ]: value
        } ) );
    }

    const isPincodeValid = ( pincode ) => /^\d{6}$/.test( pincode );
    const isCityValid = ( city ) => /^[a-zA-Z\s]+$/.test( city );
    const isStateValid = ( state ) => /^[a-zA-Z\s]+$/.test( state );

    const checkFormFilled = () => {
        if ( !prev1Address ) return;

        const { line1, line2, pincode, city, state } = prev1Address;

        const isLine1Filled = line1 && line1.trim() !== '';
        const isLine2Filled = line2 && line2.trim() !== '';
        const isPincodeFilled = pincode && pincode.trim() !== '';
        const isCityFilled = city && city.trim() !== '';
        const isStateFilled = state && state.trim() !== '';

        const isPincodeValidated = isPincodeFilled && isPincodeValid( pincode );
        const isCityValidated = isCityFilled && isCityValid( city );
        const isStateValidated = isStateFilled && isStateValid( state );

        if (
            isLine1Filled &&
            isLine2Filled &&
            isPincodeFilled &&
            isCityFilled &&
            isStateFilled &&
            isPincodeValidated &&
            isCityValidated &&
            isStateValidated
        ) {
            setIsFormFilled( true );
        } else {
            setIsFormFilled( false );
        }
    };

    const [ workedTiiList, setWorkTillList ] = React.useState( {
        'workedTill-0': '',
        'workedTill-1': ''
    } );
    const setTillDateArray = ( e, index ) => {
        let name = e.target.name;
        let value = e.target.value;
        const newObj = { ...workedTiiList };
        newObj[ name ] = value;
        setWorkTillList( newObj );
    }
    return (
        <div className="row mt-2">
            <div className="col-12 col-md-6 col-lg-6 ">
                {res.employerDetails ?
                    <ArthaFormControl fullWidth>
                        <InputLabel id="previousEmployer-label">
                            Previous Employer Name
                        </InputLabel>
                        <Select
                            labelId="previousEmployer-label"
                            id="previousEmployer"
                            name={`previousEmployer-${prevCount}`}
                            renderValue={( value ) => ( value === 'editable' ? customText[ prevCount ] : value )}
                            value={formData.previousEmployer[ prevCount ] || ""}
                            onChange={( e ) => handlePreviousEmployerChange( e, prevCount )}
                        >
                            <MenuItem value="">Previous Employer Name</MenuItem>
                            {res.employerDetails &&
                                res.employerDetails.map( ( item, index ) => (
                                    selectedEmployeerNames.includes( item.companyName ) ? null : (
                                        <MenuItem
                                            key={index}
                                            value={item.companyName}
                                            onClick={() => {
                                                previoudEmployerClick( index );
                                            }}
                                        >
                                            {item.companyName}
                                        </MenuItem>
                                    )
                                ) )}
                            <MenuItem value="editable">
                                <TextField
                                    placeholder="add new employeer"
                                    name={`previousEmployer-${prevCount}`}
                                    value={customText[ prevCount ] || ""}
                                    onChange={( e ) => handlePreviousNewEmployerChange( e, prevCount )}
                                    onClick={( e ) => { handleTextFieldClick( e, prevCount ) }}
                                    label="New employeer"
                                    style={{ width: '100%' }}
                                    className="employeer-field"
                                    autoComplete="off"
                                />
                            </MenuItem>
                        </Select>
                    </ArthaFormControl> :
                    <ArthaFormControl fullWidth>
                        <InputLabel id="previousEmployer-label">
                            Previous Employer Name
                        </InputLabel>
                        <Select
                            labelId="previousEmployer-label"
                            id="previousEmployer"
                            name={`previousEmployer-${prevCount}`}
                            renderValue={( value ) => ( value === 'editable' ? customText[ prevCount ] : value )}
                            value={formData.previousEmployer[ prevCount ] || ""}
                            onChange={( e ) => handlePreviousEmployerChange( e, prevCount )}
                        >
                            <MenuItem value="">Previous Employer Name</MenuItem>

                            <MenuItem value="editable">
                                <TextField
                                    placeholder="add new employeer"
                                    name={`previousEmployer-${prevCount}`}
                                    value={customText[ prevCount ] || ""}
                                    onChange={( e ) => handlePreviousNewEmployerChange( e, prevCount )}
                                    onClick={( e ) => { handleTextFieldClick( e, prevCount ) }}
                                    label="New employeer"
                                    style={{ width: '100%' }}
                                    className="employeer-field"
                                    autoComplete="off"
                                />
                            </MenuItem>
                        </Select>
                    </ArthaFormControl>
                }
            </div>

            <div className="col-12 col-md-6  col-lg-6 mt-3 mt-md-0">
                <ArthaFormControl fullWidth>
                    <InputLabel id="previousEmployerType">
                        Previous Employer Type
                    </InputLabel>
                    <Select
                        labelId="previousEmployerType"
                        id="previousEmployerType"
                        name={`previousEmployerType-${prevCount}`}
                        value={previousEmployeerType[ prevCount ] || ""}
                        onChange={( e ) => handlePreviousEmployerTypeChange( e, prevCount )}
                    >
                        <MenuItem value="">Previous Employer Type</MenuItem>
                        {employeerCategoryList.map( ( item ) => {
                            return (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            );
                        } )}
                    </Select>
                </ArthaFormControl>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <span className="msg">Employer Address</span><br />
                {prevCount == 0 ? <>
                    <div className="row">
                        {isPrev1AddressAdded ? <>
                            <div className="col-8">
                                <FaExclamationCircle className="badge-icon" color="green" style={{ marginTop: "5px !important" }} />
                                <span
                                    className="mt-2 upload-lable"
                                    style={{}}
                                >
                                    {" "}
                                    Address Added!
                                </span>
                            </div>
                            <div className="col-4">
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none"
                                    onClick={openPrev1AddrDialog}
                                    style={{ fontSize: '11px !important' }}
                                >
                                    Edit
                                </Button>
                            </div>
                        </> : <>
                            <div className="col-7">
                                <FaExclamationCircle className="badge-icon" style={{ marginTop: "0" }} />
                                <span
                                    className="mt-2 upload-lable"
                                    style={{}}
                                >
                                    {" "}
                                    Not added
                                </span>
                            </div>
                            <div className="col-5">
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none "
                                    onClick={openPrev1AddrDialog}
                                    style={{ fontSize: '11px !important' }}
                                >
                                    Add Address
                                </Button>
                            </div>

                        </>}
                    </div>
                </> : <>
                    <div className="row">
                        {isPrev2AddressAdded ? <>
                            <div className="col-8">
                                <FaExclamationCircle className="badge-icon" color="green" style={{ marginTop: "0" }} />
                                <span
                                    className="mt-2 upload-lable"
                                    style={{ margin: "0", fontSize: '11px' }}
                                >
                                    {" "}
                                    Address Added!
                                </span>
                            </div>
                            <div className="col-4">
                                <Button
                                    variant="contained"
                                    className="upload_btn btn_white shadow-none "
                                    onClick={openPrev2AddrDialog}
                                    style={{ fontSize: '11px !important' }}
                                >
                                    Edit
                                </Button>
                            </div></> : <>
                            <FaExclamationCircle className="badge-icon" />
                            <span
                                className="mt-2 upload-lable"
                                style={{ position: "relative", top: "-10px" }}
                            >
                                {" "}
                                Not added
                            </span>
                            <Button
                                variant="contained"
                                className="upload_btn btn_white shadow-none mt-3"
                                onClick={openPrev2AddrDialog}
                                style={{ fontSize: '11px !important' }}
                            >
                                Add Address
                            </Button></>}
                    </div>
                </>}

                <>
                    <Dialog
                        open={openPrev1AddressDialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="modal row">
                            <div className="modal-content col-md-6">
                                {!isPrev1AddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line1"
                                            fullWidth
                                            value={prev1Address && prev1Address.line1}
                                            onChange={( e ) => { handleAddressChange( e ) }}
                                            label="Address Line 1"

                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line2"
                                            onChange={( e ) => { handleAddressChange( e ) }}
                                            value={prev1Address && prev1Address.line2}
                                            fullWidth
                                            label="Address Line 2"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-5 mt-md-3">
                                        <ArthaTextField
                                            name="pincode"
                                            onChange={( e ) => {
                                                const inputValue = e.target.value.replace( /\D/g, '' ).slice( 0, 6 );
                                                handleAddressChange( { target: { name: 'pincode', value: inputValue } } );
                                            }}
                                            value={prev1Address && prev1Address.pincode}
                                            label="Pincode"
                                            fullWidth

                                            maxLength={6}
                                        />

                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-3">
                                        <ArthaTextField
                                            name="city"
                                            label="City"
                                            onChange={( e ) => {
                                                const inputValue = e.target.value.replace( /[^a-zA-Z]/g, '' );
                                                handleAddressChange( { target: { name: 'city', value: inputValue } } );
                                            }}
                                            value={prev1Address && prev1Address.city}
                                            fullWidth

                                        />
                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-3">
                                        <ArthaTextField
                                            name="state"
                                            label="State"
                                            onChange={( e ) => {
                                                const inputValue = e.target.value.replace( /[^a-zA-Z]/g, '' );
                                                handleAddressChange( { target: { name: 'state', value: inputValue } } );
                                            }}
                                            value={prev1Address && prev1Address.state}
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 mx-auto">
                                    <div className="col-6 d-flex justify-content-end mt-4">
                                        <ArthaClearButton variant="contained" onClick={closePrev1AddrDialog}>
                                            Cancel
                                        </ArthaClearButton>
                                    </div>
                                    <div className="col-6 d-flex justify-content-start mt-4">
                                        <ArthaContinueButton
                                            variant="contained"
                                            disabled={!isFormFilled}
                                            onClick={() => { setOpenPrev1AddressDialog( false ); setPrev1AddressAdded( true ) }}
                                        >
                                            Save
                                        </ArthaContinueButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </>

                <>
                    <Dialog
                        open={openPrev2AddressDialog}

                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="modal row">
                            <div className="modal-content col-md-6">
                                {!isPrev2AddressAdded ? <h6> Add New Address</h6> : <h6>Edit Address</h6>}
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line1"
                                            fullWidth
                                            value={prev2Address && prev2Address.line1}
                                            onChange={( e ) => { handlePrev2AddressChange( e ) }}
                                            label="Address Line 1"

                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <ArthaTextField
                                            name="line2"
                                            onChange={( e ) => { handlePrev2AddressChange( e ) }}
                                            value={prev2Address && prev2Address.line2}
                                            fullWidth
                                            label="Address Line 2"
                                        />
                                    </div>
                                    <div className="col-md-6 mt-5 mt-md-3">
                                        <ArthaTextField
                                            name="pincode"
                                            onChange={( e ) => { handlePrev2AddressChange( e ) }}
                                            value={prev2Address && prev2Address.pincode}
                                            label="Pincode"
                                            fullWidth

                                            maxLength={6}
                                        />

                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-3">
                                        <ArthaTextField
                                            name="city"
                                            label="City"
                                            onChange={( e ) => { handlePrev2AddressChange( e ) }}
                                            value={prev2Address && prev2Address.city}
                                            fullWidth

                                        />
                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-3">
                                        <ArthaTextField
                                            name="state"
                                            label="State"
                                            onChange={( e ) => { handlePrev2AddressChange( e ) }}
                                            value={prev2Address && prev2Address.state}
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4 mx-auto">
                                    <div className="col-6 d-flex justify-content-end mt-4">
                                        <ArthaClearButton variant="contained" onClick={closePrev2AddrDialog}>
                                            Cancel
                                        </ArthaClearButton>
                                    </div>
                                    <div className="col-6 d-flex justify-content-start mt-4">
                                        <ArthaContinueButton
                                            variant="contained"
                                            onClick={() => { setOpenPrev2AddressDialog( false ); setPrev2AddressAdded( true ) }}
                                        >
                                            Save
                                        </ArthaContinueButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="text"
                    name="employerWebsite"
                    value={prevCount == 0 ? prev1Info.employerWebsite : prev2Info.employerWebsite}
                    onChange={( e ) => prevCount == 0 ? handlePrev1Info( e ) : handlePrev2Info( e )}
                    label="Employer Website (Optional)"
                    fullWidth
                />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="text"
                    name="designation"
                    value={prevCount == 0 ? prev1Info.designation : prev2Info.designation}
                    onChange={( e ) => prevCount == 0 ? handlePrev1Info( e ) : handlePrev2Info( e )}
                    label="Designation"
                    fullWidth
                />
            </div>
            <div className="col-8 col-md-4 mt-3 mt-md-4 ">
                <p className="msg">Supporting Documents(optional)</p>
                {( isIDUploaded2 || previousEmployeerDocuments && previousEmployeerDocuments.document ) ? (
                    <>

                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                            {" "}
                            {
                                previousEmployeerDocuments && previousEmployeerDocuments.document ? previousEmployeerDocuments.document.map( ( item ) => {
                                    return <p><FaCheck className="check-icon" /><span style={{ marginRight: '10px' }}>{item.documentType}</span></p>
                                } ) : <>
                                    <FaExclamationCircle className="badge-icon" />
                                    <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                                        {" "}
                                        Not Uploaded
                                    </span>
                                </>
                            }
                        </span>
                        {fileNames1.map( ( fileName, index ) => (
                            <div
                                className="mt-2 upload-lable"
                                style={{ position: "relative", top: "-10px" }}
                                key={index}
                            >
                                <FaCheck className="check-icon" />
                                <span style={{ fontSize: "13px", position: "relative", top: "-9px" }}>{fileName}</span>
                            </div>
                        ) )}
                    </>
                ) : (
                    <>
                        <FaExclamationCircle className="badge-icon" />
                        <span className="mt-2 upload-lable" style={{ position: "relative", top: "-10px" }}>
                            {" "}
                            Not Uploaded
                        </span>
                    </>
                )}
            </div>
            <div className="col-4 col-md-2 mt-4">
                <input
                    type="file"
                    ref={fileInputRefID2}
                    style={{ display: "none" }}
                    onChange={handleFileChangeID2}
                />
                <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={handleUploadDocument}
                >
                    Upload
                </Button>
                <UploadDocument
                    open={isPopupOpen1}
                    fileNames={fileNames1}
                    existingDocuments={previousEmployeerDocuments && previousEmployeerDocuments.document}
                    onRemove={removeExistingDoc}
                    handleClose={handlePopupClose1}

                />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="date"
                    name={`workedFrom-${prevCount}`}
                    value={previousEmployeerFromDate[ prevCount ] || ""}
                    onChange={( e ) => handlePreviousFromChange( e, prevCount )}
                    InputProps={{
                        inputProps: { max: previousEmployeerTillDate[ prevCount - 1 ] ? previousEmployeerFromDate[ prevCount - 1 ] : formData.workingSince },
                    }}
                    className={`workedFrom${workedFromCounter}`}
                    label="Working From"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="date"
                    name={`workedTill-${prevCount}`}
                    value={previousEmployeerTillDate[ prevCount ] || ""}
                    onChange={( e ) => { setTillDateArray( e ); }}
                    onBlur={
                        ( e ) => {
                            handlePreviousWorkedTillChange( e, prevCount );
                        }
                    }
                    className={`workedTill${prevCount}`}
                    label="Worked Till"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            //max: calculateMaxWorkedTillDate( prevCount ),
                            //max: formData.workingSince,
                            min: previousEmployeerFromDate[ prevCount ],
                            max: new Date().toISOString().split( "T" )[ 0 ]
                        }
                    }}
                    fullWidth
                    error={Boolean( dateValidationError )}
                    helperText={dateValidationError}
                />
            </div>

            <div className="col-12 col-md-6  col-lg-6 mt-3 mt-md-4">
                <ArthaFormControl fullWidth>
                    <InputLabel id="previousSalaryAccount-label">
                        Salary Account Bank
                    </InputLabel>
                    <Select
                        labelId="previousSalaryAccount-label"
                        id="previousSalaryAccountBank"
                        name={`previousSalaryAccountBank-${prevCount}`}
                        value={formData.previousSalaryAccountBank[ prevCount ] || ""}
                        onChange={( e ) => handlePreviousBankChange( e, prevCount )}

                    >
                        <MenuItem value="">Salary Account Bank</MenuItem>
                        {bankNames && bankNames.map( ( item ) => {
                            return <MenuItem value={item} key={item}>{item}</MenuItem>
                        } )}
                    </Select>
                </ArthaFormControl>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                <ArthaTextField
                    type="text"
                    name="accNo"
                    value={prevCount == 0 ? prev1Info.accNo : prev2Info.accNo}
                    onChange={( e ) => prevCount == 0 ? handlePrev1Info( e ) : handlePrev2Info( e )}
                    label="Salary Account Number"
                    fullWidth

                />
            </div>
        </div>
    )
}