import React, { useState, useRef, useEffect } from "react";
import ArthaContinueButton from "../../fields/ArthaContinueButton.js";
import ArthaClearButton from "../../fields/ArthaClearButton";
import { MdOutlineFileDownload } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { Button } from "@mui/material";
import axios from "axios"; // Use only one axios instance
import { handleSignOut } from "./SessionExpiredApi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Vkyc from "./vKYC.jsx";
import LoadingSpinner from "../../fields/spinner.jsx";
import { toast } from 'react-toastify';
import { skipMsigner } from "../../actioncreators/actioncreators.js";
const Msigner = ({ onBack, onContinue, setActiveStep }) => {
  const fileInputRefAIS1 = useRef(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const [stateResponse, setStateResponse] = useState({});
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  // GET API TO FETCH SANCTION LETTER DETAILS START ----------------------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/personal-loan/get-loan-docket/", {
          headers: {
            "x-apid": ap_id,
            "x-loanappid": loan_app_id,
          },
        });

        console.log("API Response:", response);

        if (response) {
          setStateResponse(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await handleSignOut();
          toast.error("Your Session has expired. You will be redirected to the Login Page.");
          navigate('/');
        }
        else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
              }else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
              }
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [ap_id, loan_app_id]);
  // GET API TO FETCH SANCTION LETTER DETAILS END ----------------------------

  const handleBack = () => {
    onBack();
  };
  // const handleContinue = () => {
  //   onContinue();
  // };
 

async function fetchData_back() {
  	// Set loading state to true
    setIsButtonClicked(true);
    setIsLoading(true);
  try {
      const response = await axios.post("/personal-loan/emsigner-esign", {
          headers: {
              "Accept": "application/json",
              // Add any additional headers if required
          },
      });
      return response.data;
  } catch (error) {
    if ( error.response.status === 401 ) {
      handleSignOut();
      toast.error( "Your Session has expired.You will be redirected to Login Page." )
      navigate( '/' )
  }else if (error.response && error.response.status === 429) {
      toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
    }else {
      toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
    }
      console.log(error);
  }	finally {
    // Reset loading state after the operation is complete
    setIsLoading(false);
    setIsButtonClicked(false);
  }
  return null;
}

const handleContinue = async () => { 
  let res = await fetchData_back();
  if (res.businessStatusCode === 2) {
      localStorage.setItem('isEmsignerRedirected', 'true');
      let externalUrl = res.redirectURL;
      window.location.href = externalUrl;
  }
  setResponse(res);
};

  const handleAgreeChange = () => {
    setIsAgreed( !isAgreed );
  };

  // Download API call
  const downloadDocument = async ( documentId ) => {
    try {
      const payload = {
        documentId: documentId,
      };

      const documentresponse = await axios.post(
        "/personal-loan/get-document-data-by-document-id",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-apid": ap_id,
            "x-loanappid": loan_app_id,
          },
        }
      );
      console.log( "documentresponse", documentresponse );
      console.log( "documentresponse.data", documentresponse.data );
      console.log("documentresponse.data",
        documentresponse.data.documentBase64
      );

      if (
        documentresponse.status === 200 &&
        documentresponse.data.businessStatusCode === 2
      ) {
        console.log( "documentresponse", documentresponse );
        console.log( "documentresponse.data", documentresponse.data );
        console.log(
          "documentresponse.data",
          documentresponse.data.documentBase64
        );

        const base64Data = documentresponse.data.documentBase64;
        const byteCharacters = atob( base64Data );
        const byteNumbers = new Array( byteCharacters.length );
        for ( let i = 0; i < byteCharacters.length; i++ ) {
          byteNumbers[ i ] = byteCharacters.charCodeAt( i );
        }
        const byteArray = new Uint8Array( byteNumbers );

        const blob = new Blob( [ byteArray ], { type: "application/pdf" } );

        const link = document.createElement( "a" );
        link.href = window.URL.createObjectURL( blob );
        link.download = documentresponse.data.documentName;
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
      } else {
        // Handle the case where the download is not successful
        console.error( "Error downloading document:", documentresponse );
        // You may want to show an error message to the user
      }
    } catch ( error ) {
      if ( error.response.status === 401 ) {
        handleSignOut();
        toast.error( "Your Session has expired.You will be redirected to Login Page." )
        navigate( '/' )
    }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error( "Error downloading document:", error );
    }
  };
  
  const handleDownloadButton = async ( documentId ) => {
    await downloadDocument( documentId );
  };

  const [ showVKYC, setShowVKYC ] = useState( false );

  const skipMsign = async () => {
    
    try {
      const response = await skipMsigner(); 
      if ( response.data.businessStatusCode === 2) {
        navigate('/home/completedapplication')
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired. You will be redirected to the Login Page.");
        navigate("/");
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error making GET request:", error);
      // Handle error if needed
    }
  };
  return (
    <>
      {!showVKYC ? (
        <>
          <div className="row ">
            <div className="col-12 mt-3 d-flex">
              <h6>Loan Docket</h6>
              <div className="col-2 ml-auto">
              <a
                onClick={skipMsign}
                style={{
                  color: "rgb(255, 115, 0)",
                  fontWeight: "bold",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Skip
              </a>
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col-11 mt-2">
              <p className="small-heading kyc-form-text-size">
              After clicking on 'Continue', your 'Loan Docket' will be sent to your registered email ID for e-signing. Additionally, you can downloaded 'Loan Docket' from here.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-md-9 mt-0 mt-md-4 ">
              <p className="msg">Loan Docket </p>

              <>
                {/* <FaExclamationCircle className="badge-icon" /> */}
                <CgFileDocument
                  className="badge-icon1"
                  style={{ fontSize: "18px" }}
                />
                <span
                  className="mt-2 upload-lable"
                  style={{
                    position: "relative",
                    top: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {stateResponse && stateResponse.loanDocketDocumentName}
                </span>
              </>
            </div>
            <div className="col-4 col-md-2 mt-0 mt-md-4">
              <input
                type="file"
                ref={fileInputRefAIS1}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={() =>
                  handleDownloadButton(stateResponse && stateResponse.loanDocketDocumentId)
                }
              >
                <MdOutlineFileDownload style={{ fontSize: "20px" }} /> Download
              </Button>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-8 col-md-9 mt-3 mt-md-4 ">
              <p className="msg">Terms & conditions</p>

              <>
                <FaExclamationCircle className="badge-icon" />
                <CgFileDocument
                  className="badge-icon1"
                  style={{ fontSize: "18px" }}
                />
                <span
                  className="mt-2 upload-lable"
                  style={{
                    position: "relative",
                    top: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {stateResponse.mitcDocumentName}
                </span>
              </>
            </div> */}
            {/* <div className="col-4 col-md-2 mt-4">
              <input
                type="file"
                ref={fileInputRefAIS2}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={() =>
                  handleDownloadButton( stateResponse.mitcDocumentId )
                }
              >
                <MdOutlineFileDownload style={{ fontSize: "20px" }} /> Download
              </Button>
            </div> */}
            <div className="col-12 col-md-12 mt-3 checkbox">
              <input
                type="checkbox"
                id="agree"
                checked={isAgreed}
                onChange={handleAgreeChange}
              />
              <label htmlFor="agree">
                I accept the terms of the above Loan Docket
              </label>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-6 d-flex justify-content-end">
              <ArthaClearButton
                type="button"
                className="custom_btn"
              onClick={handleBack}
              >
                Back
              </ArthaClearButton>
            </div>
            <div className="col-6 d-flex justify-content-start">
              <ArthaContinueButton
                type="button"
                className={`get-opt-sbmt ${!isAgreed && "disabled"}`}
                style={{
                  opacity: (!isAgreed || isButtonClicked) ? 0.39 : 1,
                  pointerEvents: isButtonClicked ? "none" : "auto"
                }}                
                onClick={handleContinue}
                disabled={!isAgreed}
              >
               {isLoading ? (
            <LoadingSpinner style={{ pointerEvents: 'none' }} />
          ) : (
            'Continue'
          )}
              </ArthaContinueButton>
            </div>
          </div>
        </>
      ) : (
        <Vkyc
          setActiveStep={setActiveStep}
        />
      )
      }

    </>
  );
};

export default Msigner;
