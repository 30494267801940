import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoanOffer from "./LoanOffer";
import { handleSignOut } from "./SessionExpiredApi";
import { businessLoanIntegrationApi } from "../../actioncreators/actioncreators";
import { toast } from "react-toastify";


const LoadingComponent = ( { setActiveStep, isLoading } ) => {
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};
  const navigate = useNavigate();
  const [ timeRemaining, setTimeRemaining ] = useState( 70 );
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed
  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };
  useEffect( () => {
    const interval = setInterval( () => {
      setTimeRemaining( prevTime => prevTime - 1 );
    }, 1000 );


    return () => clearInterval( interval );
  }, [ timeRemaining ] );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await businessLoanIntegrationApi();
        if (response.httpResponseCode === 200 && response.businessStatusCode === 2) {
          const responseBody = response.data;
          setActiveStep(6);
          navigate(LoanOffer, {
            state: {
              ap_id: ap_id,
              loan_app_id: loan_app_id,
              response: responseBody,
            },
          });
        } else if (response.data.businessStatusSubCode === 1.1) {
          toast.error(response.data.message);
          isLoading(false);
          setActiveStep(5);
        } else {
          navigate("/home/completedapplication");
          console.error(
            "Error in API call:",
            response.data.message || response.statusText
          );
        }

    } catch ( error ) {
      handleApiError(error);
      navigate( "/home/completedapplication" );
      console.error( "Error:", error );
    }
  };
    fetchData();
  }, [isLoading]);
  

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <p>Please wait, while your application is being analyzed</p>
      <p style={{ fontSize: '14px' }}>wait : {timeRemaining} sec</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ margin: "20px" }}
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="#3498db"
          strokeWidth="4"
          fill="none"
          strokeLinecap="round"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="2s"
            repeatCount="indefinite"
            from="0"
            to="502"
          />
          <animate
            attributeName="stroke-dasharray"
            dur="2s"
            repeatCount="indefinite"
            values="150.79644737231007 100.53096491487338;1 150"
          />
        </circle>
      </svg>
    </div>
  );
};

export default LoadingComponent;