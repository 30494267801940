const loginResponse = ( response ) => {
    return {
        type: 'LOGIN_RESPONSE',
        payload: response
    }
}

const DocumentDisplay = ( response ) => {
    return {
        type: 'Document',
        payload: response
    }
}






export {loginResponse,DocumentDisplay};


