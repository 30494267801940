import React, { useState, useEffect, useRef } from "react";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import ArthaContinueButton from "../fields/ArthaContinueButton";
import ArthaClearButton from "../fields/ArthaClearButton";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { handleSignOut } from "../Loan/PersonalLoan/SessionExpiredApi";
import { toast } from "react-toastify";
import { BusinessFetchTermsAndCondition } from "../actioncreators/actioncreators";

const TermsPopup = (props) => {
  const [termsMessage, setTermsMessage] = useState("");
  const navigate = useNavigate();
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const errorDisplayedRef = useRef(false); // Ref to keep track of errorDisplayed

  const handleApiError = (error) => {
    if (errorDisplayedRef.current) return; // Do nothing if an error has already been displayed

    setErrorDisplayed(true); // Set the state to true
    errorDisplayedRef.current = true; // Set the ref to true

    if (error.response && error.response.status === 401) {
      handleSignOut();
      toast.error(
        "Your session has expired. You will be redirected to the Login Page."
      );
      navigate("/");
    } else if (error.response && error.response.status === 429) {
      toast.error(
        "Too Many Requests: You have exceeded the rate limit. Please try again later."
      );
    } else {
      toast.error(
        "There appears to be a technical issue connecting to our servers. Could you please try again later."
      );
    }

    // Reset the error flag after a certain time
    setTimeout(() => {
      setErrorDisplayed(false);
      errorDisplayedRef.current = false;
    }, 2000); // 2 seconds
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await BusinessFetchTermsAndCondition();
        // Process the terms and conditions to include HTML tags
        const formattedTerms = formatTerms(response.data.loginPageTermsAndConditions);
        setTermsMessage(formattedTerms);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching terms and conditions:", error);
      }
    };
    fetchData();
  }, []);

  const formatTerms = (terms) => {
    // Split the terms text by numbers followed by a period and a space
    const points = terms.split(/(\d+\.\s+)/).filter((str) => str.trim().length > 0);
    // Wrap each point in a <p> tag, keeping the numbering intact
    return points.map((point, index) => {
      if (index % 2 === 0 && index + 1 < points.length) {
        return `<p key=${index}>${point}${points[index + 1]}</p>`;
      }
      return null;
    }).filter((item) => item !== null).join("");
  };

  const handleAcceptTerms = () => {
    props.onAcceptTerms();
    props.closeModal();
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <div>
      {props.isOpen && (
        <div className="modal termsPopup" tabIndex="-1" role="dialog">
          <div
            className="modal-dialog modal-lg modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h6>Terms & Conditions</h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ maxHeight: "calc(80vh - 180px)", overflowY: "auto" }}
              >
                <div dangerouslySetInnerHTML={{ __html: termsMessage }} />
              </div>
              <div className="modal-footer">
                <div className="row mt-2 mx-auto">
                  <div className="col-12 d-flex justify-content-center mt-2">
                    <ArthaContinueButton
                      variant="contained"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                      onClick={handleAcceptTerms}
                    >
                      Accept Terms
                    </ArthaContinueButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsPopup;
