// LoginModule.js
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./LoginForm.css"; // Import the CSS file
import LoginForm from "./LoginForm"; // Import the LoginForm component
import OtpForm from "./OtpForm";
import logo from "./public/logo.svg";

const LoginModule = () => {
  return (
    <div className="container-fluid mainDiv">
      <div className="row loginBox">
        <div className="col-12 col-md-11 col-lg-8 p-3 p-md-5 loginModule">
          <div className="row">
            <div className="col-12 col-md-6 mb-3 mb-sm-5 loginLogo">
              <div className="logo-tagline">
                <img className="mainLogo" alt="" src={logo} />
                <p className="artha-tagline">
                  Chutkiyon mei aapke bank account mei
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 loginForms">
              <Routes>
                <Route path="/getOtp" element={<OtpForm />} />
                <Route path="/" element={<LoginForm />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModule;
