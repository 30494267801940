import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleSignOut } from "./SessionExpiredApi";
import { toast } from 'react-toastify';

const RedirectedLoadingComponent = ( { setActiveStep } ) => {
    const navigate = useNavigate();
    const [ progress, setProgress ] = useState( 0 );

    const startTimer = () => {
        const startTime = Date.now();
        const endTime = startTime + 1 * 60 * 1000;

        const interval = setInterval( () => {
            const now = Date.now();
            const elapsedTime = now - startTime;
            const remainingTime = endTime - now;
            let percentage = ( elapsedTime / ( 1 * 60 * 1000 ) ) * 100;

            if ( now < endTime ) {
                setProgress( percentage );
            } else {
                clearInterval( interval );
                setProgress( 0 );
                startTimer();
            }
        }, 1000 ); // Update every second

        return () => clearInterval( interval );
    };

    useEffect( () => {
        startTimer(); // Start the timer when the component mounts
    }, [] );
    async function fetchRedirectionStatus () {
        try {
            const response = await axios.get( "/personal-loan/vkyc-status-check", {
                headers: {
                    "Accept": "application/json",
                },
            } );
            return response.data;
        } catch ( error ) {
            if ( error.response.status === 401 ) {
                await handleSignOut();
                toast.error( "Your Session has expired.You will be redirected to Login Page." )
                navigate( '/' )
            }else if (error.response && error.response.status === 429) {
                toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
              }else {
                toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
              }
            console.error( error );
            throw error; // Rethrow the error to handle it outside of this function
        }
    }
    useEffect( () => {
        fetchRedirectionStatus();
      }, [] )
    useEffect( () => {
        const checkVkycRedirection = async () => {
            let isVkycRedirected = localStorage.getItem( 'isVkycRedirected' );

            if ( isVkycRedirected === 'true' ) {
                localStorage.setItem( 'isVkycRedirected', 'false' );

                try {
                    let res = await fetchRedirectionStatus();

                    if ( res.businessStatusCode === 2 ) {
                        setActiveStep( 10 );
                    } else if ( res.businessStatusSubCode === 1.2 ) {
                        toast.error(res.message)
                        setActiveStep( 8 );
                    } else {
                        navigate( '/home/failed' );
                    }
                } catch ( error ) {
                    if ( error.response.status === 401 ) {
                        handleSignOut();
                        toast.error( "Your Session has expired.You will be redirected to Login Page." )
                        navigate( '/' )
                    }else if (error.response && error.response.status === 429) {
                        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
                      }else {
                        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
                      }
                    console.error( error );
                }
            }
        };

        checkVkycRedirection();
    }, [ setActiveStep, navigate ] );

    return (
        <div style={{ textAlign: "center", padding: "20px" }}>
            <p>Please wait, Your Application is in Process</p>
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                style={{ margin: "20px" }}
            >
                <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="#3498db"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                >
                    <animate
                        attributeName="stroke-dashoffset"
                        dur="2s"
                        repeatCount="indefinite"
                        from="0"
                        to="502"
                    />
                    <animate
                        attributeName="stroke-dasharray"
                        dur="2s"
                        repeatCount="indefinite"
                        values="150.79644737231007 100.53096491487338;1 150"
                    />
                </circle>
            </svg> */}
            <div style={{ width: '80%', border: '1px solid #ccc', borderRadius: '4px', position: 'relative' }}>
                <div style={{ width: '100%', backgroundColor: '#f0f0f0', height: '20px', position: 'relative', overflow: 'hidden', borderRadius: '4px' }}>
                    <div style={{ width: `${progress}%`, backgroundColor: '#007bff', height: '100%', borderRadius: '4px' }}></div>
                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#000' }}>{`${Math.round( progress )}%`}</span>
                </div>
            </div>
        </div>
    );
};

export default RedirectedLoadingComponent;
