import React, { useState, useRef, useEffect } from "react";
import {
  FaIdBadge,
  FaCheck,
  FaExclamationTriangle,
  FaExclamationCircle,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
import ArthaClearButton from "../../fields/ArthaClearButton";
import ArthaContinueButton from "../../fields/ArthaContinueButton";
import AdditionalEmployementDetails from "./AdditionalEmployementDetails";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "./SessionExpiredApi";
import LoadingSpinner from "../../fields/spinner.jsx";
import ProfessionalDetails from "./professionalDetails.jsx";
import { validateUAN,uploadAisReport,skipAisAPI } from "../../actioncreators/actioncreators.js";
import { toast } from 'react-toastify';

function EmployementDetails({ onContinue, onBack }) {
  const fileInputRefAIS1 = useRef(null);
  const [isAISReportUploaded1, setIsAISReportUploaded1] = useState(false);
  const [fileName, setFileName] = useState("");
  const [
    showAdditionalEmployementDetails,
    setshowAdditionalEmployementDetails,
  ] = useState(false);

  const [showProfessionalDetails, setshowProfessionalDetails] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const location = useLocation();
  const { ap_id, loan_app_id } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
    try{
      const response = await validateUAN(); 
        console.log("Response from API:", response);

        if (response.status === 200) {
          console.log("Request successful");
        } else {
          console.error("Invalid response status.");
          // Handle error if needed
        }
      
    } catch(error)  {
        if (error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired.You will be redirected to Login Page.");
          navigate("/");
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error making GET request:", error);
        // Handle error if needed
      };
    }
      fetchData();
  }, [ap_id, loan_app_id]);

  const handleFileChangeAndUploadAIS1 = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFileName(selectedFile.name);
      setIsAISReportUploaded1(true);
      setFile(selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];

        // Set the base64 string to the 'imageBase64' state
        setImageBase64(base64String);

        // Optionally, you can perform additional logic with the base64 string here

        // Now, you can handle the file upload or trigger further actions
        handleUpload();
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setFileName("");
      setIsAISReportUploaded1(false);
      setFile(null);
      setImageBase64(null);
      setDisableContinue(true);
      // Optionally, you can handle the case when no file is selected
    }
  };

  const handleUpload = async () => {
    if (imageBase64) {
      setIsButtonClicked(true);
      setIsLoading(true);
      const data = {
        base64PDF: imageBase64,
      };
  
      try {
        const response = await uploadAisReport(data);
        
        if (
          response.data.httpResponseCode === 200 &&
          response.data.businessStatusCode === 2 &&
          response.data.businessStatusSubCode === 2.1
        ) {
          console.log("Upload successful");
          setshowAdditionalEmployementDetails(true);
        } else if (
          response.data.httpResponseCode === 200 &&
          response.data.businessStatusCode === 2 &&
          response.data.businessStatusSubCode === 2.2
        ) {
          console.log("Self Employed redirected successfully");
          setshowProfessionalDetails(true);
        } else {
          toast.error(
            "With the PAN and DOB that you have provided, we are unable to access the AIS."
          );
          console.error("Invalid response status.");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          handleSignOut();
          toast.error("Your session has expired. You will be redirected to the Login Page.");
          navigate("/");
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
          console.error("Error uploading file:", error);
  
      } finally {
        // Reset loading state after the operation is complete
        setIsLoading(false);
        setIsButtonClicked(false);
      }
    } else {
      console.error("No base64 string available for upload.");
    }
  
    setDisableContinue(false);
  };
 

  const handleFileChangeAIS1 = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFileName(selectedFile.name);
      setIsAISReportUploaded1(true);
    } else {
      setFileName("");
      setIsAISReportUploaded1(false);
    }
  };

  const handleUploadButtonClickAIS1 = () => {
    fileInputRefAIS1.current.click();
  };

  // const fileInputRefAIS2 = useRef(null); // Separate ref for permanent address file input
  // const [isAISReportUploaded2, setIsAISReportUploaded2] = useState(false);
  // const [fileName2, setFileName2] = useState(""); // State to store the file name

  // const handleFileChangeAIS2 = (e) => {
  //   const selectedFile2 = e.target.files[0];

  //   if (selectedFile2) {
  //     setFileName2(selectedFile2.name);
  //     setIsAISReportUploaded2(true);
  //   } else {
  //     setFileName2("");
  //     setIsAISReportUploaded2(false);
  //   }
  // };

  // const handleUploadButtonClickAIS2 = () => {
  //   fileInputRefAIS2.current.click();
  // };
  const handleBack = () => {
    onBack();
  };

  const callApiOnClick = async() => {
    const headers = {
      "x-apid": ap_id,
      "x-loanappid": loan_app_id,
    };
    try{
    const response = await axios
      .get("/personal-loan/validate-uan", { headers: headers });
        console.log("Response from API:", response);

        if (response.status === 200) {
          console.log("Request successful");
        } else {
          console.error("Invalid response status.");
          // Handle error if needed
        }
      
      }catch(error) {
        if (error.response.status === 401) {
          handleSignOut();
          toast.error("Your Session has expired. You will be redirected to the Login Page.");
          navigate("/");
        }else if (error.response && error.response.status === 429) {
          toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
        }else {
          toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
        }
        console.error("Error making GET request:", error);
        // Handle error if needed
      };
  };
 
  const skipAIS = async () => {
    //setshowProfessionalDetails(true);
    // setshowAdditionalEmployementDetails(true);
    try {
      const response = await skipAisAPI(); 

      console.log("Response from API:", response);

      if (response.status === 200 && response.data.businessStatusCode === 2 && response.data.businessStatusSubCode === 2.1) {
        setshowAdditionalEmployementDetails(true);
        console.log("Request successful");
        // Do something with the successful response
      } else if (response.status === 200 && response.data.businessStatusCode === 2 && response.data.businessStatusSubCode === 2.2) {
        setshowProfessionalDetails(true);
        console.log("Self Employed");
        // Do something with the successful response
      }

      else {
        console.error("Invalid response status.");
        // Handle error if needed
      }
   
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleSignOut();
        toast.error("Your Session has expired. You will be redirected to the Login Page.");
        navigate("/");
      }else if (error.response && error.response.status === 429) {
        toast.error("Too Many Requests: You have exceeded the rate limit. Please try again later.");
      }else {
        toast.error("There appears to be a technical issue connecting to our servers. Could you please try again later.")
      }
      console.error("Error making GET request:", error);
      // Handle error if needed
    }
  };
 
  const handleRemoveFile = () => {
    setFileName("");
    setIsAISReportUploaded1(false);
    setFile(null);
    setImageBase64(null);
    setDisableContinue(true);
  };
  return (
    <>
      {!showAdditionalEmployementDetails && !showProfessionalDetails && (
        <>
          <div className="row">
            <div className="col-4 ">
              <h6>AIS Report</h6>
            </div>
            <div className="col-2 ms-auto">
              <a
                onClick={skipAIS}
                style={{
                  color: "rgb(255, 115, 0)",
                  fontWeight: "bold",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Skip
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h6>Upload AIS Report to expedite loan approval</h6>
              <p className="small-heading kyc-form-text-size">
                What is the Annual Info Statement (AIS)? The Income Tax Council
                specified that the assessee can access the latest Annual
                Information Statement (AIS), which consists of financial
                transaction details such as Salary, interest, dividends,
                Securities Transactions, Mutual Fund transactions, Remittances
                from abroad, etc. on the e-filing portal.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-md-4 mt-3 mt-md-4 ">
              <span className="msg">AIS for FY 2023-24</span><br />
              {isAISReportUploaded1 ? (
                <>
                  <FaCheck className="check-icon " style={{ marginTop: "3px" }} />
                  <span className=""> {fileName}</span>
                </>
              ) : (
                <>
                  <FaExclamationCircle className="badge-icon" style={{ marginTop: "3px" }} />
                  <span className=""> Not Uploaded</span>
                </>
              )}
            </div>
            <div className="col-4 col-md-2 mt-4">
              {isAISReportUploaded1 ? (
                <>
                  <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={handleRemoveFile} // Call handleRemoveFile when remove button is clicked
                  >
                    Remove
                  </Button>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    ref={fileInputRefAIS1}
                    style={{ display: "none" }}
                    onChange={handleFileChangeAndUploadAIS1}
                    accept="application/pdf"
                  />
                  <Button
                    variant="contained"
                    className="upload_btn btn_white shadow-none mt-3"
                    onClick={handleUploadButtonClickAIS1}
                  >
                    Upload
                  </Button>
                </>
              )}
            </div>
          </div>


          {/* <div className="row">
            <div className="col-8 col-md-4 mt-3 mt-md-4 ">
              <p className="msg">AIS for FY 2022-23</p>
              {isAISReportUploaded2 ? (
                <>
                  <FaCheck className="check-icon" />
                  <span className="mt-2"> {fileName2}</span>
                </>
              ) : (
                <>
                  <FaExclamationCircle className="badge-icon" />
                  <span className="mt-2"> Not Uploaded</span>
                </>
              )}
            </div>
            <div className="col-4 col-md-2 mt-4">
              <input
                type="file"
                ref={fileInputRefAIS2}
                style={{ display: "none" }}
                onChange={handleFileChangeAIS}
              />
              <Button
                variant="contained"
                className="upload_btn btn_white shadow-none mt-3"
                onClick={handleUploadButtonClickAIS2}
              >
                Upload
              </Button>
            </div>
          </div> */}
          <div className="row mt-4">
            <div className="col-6 d-flex justify-content-end">
              <ArthaClearButton type="button" onClick={handleBack}>
                Back
              </ArthaClearButton>
            </div>

            <div className="col-6 d-flex justify-content-start">
              <ArthaContinueButton type="button" onClick={handleUpload} disabled={disableContinue} style={{
                opacity: disableContinue || isButtonClicked ? 0.37 : 1,
                color: 'white',
                pointerEvents: isButtonClicked ? "none" : "auto",
              }}
              >
                {isLoading ? (
                  <LoadingSpinner style={{ pointerEvents: 'none' }} />
                ) : (
                  'Continue'
                )}
              </ArthaContinueButton>
            </div>
            <p className="smaller-heading kyc-form-text-size border mt-5">
              How to Download AIS on ITR Portal?
              <br />
              Step 1 – Login to income tax e-filing portal (
              <a href=" https://eportal.incometax.gov.in/iec/foservices/#/login"></a>
              ) using the Aadhaar or PAN CARD & your password. <br />
              Step 2 – Then Go to the ‘Services’ top section & click on ‘Annual
              Information Statement (AIS). <br />
              Step 3 – To Download the statement of AIS in PDF format, Choose
              the PDF option & click on ‘Download’. <br />
              Step 4 – Click PDF & enter the right password. <br />
              Step 5 – The password will be your PAN + your date of birth.
              Example: aaaaa0000a01011991. <br />
              Step 6 – After entering the password one can see all the
              information in your AIS.
            </p>
          </div>
        </>
      )}
      {showAdditionalEmployementDetails && (
        <AdditionalEmployementDetails
          onContinue={onContinue}
          onBack={onBack}
          onCancel={() => setshowAdditionalEmployementDetails(false)}
        />
      )}
      {showProfessionalDetails && (
        <ProfessionalDetails
        />
      )}
    </>
  );
}

export default EmployementDetails;
