import React from "react";
import ArthaContinueButton from "../../../fields/ArthaContinueButton";
import Congrats from '../images/congrats.png';
const SuccessLoanApplicationPage = () => {
  return (
    <>
      <div className="row">
        <div className="inavlid-box   mx-auto col-8  text-center ">

          <img src={Congrats} style={{ width: "12%", justifyContent: 'center', marginTop: "6%" }} />
          <h5 className="col-12  mt-4">
            Yay! You have completed the loan application
          </h5>
          <p className="failed-subheading">
            sit tight till we check your credit score!
          </p>
          <div className="row mt-5 d-flex justify-content-center">
            <div
              className="col-8 card shadow-none  "
              style={{ background: "#ebf5ff" }}
            >
              <div className="card-body">
                <p className="failed-subheading">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo
                  voluptatem quidem architecto vel et perspiciatis?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessLoanApplicationPage;
